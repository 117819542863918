import { TimeLineContainer } from '../cells/TimeLine.container';
import { TableColumn } from '../../enums/TableColumn';

export const TableHeadCellStateChanges = (): JSX.Element => {
    return (
        <th
            style={{ width: '50%' }}
            className={'force-overflow-visible padding-20 padding-bottom-10 padding-top-2'}
            key={TableColumn.STATE_CHANGES}
        >
            <span>
                <span>
                    <TimeLineContainer />
                </span>
            </span>
        </th>
    );
};
