import { OnboardinggState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Tips } from '../types';
import { loadOnboardingTip, saveOnboardingTip } from '../onboardingToLocalStorage';
import { State } from '../../../reducers';

const initialState: OnboardinggState = {
    [Tips.HISTORICAL_VIEW]: loadOnboardingTip(Tips.HISTORICAL_VIEW),
    [Tips.ACTIVITIES_VIEW]: loadOnboardingTip(Tips.ACTIVITIES_VIEW),
    [Tips.TIME_RANGE_SELECTION]: loadOnboardingTip(Tips.TIME_RANGE_SELECTION),
};

const name = 'onboarding';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setShowOnboardingTip: (
            state: OnboardinggState,
            action: PayloadAction<{ type: Tips; value: boolean }>
        ): void => {
            state[action.payload.type] = action.payload.value;
            saveOnboardingTip(action.payload.type, action.payload.value);
        },
    },
});

export const onboardingActions = actions;
export const onboardingReducer = reducer;

const getBase = (state: State): OnboardinggState | undefined => get(state, name);

export const getShowOnboardingTip = (state: State, tip: Tips): boolean => get(getBase(state), tip, false);
