import * as t from 'io-ts';
import { ResourceLinkList } from './resourceLinkCodec';

const tag = t.type({
    id: t.string,
});

const embedded = t.type({
    tags: t.array(tag),
});

const apiDriver = t.type({
    id: t.string,
    display_name: t.string,
    status: t.string,
    _embedded: embedded,
});

const apiResponseDrivers = t.type({
    _links: ResourceLinkList,
    items: t.array(apiDriver),
});

export const apiResponseDriverCodec = apiResponseDrivers;

export type ApiDriver = t.TypeOf<typeof apiDriver>;
export type ApiDriversResponse = t.TypeOf<typeof apiResponseDrivers>;
