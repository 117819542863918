import { useEffect } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type TranslatedAppTitleUpdateProps = WrappedComponentProps;

const TranslatedAppTitleUpdate = (props: TranslatedAppTitleUpdateProps): null => {
    const { intl } = props;
    useEffect(() => {
        // Update the document title using the browser API
        window.document.title = intl.formatMessage({ id: 'intl-msg:timed.common.subModuleName.timed' });
    });
    return null;
};

export const TranslatedAppTitleUpdateContainer = injectIntl(TranslatedAppTitleUpdate);
