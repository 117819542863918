/* eslint-disable */
import * as t from 'io-ts';

export const tag = t.type({
    id: t.string,
    type: t.string,
    name: t.string,
    account_id: t.string,
});

export const apiTagCodec = t.type({
    items: t.array(tag),
});

export type ApiResponseTags = t.TypeOf<typeof apiTagCodec>;
