import { findIndex } from 'lodash';
import { ApiStateChangeItem, StateChange } from '../types';
import { ApiResponseActivity } from '../iots/activitiesCodec';

const getExistingTourIndex = (activities: StateChange[], item: ApiResponseActivity): number =>
    findIndex(
        activities,
        (activity) => activity.asset_id === item.asset_id && activity.identification === item.identification
    );

const mapToApiStateChangeItem = (activity: ApiResponseActivity): ApiStateChangeItem => ({
    id: activity.activity_id,
    driver_id: activity.driver_id,
    identification: activity.identification,
    identification_type: activity.identification_type,
    asset_id: activity.asset_id,
    state_change_start: activity.start,
    state_change_end: activity.end,
    working_state: {
        state: activity.activity_type,
    },
});

const createNewTour = (activity: ApiResponseActivity, activities: StateChange[]): void => {
    const apiStateChangeItem = mapToApiStateChangeItem(activity);
    const newStateChangeItem = { ...apiStateChangeItem, stateChanges: [apiStateChangeItem] };
    activities.push(newStateChangeItem);
};

const updateLatestActivity = (
    activity: ApiResponseActivity,
    activities: StateChange[],
    existingTourIndex: number,
    apiStateChangeItem: ApiStateChangeItem
): void => {
    if (activity.end > (activities[existingTourIndex]?.state_change_end ?? '')) {
        activities[existingTourIndex] = { ...activities[existingTourIndex], ...apiStateChangeItem };
    }
};

const addToExistingTour = (
    activity: ApiResponseActivity,
    activities: StateChange[],
    existingTourIndex: number
): void => {
    const apiStateChangeItem = mapToApiStateChangeItem(activity);
    activities[existingTourIndex].stateChanges.push(apiStateChangeItem);
    updateLatestActivity(activity, activities, existingTourIndex, apiStateChangeItem);
};

export const mapToActivities = (data: ApiResponseActivity[]): StateChange[] => {
    const activities: StateChange[] = [];
    data.forEach((activity) => {
        const existingTourIndex = getExistingTourIndex(activities, activity);
        if (existingTourIndex === -1) {
            createNewTour(activity, activities);
        } else {
            addToExistingTour(activity, activities, existingTourIndex);
        }
    });
    return activities;
};
