import {
    DetailsDisplayStateChange,
    StateChangesDetailsDialog,
    StateChangesDetailsDialogProps,
} from './StateChangesDetailsDialog';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    getFromOfTimeRangeSelection,
    getReceivedTimestampOfStateChanges,
    getSelectedStateChangesRowId,
    getStateChangesItems,
    getToOfTimeRangeSelection,
    realtimeActions,
} from '../realtime/redux/realtime.redux';
import { StateChange } from '../api/types';
import { getDrivers, getVehicles } from '../data/redux/data.redux';
import { AssetItem, Driver } from '../data/redux/types';
import { getDriverNameOfDriverId } from '../data/service/driverService';
import { getVehicleNameOfAssetId } from '../data/service/vehicleService';
import { getDetailsSidebarDisplayItems } from '../detailssidebar/redux/detailsSidebar.redux';
import { FunctionProperties, NonFunctionProperties } from '../../utils/util';
import moment from 'moment';
import { State } from '../../reducers';

const enrichWithDriverAndVehicleName = (
    selectedItem: StateChange,
    vehicles: AssetItem[],
    drivers: Driver[]
): DetailsDisplayStateChange => {
    const driverName = getDriverNameOfDriverId(selectedItem.driver_id, drivers);
    return {
        ...selectedItem,
        driverName: driverName ? driverName : selectedItem.identification,
        vehicleName: getVehicleNameOfAssetId(selectedItem.asset_id, vehicles),
    };
};

const getStateChangesItemForDetailsDialog = (state: State): DetailsDisplayStateChange | undefined => {
    const selectedRowId = getSelectedStateChangesRowId(state);
    const stateChangesItems = getStateChangesItems(state);
    const vehicles = getVehicles(state);
    const drivers = getDrivers(state);
    const selectedItem = stateChangesItems.find((item) => item.id === selectedRowId);
    if (!selectedItem) {
        return undefined;
    }
    const result = enrichWithDriverAndVehicleName(selectedItem, vehicles, drivers);
    result.shiftTimes = getDetailsSidebarDisplayItems(state).find(
        (item) => item.driverId === selectedItem.identification
    )?.shiftTimes;
    return result;
};

const mapStateToProps = (state: State): NonFunctionProperties<StateChangesDetailsDialogProps> => ({
    historicalViewSelectionState: getStateChangesItemForDetailsDialog(state),
    receivedTimestamp: getReceivedTimestampOfStateChanges(state),
    startRange: moment(getFromOfTimeRangeSelection(state)),
    endRange: moment(getToOfTimeRangeSelection(state)),
});

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<StateChangesDetailsDialogProps> => ({
    onClose: (): void => {
        dispatch(realtimeActions.selectStateChangesTableRow(undefined));
    },
});

export const StateChangesDetailsDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StateChangesDetailsDialog);
