import { getIsCurrentValuesDisplayed } from '../../realtime/redux/realtime.redux';
import { getColumnOrder, getHiddenColumns } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import { getFeatures } from '../../data/redux/data.redux';
import { Feature } from '../../data/redux/types';

const defaultCurrentColumnOrder = [
    TableColumn.VEHICLE,
    TableColumn.DRIVER,
    TableColumn.STATUS_DURATION,
    TableColumn.CONTINUOUS_DRIVING,
    TableColumn.RESTING_TODAY_CURRENT,
    TableColumn.DRIVING_TODAY_CURRENT,
    TableColumn.DRIVING_WEEKLY_CURRENT,
    TableColumn.DRIVING_TWO_WEEKLY_CURRENT,
    TableColumn.MINIMUM_DAILY_REST_MINIMUM,
    TableColumn.CURRENT_DAILY_WORKING_TIME,
    TableColumn.CURRENT_WEEKLY_WORKING_TIME,
    TableColumn.WEEKLY_REST_MINIMUM,
    TableColumn.DRIVING_TIMES_TIMESTAMP,
    TableColumn.WORKING_TIMES_TIMESTAMP,
];

const defaultHiddenColumns = [TableColumn.WEEKLY_REST_MINIMUM];

const currentToRemainingColumnMap: { [key: string]: TableColumn } = {
    [TableColumn.CONTINUOUS_DRIVING]: TableColumn.REMAINING_CURRENT_DRIVING_TIME,
    [TableColumn.RESTING_TODAY_CURRENT]: TableColumn.REMAINING_TIME_OF_CURRENT_BREAK,
    [TableColumn.DRIVING_TODAY_CURRENT]: TableColumn.REMAINING_DRIVING_TODAY_CURRENT,
    [TableColumn.DRIVING_WEEKLY_CURRENT]: TableColumn.REMAINING_WEEK_DRIVING_REMAINING,
    [TableColumn.DRIVING_TWO_WEEKLY_CURRENT]: TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT,
    [TableColumn.MINIMUM_DAILY_REST_MINIMUM]: TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM,
    [TableColumn.CURRENT_DAILY_WORKING_TIME]: TableColumn.REMAINING_DAILY_WORKING_TIME,
    [TableColumn.CURRENT_WEEKLY_WORKING_TIME]: TableColumn.REMAINING_WEEKLY_WORKING_TIME,
};

const translateToRemaining = (columnsAfterFiltering: TableColumn[]): TableColumn[] =>
    columnsAfterFiltering.map((it) => (currentToRemainingColumnMap[it] ? currentToRemainingColumnMap[it] : it));

const calculateDrivingTimeColumns = (
    columnOrder: TableColumn[],
    hiddenColumns: TableColumn[],
    displayCurrentValues: boolean
): TableColumn[] => {
    const columnsAfterFiltering = columnOrder.filter((column) => !hiddenColumns.includes(column));
    const columns = displayCurrentValues ? columnsAfterFiltering : translateToRemaining(columnsAfterFiltering);
    return columns.filter((column) => !hiddenColumns.includes(column));
};

const checkWorkingTimesColumnsRestrictions = (column: TableColumn, state: State) => {
    if (
        column === TableColumn.CURRENT_WEEKLY_WORKING_TIME ||
        column === TableColumn.CURRENT_DAILY_WORKING_TIME ||
        column === TableColumn.WORKING_TIMES_TIMESTAMP
    ) {
        return getFeatures(state).includes(Feature.WORKING_TIMES);
    } else {
        return true;
    }
};

export const getCurrentColumns = (state: State): TableColumn[] => {
    const hiddenColumns: TableColumn[] = getHiddenColumns(state);
    const displayCurrentValues = getIsCurrentValuesDisplayed(state);
    const columnOrder = getColumnOrder(state);
    return calculateDrivingTimeColumns(columnOrder, hiddenColumns, displayCurrentValues);
};

export const getUpdatedCurrentColumnOrder = (state: State): TableColumn[] => {
    const currentColumnOrder = getColumnOrder(state);
    const availableCurrentColumns = getAvailableCurrentColumns(state);

    const columnsToAdd = availableCurrentColumns.filter((c) => !currentColumnOrder.includes(c));
    const columnsToRemove = currentColumnOrder.filter((c) => !availableCurrentColumns.includes(c));

    if (columnsToAdd.length > 0 || columnsToRemove.length > 0) {
        const modifiedColumnsInOrder = currentColumnOrder.filter((c) => !columnsToRemove.includes(c));
        columnsToAdd.forEach((tc) =>
            modifiedColumnsInOrder.splice(
                defaultCurrentColumnOrder.findIndex((it) => it === tc),
                0,
                tc
            )
        );
        return modifiedColumnsInOrder;
    } else {
        return currentColumnOrder;
    }
};

export const getAvailableCurrentColumns = (state: State): TableColumn[] => {
    return defaultCurrentColumnOrder.filter((it) => checkWorkingTimesColumnsRestrictions(it, state));
};

export const getDefaultSortBy = (): TableColumn => TableColumn.VEHICLE;

export const getDefaultHiddenColumns = (): TableColumn[] => defaultHiddenColumns;
