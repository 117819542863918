import { DrivingTimesDisplayItem } from './types';
import {
    getDrivingTimeDisplayItems,
    getSelectedActivities,
    getSelectedDrivingTimesRowId,
    getSelectedTimestampCategories,
} from '../../realtime/redux/realtime.redux';
import { sortDrivingTimesDisplayItems } from '../sorting/sortingService';
import {
    isActivityInSelectedActivities,
    isAssetIdInSelectedAssetIds,
    isDriverIdInSelectedDriverIds,
    isTimestampInSelectedTimestampCategories,
} from '../filtering/filteringService';
import { getIdsOfSelectedAssets, getIdsOfSelectedDrivers } from '../../tree/selectionService';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const setActiveRow = (state: State, items: DrivingTimesDisplayItem[]): DrivingTimesDisplayItem[] => {
    const selectedDrivingTimesRowId = getSelectedDrivingTimesRowId(state);
    const indexOfSelectedItem = items.findIndex((item) => item.id === selectedDrivingTimesRowId);
    if (indexOfSelectedItem > -1) {
        items[indexOfSelectedItem] = { ...items[indexOfSelectedItem], isActive: true };
    }
    return items;
};

const filterItemsBasedOnTreeSelection = (state: State, items: DrivingTimesDisplayItem[]): DrivingTimesDisplayItem[] => {
    const selectedAssetIds = getIdsOfSelectedAssets(state);
    const selectedDriverIds = getIdsOfSelectedDrivers(state);
    return items
        .filter((drivingTimeItem) => isAssetIdInSelectedAssetIds(drivingTimeItem.assetId, selectedAssetIds))
        .filter((drivingTimeItem) => isDriverIdInSelectedDriverIds(drivingTimeItem.driverId, selectedDriverIds));
};

const filterItemsBasedOnTableSelections = (
    state: State,
    items: DrivingTimesDisplayItem[]
): DrivingTimesDisplayItem[] => {
    const selectedActivities = getSelectedActivities(state);
    const selectedTimestampCategories = getSelectedTimestampCategories(state);

    return items
        .filter((drivingTimeItem) =>
            isActivityInSelectedActivities(drivingTimeItem[TableColumn.STATUS_DURATION], selectedActivities)
        )
        .filter((drivingTimeItem) =>
            isTimestampInSelectedTimestampCategories(
                drivingTimeItem[TableColumn.DRIVING_TIMES_TIMESTAMP],
                selectedTimestampCategories
            )
        );
};

export const getFilteredAndSortedDrivingTimesDisplayItems = (
    state: State,
    sortBy: TableColumn,
    sortDir: SortDirectionType
): DrivingTimesDisplayItem[] => {
    const drivingTimeItems = getDrivingTimeDisplayItems(state);

    const selectedItems = filterItemsBasedOnTreeSelection(state, drivingTimeItems);
    const filteredItems = filterItemsBasedOnTableSelections(state, selectedItems);
    const filteredItemsWithSelection = setActiveRow(state, filteredItems);

    return sortDrivingTimesDisplayItems(filteredItemsWithSelection, sortBy, sortDir);
};
