import { ApiEuInfringement } from '../../api/types';
import { InfringementLevel } from '../../enums/InfringementLevel';
import { assertUnreachable } from '../../../common/typescriptutils/exhaustiveCheck';

export const filterInfringementsOfRelevantTypes = (
    apiEuInfringements: ApiEuInfringement[],
    relevantTypes: string[]
): ApiEuInfringement[] => {
    const relevantTypesLowerCase = relevantTypes.map((t) => t.toLowerCase());
    return apiEuInfringements?.filter((it) => relevantTypesLowerCase.includes(it.type.toLowerCase()));
};

export const mapToInfringementLevel = (apiInfringementLevel: string): InfringementLevel | undefined => {
    switch (apiInfringementLevel.toLowerCase()) {
        case 'info':
            return InfringementLevel.INFO;
        case 'warn':
            return InfringementLevel.WARN;
        case 'alert':
            return InfringementLevel.ALERT;
        case 'minor':
            return InfringementLevel.MINOR;
        case 'serious':
            return InfringementLevel.SERIOUS;
        case 'very_serious':
            return InfringementLevel.VERY_SERIOUS;
        case 'most_serious':
            return InfringementLevel.MOST_SERIOUS;
        default:
            console.error(`Received invalid infringement level from api: ${apiInfringementLevel}`);
            return undefined;
    }
};

const getSeverityFor = (level: InfringementLevel): number => {
    switch (level) {
        case InfringementLevel.INFO:
            return 0;
        case InfringementLevel.WARN:
            return 1;
        case InfringementLevel.ALERT:
            return 2;
        case InfringementLevel.MINOR:
            return 3;
        case InfringementLevel.SERIOUS:
            return 4;
        case InfringementLevel.VERY_SERIOUS:
            return 5;
        case InfringementLevel.MOST_SERIOUS:
            return 6;
        default:
            assertUnreachable(level);
            throw new Error('Should never occur');
    }
};

export const extractMostSevereInfringementLevel = (
    apiEuInfringements: ApiEuInfringement[],
    relevantTypes: string[]
): InfringementLevel | undefined => {
    return filterInfringementsOfRelevantTypes(apiEuInfringements, relevantTypes)
        .map((inf) => mapToInfringementLevel(inf.level))
        .reduce((previous, current) => {
            if (!previous || !current) {
                return previous ?? current;
            }
            return getSeverityFor(previous) > getSeverityFor(current) ? previous : current;
        }, undefined);
};
