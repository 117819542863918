import Activity from '@rio-cloud/rio-uikit/Activity';

export enum Activities {
    RESTING = 'RESTING',
    WORKING = 'WORKING',
    AVAILABLE = 'AVAILABLE',
    DRIVING = 'DRIVING',
}

export const INACTIVE_COLOR = 'light';

export const ActivityConfig = {
    [Activities.AVAILABLE]: {
        type: 'status-available',
        color: 'status-available',
        translationId: 'intl-msg:glossary.activity.available',
        uikitActivity: Activity.AVAILABLE,
        uiString: 'status-available',
    },
    [Activities.DRIVING]: {
        type: 'status-driving',
        color: 'status-driving',
        translationId: 'intl-msg:glossary.activity.driving',
        uikitActivity: Activity.DRIVING,
        uiString: 'status-driving',
    },
    [Activities.WORKING]: {
        type: 'status-working',
        color: 'status-working',
        translationId: 'intl-msg:glossary.activity.working',
        uikitActivity: Activity.WORKING,
        uiString: 'status-working',
    },
    [Activities.RESTING]: {
        type: 'status-resting',
        color: 'status-resting',
        translationId: 'intl-msg:glossary.activity.resting',
        uikitActivity: Activity.RESTING,
        uiString: 'status-resting',
    },
};

export const convertFromApiValues = (apiString?: string): Activities | undefined => {
    switch (apiString) {
        case undefined:
            return undefined;
        case 'DRIVING':
            return Activities.DRIVING;
        case 'WORKING':
            return Activities.WORKING;
        case 'RESTING':
            return Activities.RESTING;
        case 'AVAILABLE':
            return Activities.AVAILABLE;
        default:
            return undefined;
    }
};
