import { TreeCategory } from '../tree/redux/types';
import { TableColumn } from '../enums/TableColumn';
import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

export enum TimeDisplayMode {
    CURRENT = 'current',
    REMAINING = 'remaining',
}

export interface PersistedSpaState {
    selectedAssetIds?: string[];
    selectedVehicleGroupIds?: string[];
    selectedDriverIds?: string[];
    selectedDriverGroupIds?: string[];
    selectedActivityIds?: string[];
    selectedTimestampIds?: string[];
    selectedHiddenColumnIds?: TableColumn[];
    selectedColumnOrderIds?: TableColumn[];
    selectedTimeDisplayMode?: string;
    selectedStateChangesDisplay?: boolean;
    drivingTimesChunkNumber?: number;
    stateChangesChunkNumber?: number;
    stateChangesStartNumber?: number;
    selectedDrivingTimesRowId?: string;
    selectedStateChangesRowId?: string;
    sortDir?: SortDirectionType;
    sortBy?: TableColumn;
    viewMode?: TableViewTogglesViewType;
    treeCategory?: TreeCategory;
}

export type Action = 'PUSH' | 'POP' | 'REPLACE';
