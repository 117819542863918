import React from 'react';
import { connect } from 'react-redux';
import { StateChangesTableRow } from './StateChangesTableRow';
import { StateChangesDisplayItem } from './types';
import { getStateChangesDisplayItems } from './stateChangesDataMappingService';
import { chunkResult } from '../service/chunkService';
import { getStateChangesChunkNumber } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { Dispatch } from 'redux';
import { realtimeActions } from '../../realtime/redux/realtime.redux';
import range from 'lodash/fp/range';
import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../reducers';

type TableBodyProps = TableBodyMapDispatchToProps & TableBodyMapStateToProps;

interface TableBodyMapDispatchToProps {
    onSelect: (id: string) => void;
}

interface TableBodyMapStateToProps {
    data: StateChangesDisplayItem[];
}

const TableBody = (props: TableBodyProps): JSX.Element => {
    const { data, onSelect } = props;

    // eslint-disable-next-line react/display-name
    const TableCardPlaceholder = React.memo(() => {
        const content = range(0, 10).map((index) => <tr key={index} className={'table-card-placeholder'} />);
        return <React.Fragment>{content}</React.Fragment>;
    });

    return (
        <tbody>
            {data.map((stateChangeDisplayItem, index) => {
                const onRowSelected = (): void => {
                    onSelect(stateChangeDisplayItem.id);
                };
                return (
                    <StateChangesTableRow
                        key={index}
                        stateChangesItem={stateChangeDisplayItem}
                        onRowSelected={onRowSelected}
                    />
                );
            })}
            <TableCardPlaceholder />
        </tbody>
    );
};

const getChunkedStateChanges = createSelector(getStateChangesDisplayItems, getStateChangesChunkNumber, chunkResult);

const mapStateToProps = (state: State): TableBodyMapStateToProps => ({
    data: getChunkedStateChanges(state) as StateChangesDisplayItem[],
});
const mapDispatchToProps = (dispatch: Dispatch): TableBodyMapDispatchToProps => ({
    onSelect: (id: string): void => {
        dispatch(realtimeActions.selectStateChangesTableRow(id));
    },
});

export const StateChangesTableBodyContainer = connect(mapStateToProps, mapDispatchToProps)(TableBody);
