/* eslint-disable */
import * as t from 'io-ts';

const productWithBookingDateAndStatus = t.type(
    {
        product: t.string,
        status_change_date: t.string,
        status: t.string,
    },
    'productWithBookingDateAndStatus'
);

export const productActivationsApiCodec = t.type(
    {
        asset_products: t.record(t.string, t.array(productWithBookingDateAndStatus), 'assetProduct'),
        features: t.array(t.string),
    },
    'productActivationsApiResponse'
);

export type ProductActivationsApiResponse = t.TypeOf<typeof productActivationsApiCodec>;

export type ProductWithBookingDateAndStatus = t.TypeOf<typeof productWithBookingDateAndStatus>;
