import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { fetchDataThunk } from '../data/data.thunk';
import { ITEM_POLLING_INTERVAL } from '../realtime/RealTimeConstants';
import { fetchDrivingTimesDataThunk } from '../realtime/thunks/drivingTimesData.thunk';
import { State } from '../../reducers';

interface WidgetDataFetcherProps {
    fetchData: () => void;
    fetchDrivingTimesData: (isLoadingSpinnerDisplayed: boolean) => void;
}

// converting this into React lifecycle hooks results in https://reactjs.org/docs/error-decoder.html/?invariant=185
class WidgetDataFetcher extends React.Component<WidgetDataFetcherProps> {
    private dataPollingInterval?: number;

    componentDidMount(): void {
        this.initialFetching();
        this.initializeScheduledFetching();
    }

    componentWillUnmount(): void {
        window.clearInterval(this.dataPollingInterval);
        this.dataPollingInterval = undefined;
    }

    render(): null {
        return null;
    }

    private initialFetching(): void {
        const { fetchData, fetchDrivingTimesData } = this.props;
        fetchData();
        fetchDrivingTimesData(true);
    }

    private initializeScheduledFetching(): void {
        const { fetchDrivingTimesData } = this.props;
        this.dataPollingInterval = window.setInterval(() => {
            fetchDrivingTimesData(false);
        }, ITEM_POLLING_INTERVAL);
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Dispatch, State, any>): WidgetDataFetcherProps => ({
    fetchData: (): void => {
        dispatch(fetchDataThunk);
    },
    fetchDrivingTimesData: (isLoadingSpinnerDisplayed): void => {
        dispatch(fetchDrivingTimesDataThunk(isLoadingSpinnerDisplayed));
    },
});

export const WidgetDataFetcherContainer = connect(null, mapDispatchToProps)(WidgetDataFetcher);
