export enum TableColumn {
    VEHICLE = 'vehicle',
    DRIVER = 'driver',
    STATUS_DURATION = 'statusDuration',
    CONTINUOUS_DRIVING = 'continuousDriving',
    REMAINING_CURRENT_DRIVING_TIME = 'remainingCurrentDrivingTime',
    RESTING_TODAY_CURRENT = 'restingTodayCurrent',
    REMAINING_TIME_OF_CURRENT_BREAK = 'remainingTimeOfCurrentBreakRest',
    DRIVING_TODAY_CURRENT = 'drivingTodayCurrent',
    REMAINING_DRIVING_TODAY_CURRENT = 'remainingDrivingTodayCurrent',
    DRIVING_WEEKLY_CURRENT = 'drivingWeeklyCurrent',
    REMAINING_WEEK_DRIVING_REMAINING = 'remainingWeekDrivingRemaining',
    DRIVING_TWO_WEEKLY_CURRENT = 'drivingTwoWeeklyCurrent',
    REMAINING_DRIVING_TWO_WEEKLY_CURRENT = 'remainingDrivingTwoWeeklyCurrent',
    MINIMUM_DAILY_REST_MINIMUM = 'minimumDailyRestMinimum',
    REMAINING_MINIMUM_DAILY_REST_MINIMUM = 'remainingMinimumDailyRestMinimum',
    WEEKLY_REST_MINIMUM = 'weeklyRestMinimum',
    STATE_CHANGES = 'stateChanges',
    DRIVING_TIMES_TIMESTAMP = 'drivingTimesTimestamp',
    CURRENT_DAILY_WORKING_TIME = 'currentDailyWorkingTime',
    REMAINING_DAILY_WORKING_TIME = 'remainingDailyWorkingTime',
    CURRENT_WEEKLY_WORKING_TIME = 'currentWeeklyWorkingTime',
    REMAINING_WEEKLY_WORKING_TIME = 'remainingWeeklyWorkingTime',
    ACTIVITIES_TIMESTAMP = 'activitiesTimestamp',
    WORKING_TIMES_TIMESTAMP = 'workingTimesTimestamp',
}
