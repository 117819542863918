import { combineReducers, Reducer } from 'redux';
import { configReducer, langReducer, loginReducer } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer';
import { appReducer } from './features/app/reducers/App.reducers';
import { treeReducer } from './features/tree/redux/tree.redux';
import { apiCallReducer } from './features/api/redux/api.redux';
import { dataReducer } from './features/data/redux/data.redux';
import { realtimeReducer } from './features/realtime/redux/realtime.redux';
import { tableSettingsReducer } from './features/realtime/header/tablesettings/redux/tablesettings.redux';
import { onboardingReducer } from './features/onboarding/redux/oboarding.redux';
import { serviceInformationReducer } from './features/serviceInformation/serviceInformation.redux';
import { realtimeSidebarReducer } from './features/detailssidebar/redux/detailsSidebar.redux';
import { widgetReducer } from './features/widget/redux/widget.redux';
import { configCatReducer } from './configuration/featureToggle/redux/configCat.redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = (): Reducer =>
    combineReducers({
        serviceInformation: serviceInformationReducer,
        api: apiCallReducer,
        data: dataReducer,
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        onboarding: onboardingReducer,
        realtime: combineReducers({
            table: realtimeReducer,
            sidebar: realtimeSidebarReducer,
        }),
        tokenHandling: tokenHandlingReducer,
        tableSettings: tableSettingsReducer,
        tree: treeReducer,
        widget: widgetReducer,
        configCat: configCatReducer,
    });

const tmp = rootReducer();
export type State = ReturnType<typeof tmp>;
