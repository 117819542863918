import React from 'react';
import { connect } from 'react-redux';
import { getCurrentColumns } from './columnService';
import { DrivingTimesDisplayItem } from './types';
import { chunkResult } from '../service/chunkService';
import {
    getDrivingTimesChunkNumber,
    getSortBy,
    getSortDir,
    getTableViewMode,
} from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { DrivingTimesTableRow } from './DrivingTimesTableRow';
import { Dispatch } from 'redux';
import { realtimeActions } from '../../realtime/redux/realtime.redux';
import range from 'lodash/fp/range';
import { getFilteredAndSortedDrivingTimesDisplayItems } from './drivingTimesDataPreparationService';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import { TableViewMode } from '../../realtime/header/tablesettings/redux/types';

type TableBodyProps = TableBodyMapStateToProps & TableBodyMapDispatchToProps;

interface TableBodyMapDispatchToProps {
    onSelect: (id: string) => void;
}

interface TableBodyMapStateToProps {
    columns: TableColumn[];
    data: DrivingTimesDisplayItem[];
    tableViewMode: TableViewMode;
}

const TableBody = (props: TableBodyProps): JSX.Element => {
    const { columns, data, onSelect, tableViewMode } = props;

    // eslint-disable-next-line react/display-name
    const TableCardPlaceholder = React.memo(() => {
        const content = range(0, 10).map((index) => <tr key={index} className={'table-card-placeholder'} />);
        return <React.Fragment>{content}</React.Fragment>;
    });

    return (
        <tbody>
            {data.map((drivingTimeItem, index) => {
                const onRowSelected = (): void => {
                    onSelect(drivingTimeItem.id);
                };
                return (
                    <DrivingTimesTableRow
                        key={index}
                        columns={columns}
                        drivingTimesItem={drivingTimeItem}
                        onRowSelected={onRowSelected}
                        tableViewMode={tableViewMode}
                    />
                );
            })}
            <TableCardPlaceholder />
        </tbody>
    );
};

const mapStateToProps = (state: State): TableBodyMapStateToProps => ({
    columns: getCurrentColumns(state),
    data: chunkResult(
        getFilteredAndSortedDrivingTimesDisplayItems(state, getSortBy(state), getSortDir(state)),
        getDrivingTimesChunkNumber(state)
    ),
    tableViewMode: getTableViewMode(state),
});
const mapDispatchToProps = (dispatch: Dispatch): TableBodyMapDispatchToProps => ({
    onSelect: (id: string): void => {
        dispatch(realtimeActions.selectDrivingTimesTableRow(id));
    },
});

export const DrivingTimesTableBodyContainer = connect(mapStateToProps, mapDispatchToProps)(TableBody);
