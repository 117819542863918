import { DrivingTimesDisplayItem } from '../../../tables/drivingtimes/types';
import { FormattedMessage } from 'react-intl';
import moment, { Moment } from 'moment/moment';
import { State } from '../../../../reducers';
import { NonFunctionProperties } from '../../../../utils/util';
import { connect } from 'react-redux';
import { getProductStatusOfSelectedAsset } from '../../../upselling/productStatusCalculation.service';
import { ProductStatus } from '../../../upselling/types';
import { AssetBookings, Product } from '../../../data/redux/types';
import { getAssetBookings } from '../../../data/redux/data.redux';
import IframeResizer from 'iframe-resizer-react';
import { getLocale } from '../../../../configuration';

const DDD_DATA_IS_OUTDATED_CRITERIA_IN_HOURS = 48;

interface CompliantDddInfoBoxProps {
    displayItem: DrivingTimesDisplayItem;
}

interface CompliantDddInfoBoxStateProps {
    assetBookings: AssetBookings | undefined;
    locale: string;
}

const createOutdatedDddDataInfoBox = (assetId: string) => (
    <div className="alert alert-warning alert-dismissible margin-bottom-20">
        <p className={'margin-top-0 text-size-16 text-medium'}>
            <FormattedMessage id={'intl-msg:timed.widget.workingTimes.outdatedDddDataInfoBox.heading'} />
        </p>

        <div className="margin-top-5">
            <FormattedMessage
                id={'intl-msg:timed.widget.workingTimes.outdatedDddDataInfoBox.content'}
                values={{
                    link: (chunks) => (
                        <a
                            href={`https://remotedownload.rio.cloud/#archive?selectedVehicles=${assetId}&isSettingsSidebarOpen=true`}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {chunks}
                        </a>
                    ),
                }}
            />
        </div>
    </div>
);

const createCompliantUpsellingInfoBox = (assetId: string, locale: string) => (
    <div className="alert alert-warning alert-dismissible margin-bottom-20">
        <p className={'margin-top-0 text-size-16 text-medium'}>
            <FormattedMessage id={'intl-msg:timed.widget.workingTimes.compliantUpsellingInfoBox.heading'} />
        </p>
        <div className="margin-top-5">
            <FormattedMessage id={'intl-msg:timed.widget.workingTimes.compliantUpsellingInfoBox.content'} />
            <IframeResizer
                className="margin-top-5"
                src={`https://buybutton.marketplace.rio.cloud/?productId=MAN-SKU00000004&level=21&textOnly&noPrice&buttonLeft&resourceIds=${assetId}&locale=${locale}`}
                title={'UpsellingButton'}
                height={20}
            />
        </div>
    </div>
);
const isDddDataOutdated = (timestamp: Moment | undefined): boolean => {
    return (
        timestamp === undefined ||
        timestamp.isBefore(moment().subtract(DDD_DATA_IS_OUTDATED_CRITERIA_IN_HOURS, 'hours'))
    );
};

const isCompliantBookedForSelectedAsset = (
    assetBookings: AssetBookings | undefined,
    selectedAssetId: string
): boolean => {
    const productStatus = getProductStatusOfSelectedAsset(assetBookings, selectedAssetId, Product.COMPLIANT);
    return productStatus === ProductStatus.BOOKED;
};

const CompliantDddInfoBox = (props: CompliantDddInfoBoxProps & CompliantDddInfoBoxStateProps): JSX.Element | null => {
    const { displayItem, assetBookings, locale } = props;
    const assetId = displayItem.assetId;
    if (!isCompliantBookedForSelectedAsset(assetBookings, assetId)) {
        return createCompliantUpsellingInfoBox(assetId, locale);
    }

    return isDddDataOutdated(displayItem.dddDataUntil) ? createOutdatedDddDataInfoBox(assetId) : null;
};

const mapStateToProps = (state: State): NonFunctionProperties<CompliantDddInfoBoxStateProps> => ({
    assetBookings: getAssetBookings(state),
    locale: getLocale(state),
});

export const CompliantDddInfoBoxContainer = connect(mapStateToProps)(CompliantDddInfoBox);
