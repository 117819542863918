import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    getDrivingTimesChunkNumber,
    getSortBy,
    getSortDir,
    getStateChangesChunkNumber,
    tableSettingsActions,
} from './redux/tablesettings.redux';
import { getTotalChunkNumber, hasMoreChunks, dataHasMoreThanOneChunk } from '../../../tables/service/chunkService';
import { getStateChangesDisplayItems } from '../../../tables/statechanges/stateChangesDataMappingService';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../../configuration/setup/googleTagManager';
import { getFilteredAndSortedDrivingTimesDisplayItems } from '../../../tables/drivingtimes/drivingTimesDataPreparationService';
import { VisibilityTrackerComponent } from '../../../../configuration/setup/VisbilityTracker.container';
import { State } from '../../../../reducers';

interface LoadMoreButtonStateProps {
    hasMoreItemsToDisplay: boolean;
    hasMoreThanOneChunk: boolean;
    nextChunk: number;
    totalNumberOfChunks: number;
}

interface LoadMoreButtonDispatchProps {
    onLoadMore: () => void;
}

type LoadMoreButtonProps = LoadMoreButtonStateProps & LoadMoreButtonDispatchProps & WrappedComponentProps;

const LoadMoreButton = (props: LoadMoreButtonProps): JSX.Element | null => {
    const { onLoadMore, hasMoreItemsToDisplay, hasMoreThanOneChunk, nextChunk, totalNumberOfChunks } = props;
    if (!hasMoreThanOneChunk) {
        return null;
    }

    const onLoadMoreTracked = (): void => {
        onLoadMore();
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.ANY_TABLE,
                TrackingAction.LOAD_MORE_DATA,
                `${nextChunk} of total ${totalNumberOfChunks}`
            )
        );
    };

    return (
        <div className={'align-items-center display-flex justify-content-center margin-top-25 margin-bottom-25'}>
            {hasMoreItemsToDisplay ? (
                <>
                    <VisibilityTrackerComponent
                        googleAnalyticsEvent={createGoogleAnalyticsEvent(
                            TrackingCategory.DRIVING_TIMES_VIEW,
                            TrackingAction.SWITCH_BETWEEN_DRIVING_TIME_AND_STATE_CHANGES,
                            'switch to state change table'
                        )}
                        componentIsVisible={true}
                    />
                    <button className={'btn btn-link'} type={'button'} onClick={onLoadMoreTracked}>
                        <span className={'rioglyph rioglyph-arrow-down'} />
                        <FormattedMessage id={'intl-msg:timed.driving-time-table.loadMore'} />
                    </button>
                </>
            ) : (
                <div>
                    <FormattedMessage id={'intl-msg:timed.driving-time-table.nothingMoreToLoad'} />
                </div>
            )}
        </div>
    );
};

const mapStateToDrivingTimesProps = (state: State): LoadMoreButtonStateProps => {
    const tableItems = getFilteredAndSortedDrivingTimesDisplayItems(state, getSortBy(state), getSortDir(state));
    return {
        hasMoreItemsToDisplay: hasMoreChunks(tableItems, getDrivingTimesChunkNumber(state)),
        hasMoreThanOneChunk: dataHasMoreThanOneChunk(tableItems),
        nextChunk: getDrivingTimesChunkNumber(state) + 1,
        totalNumberOfChunks: getTotalChunkNumber(tableItems),
    };
};

const mapDispatchToDrivingTimesProps = (dispatch: Dispatch): LoadMoreButtonDispatchProps => ({
    onLoadMore: (): void => {
        dispatch(tableSettingsActions.increaseDrivingTimesChunkNumber());
    },
});

export const LoadMoreDrivingTimesButtonContainer = connect(
    mapStateToDrivingTimesProps,
    mapDispatchToDrivingTimesProps
)(injectIntl(LoadMoreButton));

const mapStateToStateChangesProps = (state: State): LoadMoreButtonStateProps => {
    const tableItems = getStateChangesDisplayItems(state);
    return {
        hasMoreItemsToDisplay: hasMoreChunks(tableItems, getStateChangesChunkNumber(state)),
        hasMoreThanOneChunk: dataHasMoreThanOneChunk(tableItems),
        nextChunk: getStateChangesChunkNumber(state) + 1,
        totalNumberOfChunks: getTotalChunkNumber(tableItems),
    };
};

const mapDispatchToStateChangesProps = (dispatch: Dispatch): LoadMoreButtonDispatchProps => ({
    onLoadMore: (): void => {
        dispatch(tableSettingsActions.increaseStateChangesChunkNumber());
    },
});

export const LoadMoreStateChangesButtonContainer = connect(
    mapStateToStateChangesProps,
    mapDispatchToStateChangesProps
)(injectIntl(LoadMoreButton));
