import { FormattedMessage } from 'react-intl';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import DetailsList from './DetailsList';
import ShiftChart from './ShiftChart';
import NextDrivingPanel from './NextDrivingPanel';
import NextRestingPanel from './NextRestingPanel';
import { EmptyDataIconWithTooltip } from '../tables/elements/EmptyDataIcon';
import { MapWrapperContainer } from './map/MapWrapper.container';
import { MapHeaderContainer } from './map/MapHeader.container';
import { ErrorBoundary } from '../app/components/ErrorBoundary';
import { getTimeStampType, TimeStampCategory } from '../enums/TimeStampCategory';
import { Activities, ActivityConfig } from '../enums/Activities';
import { ClockCounterComponent } from '../tables/elements/ClockCounterComponent';
import { MAX_NUMBER_OF_DAILY_REST_VIOLATIONS } from '../legalDrivingRestrictions';
import { DetailsSidebarDisplayItem } from './types';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import MapLoader from './map/MapLoader';
import { LoadingIndicator } from '../realtime/LoadingIndicator';

interface DetailSidebarProps {
    isRealtimeSidebarOpen: boolean;
    closeSidebar: () => void;
    driverState?: DetailsSidebarDisplayItem;
    receivedTimestamp?: Moment;
}

export const DetailSidebar = (props: DetailSidebarProps): ReactElement | null => {
    const { isRealtimeSidebarOpen, closeSidebar, driverState, receivedTimestamp } = props;

    if (!driverState) {
        return null;
    }
    const driver = driverState.driverName;
    const timeStamp = driverState.timeStamp;
    const multiManning = driverState.multiManning;
    const shiftTimes = driverState.shiftTimes;
    const minimumDailyRestMinimum = driverState.minimumDailyRestMinimum;
    const remainingRestingExceptions = driverState.remainingRestingExceptions;
    const isDataUpToDate = getTimeStampType(timeStamp, receivedTimestamp) !== TimeStampCategory.OUTDATED;

    return (
        <Sidebar
            title={
                <span>
                    <FormattedMessage id={'intl-msg:timed.sidebar.detailViewFor'} />
                    {` ${driver}`}
                </span>
            }
            fly={false}
            width={550}
            bodyClassName={'padding-20'}
            titleClassName={'padding-left-10'}
            onClose={closeSidebar}
            closed={!isRealtimeSidebarOpen}
        >
            <DetailsList {...driverState} />
            <hr
                className={
                    'border-style-solid border-color-light border-width-1 border-left-0 border-right-0 ' +
                    'border-top-0 bottom-5 top-0 margin-left-0 margin-right-0 margin-top-25 margin-bottom-0 width-100pct'
                }
            />
            <ShiftChart
                timeStamp={timeStamp}
                minimumDailyRestMinimum={minimumDailyRestMinimum}
                receivedTimestamp={receivedTimestamp}
                shiftTimes={shiftTimes}
                multiManning={!!multiManning}
            />
            <div className={'panel panel-default margin-top-10'}>
                <div className={'panel-body'}>
                    <div className={'display-flex justify-content-between'}>
                        <div>
                            <FormattedMessage id={'intl-msg:timed.sidebar.dailyRestTimeExceptions'} />
                            {':'}
                        </div>
                        <div className={'display-inline-block'}>
                            {typeof remainingRestingExceptions !== 'number' ? (
                                <EmptyDataIconWithTooltip />
                            ) : (
                                <ClockCounterComponent
                                    counter={remainingRestingExceptions}
                                    maxCounter={MAX_NUMBER_OF_DAILY_REST_VIOLATIONS}
                                    activeColor={ActivityConfig[Activities.RESTING].color}
                                    tooltipId={'intl-msg:timed.common.numberOfRemainingReducedRestPeriods'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'panel panel-default'}>
                <NextDrivingPanel
                    nextActivityDuration={driverState.nextDrivingDuration}
                    nextActivityTimeLeft={driverState.nextDrivingTimeLeft}
                    isDataUpToDate={isDataUpToDate}
                />
                <NextRestingPanel
                    nextActivityDuration={driverState.nextRestingDuration}
                    nextActivityTimeLeft={driverState.nextRestingTimeLeft}
                    isDataUpToDate={isDataUpToDate}
                />
            </div>
            {multiManning && (
                <section className={'margin-bottom-10'}>
                    <label>
                        <FormattedMessage id={'intl-msg:timed.sidebar.multiManning'} />
                    </label>
                    <section className={'text-size-16'}>
                        <span className={'rioglyph rioglyph-user'} />
                        <span className={'rioglyph rioglyph-driver margin-right-5'} />
                        <FormattedMessage id={'intl-msg:timed.sidebar.multiManningDetected'} />
                    </section>
                </section>
            )}
            <div>
                <MapHeaderContainer />
                <ErrorBoundary>
                    <MapLoader fallback={<LoadingIndicator />}>
                        <MapWrapperContainer />
                        {/* <div></div>*/}
                    </MapLoader>
                </ErrorBoundary>
            </div>
        </Sidebar>
    );
};
