import { AppActions, HIDE_SESSION_EXPIRED_DIALOG } from '../actions/types';
import { AppState } from './types';

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
};

export const appReducer = (state: AppState = initialState, action: AppActions): AppState => {
    if (action.type === HIDE_SESSION_EXPIRED_DIALOG) {
        return {
            ...state,
            sessionExpiredAcknowledged: true,
        };
    } else {
        return state;
    }
};
