import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { FormattedMessage } from 'react-intl';

import RioglyphIcon from '../../../wrapper/RioglyphIcon';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TimeStampSelectDispatchProps, TimeStampSelectProps, TimeStampSelectStateProps } from '../types';
import { getSelectedTimestampCategories, realtimeActions } from '../../redux/realtime.redux';
import { TimeStampCategory } from '../../../enums/TimeStampCategory';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../../configuration/setup/googleTagManager';
import { State } from '../../../../reducers';

const TimeStampCategoryConfig = {
    [TimeStampCategory.UP_TO_DATE]: {
        color: 'success',
        extraClassName: '',
        translationId: 'intl-msg:timed.table-setting.time-selection.less2Minutes',
    },
    [TimeStampCategory.SLIGHTLY_OUTDATED]: {
        color: 'success',
        extraClassName: ' opacity-50',
        translationId: 'intl-msg:timed.table-setting.time-selection.less5Minutes',
    },
    [TimeStampCategory.OUTDATED]: {
        color: 'dark',
        extraClassName: '',
        translationId: 'intl-msg:timed.table-setting.time-selection.moreThan5Minutes',
    },
};

const TimeStampSelect = (props: TimeStampSelectProps): JSX.Element => {
    const { selectedOptions, onChange } = props;

    const getLabel = (configElement: { color: string; translationId: string; extraClassName: string }): JSX.Element => (
        <span>
            <RioglyphIcon
                type={'sphere'}
                color={configElement.color}
                className={`margin-right-5${configElement.extraClassName}`}
            />
            <FormattedMessage id={configElement.translationId} />
        </span>
    );

    const selectOptions = Object.values(TimeStampCategory).map((timeStampCategory: TimeStampCategory) => {
        const configElement = TimeStampCategoryConfig[timeStampCategory];
        return {
            id: timeStampCategory,
            label: getLabel(configElement),
            selected: selectedOptions.includes(timeStampCategory),
            textClasses: configElement.color + configElement.extraClassName,
        };
    });

    const getSelectedIcons = (): JSX.Element[] =>
        selectOptions.map((option) => (
            <RioglyphIcon
                key={`timeFrameSelect-selected-${option.id}`}
                type={'sphere'}
                color={selectedOptions.indexOf(option.id) === -1 ? 'light' : option.textClasses}
                size={'large'}
                className={'margin-left-5'}
            />
        ));

    const getCounterMessage = (): JSX.Element => <span>{getSelectedIcons()}</span>;

    const trackedOnChange = (selection: string[]): void => {
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.ANY_TABLE,
                TrackingAction.CHANGE_SELECTED_RECENCY,
                selection.join(', ')
            )
        );
        onChange(selection as TimeStampCategory[]);
    };

    return (
        <div>
            <label>
                <FormattedMessage id={'intl-msg:timed.common.drivingTimesTimestamp'} />
            </label>
            <Multiselect
                options={selectOptions}
                onChange={trackedOnChange}
                renderCounterMessage={getCounterMessage}
                placeholder={<FormattedMessage id={'intl-msg:timed.common.all'} />}
            />
        </div>
    );
};

const mapStateToProps = (state: State): TimeStampSelectStateProps => ({
    selectedOptions: getSelectedTimestampCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch): TimeStampSelectDispatchProps => ({
    onChange: (selection: TimeStampCategory[]): void => {
        dispatch(realtimeActions.setSelectedTimeStampCategories(selection));
        dispatch(realtimeActions.selectDrivingTimesTableRow());
    },
});

export const TimeStampSelectContainer = connect(mapStateToProps, mapDispatchToProps)(TimeStampSelect);
