import { injectIntl } from 'react-intl';
import { NonFunctionProperties } from '../../utils/util';
import { getSelectedVehicles } from '../tree/redux/tree.redux';
import { WrappedComponentProps } from 'react-intl/lib';
import { connect } from 'react-redux';
import IframeResizer from 'iframe-resizer-react';
import { State } from '../../reducers';

interface UpsellingBuyButtonProps {
    selectedAssetId: string | undefined;
}

const marketplaceUrlForTimed = 'https://upselling.marketplace.rio.cloud/?productId=rio-sku00000027&level=10';

const UpsellingBuyButton = (props: UpsellingBuyButtonProps & WrappedComponentProps): JSX.Element => {
    const { selectedAssetId } = props;

    if (!selectedAssetId) {
        return <></>;
    }

    return (
        <div>
            <IframeResizer
                src={`${marketplaceUrlForTimed}&resourceId=${selectedAssetId}`}
                title={'UpsellingView'}
                height={0}
            />
        </div>
    );
};

const mapStateToProps = (state: State): NonFunctionProperties<UpsellingBuyButtonProps> => {
    const selectedVehicles = getSelectedVehicles(state);
    return {
        selectedAssetId: selectedVehicles.length === 1 ? selectedVehicles[0] : undefined,
    };
};

export const UpsellingBuyButtonContainer = connect(mapStateToProps)(injectIntl(UpsellingBuyButton));
