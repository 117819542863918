import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { DEFAULT_LOCALE, extractLanguage } from './lang/lang';
import langReducer from './lang/reducer';
import { getLanguageData, getLocale } from './lang/selectors';
import { configureFetchLanguageData } from './lang/services';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/actions';
import { configureMockUserManager, configureUserManager, createUserManager } from './login/login';
import loginReducer from './login/reducer';
import { handleLoginRedirect } from './login/redirect';
import { getUserAccount, isUserSessionExpired } from './login/selectors';
import configReducer from './setup/configReducer';
import { store } from './setup/store';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored, tokenExpirationAt } from './tokenHandling/actions';
import tokenHandlingReducer from './tokenHandling/reducer';
import { getAccessToken, getIdToken } from './tokenHandling/selectors';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { sendErrorToSentry } from './setup/sentry';
import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';

// eslint-disable-next-line
function main(renderApp) {
    const fetchLanguageData = configureFetchLanguageData(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            trace('index.onSessionExpired');

            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result) => {
            trace('index.onSessionRenewed', result);
            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(tokenExpirationAt(moment().add(result.expiresInSeconds, 'seconds')));
            store.dispatch(userProfileObtained(result.profile));

            datadogLogs.setGlobalContextProperty('usr', {
                id: result.profile.sub,
                account: result.profile.account,
            });

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchLanguageData(result.locale);
        },
    };

    const isAllowedToMockAuth = import.meta.env.DEV;
    const testAccessToken = sessionStorage && sessionStorage.getItem('test_access_token'); // enables mocking of authentication in production
    const userManager =
        (isAllowedToMockAuth && config.login.mockAuthorization) || testAccessToken
            ? configureMockUserManager(oauthConfig, testAccessToken)
            : configureUserManager(oauthConfig, createUserManager());

    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, userManager.signinSilent.bind(userManager));
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, userManager.signinSilent.bind(userManager));

    try {
        userManager.clearStaleState().catch((error) => sendErrorToSentry(error));
    } catch (error) {
        sendErrorToSentry(error);
    }

    attemptInitialSignIn(userManager)
        .then(() => {
            renderApp();
        })
        .catch((error) => {
            trace('could not start application', error);
        });
}

export {
    main,
    configReducer,
    getAccessToken,
    getIdToken,
    getLanguageData,
    getLocale,
    getUserAccount,
    handleLoginRedirect,
    isUserSessionExpired,
    langReducer,
    loginReducer,
    store,
    tokenHandlingReducer,
    DEFAULT_LOCALE,
};
