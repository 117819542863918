import * as t from 'io-ts';
import { decode } from '../../common/decodingUtils/iotsDecoder';

const browserPersistenceTableColumn = t.union([
    t.literal('vehicle'),
    t.literal('driver'),
    t.literal('statusDuration'),
    t.literal('statusDuration_latest'),
    t.literal('continuousDriving'),
    t.literal('remainingCurrentDrivingTime'),
    t.literal('restingTodayCurrent'),
    t.literal('remainingTimeOfCurrentBreakRest'),
    t.literal('drivingTodayCurrent'),
    t.literal('remainingDrivingTodayCurrent'),
    t.literal('drivingWeeklyCurrent'),
    t.literal('remainingWeekDrivingRemaining'),
    t.literal('drivingTwoWeeklyCurrent'),
    t.literal('remainingDrivingTwoWeeklyCurrent'),
    t.literal('minimumDailyRestMinimum'),
    t.literal('remainingMinimumDailyRestMinimum'),
    t.literal('weeklyRestMinimum'),
    t.literal('currentDailyWorkingTime'),
    t.literal('remainingDailyWorkingTime'),
    t.literal('currentWeeklyWorkingTime'),
    t.literal('remainingWeeklyWorkingTime'),
    t.literal('stateChanges'),
    t.literal('timeStamp'), // kept for backwards compatability
    t.literal('timestamp_latest'), // kept for backwards compatability
    t.literal('drivingTimesTimestamp'),
    t.literal('activitiesTimestamp'),
    t.literal('workingTimesTimestamp'),
]);

const browserPersistenceSortDir = t.union([t.literal('asc'), t.literal('desc')]);

const browserPersistenceViewMode = t.union([t.literal('TABLE'), t.literal('MULTI_CARDS'), t.literal('SINGLE_CARD')]);

const browserPersistenceTreeCategory = t.union([t.literal('AssetTree-vehicles'), t.literal('AssetTree-drivers')]);

const browserPersistenceCodec = t.partial({
    selectedAssetIds: t.array(t.string),
    selectedVehicleGroupIds: t.array(t.string),
    selectedDriverIds: t.array(t.string),
    selectedDriverGroupIds: t.array(t.string),
    selectedActivityIds: t.array(t.string),
    selectedTimestampIds: t.array(t.string),
    selectedHiddenColumnIds: t.array(browserPersistenceTableColumn),
    selectedColumnOrderIds: t.array(browserPersistenceTableColumn),
    selectedTimeDisplayMode: t.string,
    selectedStateChangesDisplay: t.boolean,
    selectedDrivingTimesRowId: t.string,
    selectedStateChangesRowId: t.string,
    drivingTimesChunkNumber: t.number,
    stateChangesChunkNumber: t.number,
    stateChangesStartNumber: t.number,
    sortDir: browserPersistenceSortDir,
    sortBy: browserPersistenceTableColumn,
    viewMode: browserPersistenceViewMode,
    treeCategory: browserPersistenceTreeCategory,
});

export type BrowserPersistenceState = t.TypeOf<typeof browserPersistenceCodec>;
export type BrowserPersistenceTableColumn = t.TypeOf<typeof browserPersistenceTableColumn>;
export type BrowserPersistenceSortDir = t.TypeOf<typeof browserPersistenceSortDir>;
export type BrowserPersistenceViewMode = t.TypeOf<typeof browserPersistenceViewMode>;
export type BrowserPersistenceTreeCategory = t.TypeOf<typeof browserPersistenceTreeCategory>;

export const decodeBrowserPersistence = (parsedObject: unknown): BrowserPersistenceState =>
    decode(parsedObject, browserPersistenceCodec);
