import { FormattedMessage } from 'react-intl';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import TableCardsSorting from '@rio-cloud/rio-uikit/TableCardsSorting';
import { TableCardsSortingHeaderProps } from './types';
import { getColumnHeaderMessageId } from './columnHeaderFormattingService';
import { TableColumn } from '../../enums/TableColumn';

export const TableCardsSortingHeader = (props: TableCardsSortingHeaderProps): JSX.Element => {
    const { sortBy, sortDir, viewType, columns, onSortChange } = props;

    const cardSortingSelectOptions = columns.map((column) => {
        return {
            id: column,
            label: <FormattedMessage id={getColumnHeaderMessageId(column)} />,
            selected: column === sortBy,
            disabled: false,
        };
    });

    return (
        <div>
            {sortBy && viewType && viewType !== TableViewToggles.VIEW_TYPE_TABLE && (
                <TableCardsSorting
                    selectOptions={cardSortingSelectOptions}
                    sortName={sortBy}
                    sortOrder={sortDir}
                    onSortChange={(name, order) => onSortChange(name as TableColumn, order)}
                />
            )}
        </div>
    );
};
