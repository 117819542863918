import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';

interface DriverCellProps {
    value?: string;
}

export const DriverCell = (props: DriverCellProps): JSX.Element => {
    const { value } = props;
    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }
    return <div>{value}</div>;
};
