import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConfigCatState {
    isFeatureToggleDummyEnabled: boolean;
}

const initialState: ConfigCatState = {
    isFeatureToggleDummyEnabled: false,
};

const { reducer, actions } = createSlice({
    name: 'configCat',
    initialState,
    reducers: {
        setIsFeatureToggleDummyEnabled: (state: ConfigCatState, action: PayloadAction<boolean>): void => {
            state.isFeatureToggleDummyEnabled = action.payload;
        },
    },
});

export const configCatActions = actions;
export const configCatReducer = reducer;
