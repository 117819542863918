import { ApiCalls, ApiLoadingState, ErrorCauses } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { State } from '../../../reducers';

const initialState: ApiLoadingState = {
    loading: {
        [ApiCalls.ASSET_TREE]: false,
    },
    error: {
        [ErrorCauses.ASSET_TREE_FETCH]: false,
        [ErrorCauses.ACCESS_DENIED_FETCH]: false,
    },
};

const name = 'api';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setLoadingForApiCall: (
            state: ApiLoadingState,
            action: PayloadAction<{ type: ApiCalls; value: boolean }>
        ): void => {
            state.loading = {
                ...state.loading,
                [action.payload.type]: action.payload.value,
            };
        },
        setErrorForApiCall: (
            state: ApiLoadingState,
            action: PayloadAction<{ type: ErrorCauses; value: boolean }>
        ): void => {
            state.error = {
                ...state.error,
                [action.payload.type]: action.payload.value,
            };
        },
    },
});

export const apiCallActions = actions;
export const apiCallReducer = reducer;

const getBase = (state: State): ApiLoadingState | undefined => get(state, 'api');
const getLoading = (state: State): { [key: string]: boolean } | undefined => get(getBase(state), 'loading');
const getError = (state: State): { [key: string]: boolean } | undefined => get(getBase(state), 'error');

export const getIsAssetTreeLoading = (state: State): boolean => get(getLoading(state), ApiCalls.ASSET_TREE, false);
export const getIsDrivingTimesLoading = (state: State): boolean =>
    get(getLoading(state), ApiCalls.DRIVING_TIMES, false);
export const getIsStateChangesLoading = (state: State): boolean =>
    get(getLoading(state), ApiCalls.STATE_CHANGES, false);

export const getHasAssetTreeFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.ASSET_TREE_FETCH, false);

export const getHasDrivingTimesFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.DRIVING_TIMES_FETCH, false);
export const getHasStateChangesFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.STATE_CHANGES_FETCH, false);
export const getHasLatestPositionFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.LATEST_POSITION_FETCH, false);
export const getHasSessionExpiredFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.SESSION_EXPIRED_FETCH, false);
export const getHasAccessDeniedFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.ACCESS_DENIED_FETCH, false);
