import { TableColumn } from '../../../enums/TableColumn';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

export const calculateSortDir = (
    currentSortDir: SortDirectionType,
    sortBy: TableColumn,
    previousSortBy: TableColumn
): SortDirectionType => {
    if (sortBy === previousSortBy) {
        return currentSortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};
