import { AssetItem, Driver } from '../redux/types';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import { getVehicleNameOfAssetId } from './vehicleService';
import { getDriverNameOfDriverId } from './driverService';
import { TableColumn } from '../../enums/TableColumn';

export const addDriverAndAssetInfoToDisplayItem = (
    displayItems: DrivingTimesDisplayItem[],
    vehicles: AssetItem[],
    drivers: Driver[]
): DrivingTimesDisplayItem[] => {
    return displayItems.map((displayItem) => {
        const driverName = getDriverNameOfDriverId(displayItem.driverId, drivers);
        return {
            ...displayItem,
            [TableColumn.VEHICLE]: getVehicleNameOfAssetId(displayItem.assetId, vehicles),
            [TableColumn.DRIVER]: driverName ? driverName : displayItem[TableColumn.DRIVER],
        };
    });
};
