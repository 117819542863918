import { EmptyDataUnknownVehicle } from '../elements/EmptyDataIcon';

interface VehicleCellProps {
    value?: string;
}

export const VehicleCell = (props: VehicleCellProps): JSX.Element => {
    const { value } = props;
    if (!value) {
        return <EmptyDataUnknownVehicle />;
    }
    return <div>{value}</div>;
};
