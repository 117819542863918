import { Dispatch } from 'redux';
import { ApiCalls, ErrorCauses } from '../../api/redux/types';
import { fetchDrivingTimes, fetchWorkingTimes } from '../../api/api';
import { ApiDrivingTimesItem, ApiWorkingTimesItem } from '../../api/types';
import { realtimeActions } from '../redux/realtime.redux';
import { apiCallActions } from '../../api/redux/api.redux';
import moment from 'moment';
import { convertToDetailsSidebarDisplayItem } from '../service/apiDrivingTimesToDetailsSidebarDisplayItemMapper';
import { getDrivers, getVehicles } from '../../data/redux/data.redux';
import { convertToDrivingTimesDisplayItem } from '../service/apiDrivingTimesToDrivingTimesDisplayItemMapper';
import { realtimeSidebarActions } from '../../detailssidebar/redux/detailsSidebar.redux';
import { isUserSessionExpired } from '../../../configuration/login/selectors';
import { logDurationWrapper } from '../../../common/timeUtils/timeMeasurementHelper';
import { State } from '../../../reducers';

export const fetchDrivingTimesDataThunk =
    (isLoadingSpinnerDisplayed: boolean) =>
    (dispatch: Dispatch, getState: () => State): void => {
        if (isUserSessionExpired(getState())) {
            dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.SESSION_EXPIRED_FETCH, value: true }));
            return;
        }

        if (isLoadingSpinnerDisplayed) {
            dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.DRIVING_TIMES, value: true }));
        }

        const requests = [
            logDurationWrapper(fetchDrivingTimes, 1000, { endpoint: 'drivingtimes' })(),
            logDurationWrapper(fetchWorkingTimes, 1000, { endpoint: 'workingtimes' })(),
        ] as Promise<ApiDrivingTimesItem[] | ApiWorkingTimesItem[]>[];

        Promise.all(requests)
            .then((result) => {
                const drivingTimes = result[0] as ApiDrivingTimesItem[];
                const workingTimes = result[1] as ApiWorkingTimesItem[];
                const vehicles = getVehicles(getState());
                const drivers = getDrivers(getState());

                const detailsSidebarDisplayItem = convertToDetailsSidebarDisplayItem(drivingTimes, vehicles, drivers);
                const drivingTimesDisplayItems = convertToDrivingTimesDisplayItem(
                    drivingTimes,
                    workingTimes,
                    vehicles,
                    drivers
                );
                dispatch(realtimeActions.fetchedDrivingTimesDisplayItems(drivingTimesDisplayItems));
                dispatch(realtimeSidebarActions.fetchedDetailsSidebarDisplayItems(detailsSidebarDisplayItem));

                dispatch(realtimeActions.setReceivedTimestampOfDrivingTimes(moment()));

                dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.DRIVING_TIMES_FETCH, value: false }));
                dispatch(
                    apiCallActions.setErrorForApiCall({
                        type: ErrorCauses.SESSION_EXPIRED_FETCH,
                        value: false,
                    })
                );
                dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.DRIVING_TIMES, value: false }));
            })
            .catch(() => {
                dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.DRIVING_TIMES_FETCH, value: true }));
                dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.DRIVING_TIMES, value: false }));
            });
    };
