import { orderBy } from 'lodash';
import { DrivingTimesDisplayItem } from '../drivingtimes/types';
import { StateChangesDisplayItem } from '../statechanges/types';
import { getSortBy, getSortDir } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { getIsCurrentValuesDisplayed } from '../../realtime/redux/realtime.redux';
import { Activities } from '../../enums/Activities';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const statusDurationSorting = [
    (it: DrivingTimesDisplayItem | StateChangesDisplayItem): Activities | undefined =>
        it[TableColumn.STATUS_DURATION]?.status,
    (it: DrivingTimesDisplayItem | StateChangesDisplayItem): number | undefined =>
        it[TableColumn.STATUS_DURATION]?.duration,
];

const weeklyRestMinimumSorting = [
    (it: DrivingTimesDisplayItem): number | undefined => it[TableColumn.WEEKLY_REST_MINIMUM]?.duration,
];

export const sortDrivingTimesDisplayItems = (
    items: DrivingTimesDisplayItem[],
    sortBy: TableColumn,
    sortDir: SortDirectionType
): DrivingTimesDisplayItem[] => {
    switch (sortBy) {
        case TableColumn.STATUS_DURATION:
            return orderBy(items, statusDurationSorting, [sortDir, sortDir]);
        case TableColumn.WEEKLY_REST_MINIMUM:
            return orderBy(items, weeklyRestMinimumSorting, [sortDir, sortDir]);
        case TableColumn.CONTINUOUS_DRIVING:
        case TableColumn.REMAINING_CURRENT_DRIVING_TIME:
        case TableColumn.RESTING_TODAY_CURRENT:
        case TableColumn.REMAINING_TIME_OF_CURRENT_BREAK:
        case TableColumn.DRIVING_TODAY_CURRENT:
        case TableColumn.REMAINING_DRIVING_TODAY_CURRENT:
        case TableColumn.DRIVING_WEEKLY_CURRENT:
        case TableColumn.REMAINING_WEEK_DRIVING_REMAINING:
        case TableColumn.DRIVING_TWO_WEEKLY_CURRENT:
        case TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT:
        case TableColumn.MINIMUM_DAILY_REST_MINIMUM:
        case TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM:
        case TableColumn.CURRENT_DAILY_WORKING_TIME:
        case TableColumn.REMAINING_DAILY_WORKING_TIME:
        case TableColumn.CURRENT_WEEKLY_WORKING_TIME:
        case TableColumn.REMAINING_WEEKLY_WORKING_TIME:
            const durationAndItemsSorting = [
                (it: DrivingTimesDisplayItem): number | undefined => it[sortBy]?.duration,
                (it: DrivingTimesDisplayItem): number | undefined => it[sortBy]?.numberOfItems,
            ];
            return orderBy(items, durationAndItemsSorting, [sortDir, sortDir]);
        default:
            return orderBy(items, sortBy, sortDir);
    }
};

export const sortStateChangesDisplayItems = (
    items: StateChangesDisplayItem[],
    sortBy: TableColumn,
    sortDir: SortDirectionType
): StateChangesDisplayItem[] => {
    if (sortBy === TableColumn.STATUS_DURATION) {
        return orderBy(items, statusDurationSorting, [sortDir, sortDir]);
    }
    return orderBy(items, sortBy, sortDir);
};

const columnInversionMapping: { [key: string]: TableColumn } = {
    [TableColumn.DRIVING_TODAY_CURRENT]: TableColumn.REMAINING_DRIVING_TODAY_CURRENT,
    [TableColumn.REMAINING_DRIVING_TODAY_CURRENT]: TableColumn.DRIVING_TODAY_CURRENT,

    [TableColumn.CONTINUOUS_DRIVING]: TableColumn.REMAINING_CURRENT_DRIVING_TIME,
    [TableColumn.REMAINING_CURRENT_DRIVING_TIME]: TableColumn.CONTINUOUS_DRIVING,

    [TableColumn.RESTING_TODAY_CURRENT]: TableColumn.REMAINING_TIME_OF_CURRENT_BREAK,
    [TableColumn.REMAINING_TIME_OF_CURRENT_BREAK]: TableColumn.RESTING_TODAY_CURRENT,

    [TableColumn.DRIVING_WEEKLY_CURRENT]: TableColumn.REMAINING_WEEK_DRIVING_REMAINING,
    [TableColumn.REMAINING_WEEK_DRIVING_REMAINING]: TableColumn.DRIVING_WEEKLY_CURRENT,

    [TableColumn.DRIVING_TWO_WEEKLY_CURRENT]: TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT,
    [TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT]: TableColumn.DRIVING_TWO_WEEKLY_CURRENT,

    [TableColumn.MINIMUM_DAILY_REST_MINIMUM]: TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM,
    [TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM]: TableColumn.MINIMUM_DAILY_REST_MINIMUM,

    [TableColumn.CURRENT_DAILY_WORKING_TIME]: TableColumn.REMAINING_DAILY_WORKING_TIME,
    [TableColumn.REMAINING_DAILY_WORKING_TIME]: TableColumn.CURRENT_DAILY_WORKING_TIME,

    [TableColumn.CURRENT_WEEKLY_WORKING_TIME]: TableColumn.REMAINING_WEEKLY_WORKING_TIME,
    [TableColumn.REMAINING_WEEKLY_WORKING_TIME]: TableColumn.CURRENT_WEEKLY_WORKING_TIME,
};

const invertSortingDependingOnView = (
    isCurrentView: boolean,
    sortBy: TableColumn,
    sortDir: SortDirectionType
): { sortBy: TableColumn; sortDir: SortDirectionType } => {
    if (isCurrentView) {
        return { sortBy, sortDir };
    } else {
        return {
            sortBy: columnInversionMapping[sortBy],
            sortDir: sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING,
        };
    }
};

export const getEnhancedSortByAndDir = (state: State): { sortBy: TableColumn; sortDir: SortDirectionType } => {
    const isCurrentView = getIsCurrentValuesDisplayed(state);
    const sortBy = getSortBy(state);
    const sortDir = getSortDir(state);

    switch (sortBy) {
        case TableColumn.VEHICLE:
        case TableColumn.DRIVER:
        case TableColumn.STATUS_DURATION:
        case TableColumn.WEEKLY_REST_MINIMUM:
        case TableColumn.STATE_CHANGES:
        case TableColumn.DRIVING_TIMES_TIMESTAMP:
        case TableColumn.ACTIVITIES_TIMESTAMP:
        case TableColumn.WORKING_TIMES_TIMESTAMP:
            return { sortBy, sortDir };
        case TableColumn.CONTINUOUS_DRIVING:
        case TableColumn.RESTING_TODAY_CURRENT:
        case TableColumn.DRIVING_TODAY_CURRENT:
        case TableColumn.DRIVING_WEEKLY_CURRENT:
        case TableColumn.DRIVING_TWO_WEEKLY_CURRENT:
        case TableColumn.MINIMUM_DAILY_REST_MINIMUM:
        case TableColumn.CURRENT_DAILY_WORKING_TIME:
        case TableColumn.CURRENT_WEEKLY_WORKING_TIME:
            return invertSortingDependingOnView(isCurrentView, sortBy, sortDir);
        case TableColumn.REMAINING_CURRENT_DRIVING_TIME:
        case TableColumn.REMAINING_TIME_OF_CURRENT_BREAK:
        case TableColumn.REMAINING_DRIVING_TODAY_CURRENT:
        case TableColumn.REMAINING_WEEK_DRIVING_REMAINING:
        case TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT:
        case TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM:
        case TableColumn.REMAINING_DAILY_WORKING_TIME:
        case TableColumn.REMAINING_WEEKLY_WORKING_TIME:
            return invertSortingDependingOnView(!isCurrentView, sortBy, sortDir);
    }
};
