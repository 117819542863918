import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { getNewestVersionFromReleaseNotes } from './releaseNotes';
import { isNewVersion } from './lastUsedVersion';
import { State } from '../../reducers';

const name = 'serviceInformation';

interface ServiceInformationState {
    showGlossaryDialog: boolean;
    showReleaseNotes: boolean;
    currentVersion: string;
}

const initialState: ServiceInformationState = {
    showGlossaryDialog: false,
    showReleaseNotes: isNewVersion(getNewestVersionFromReleaseNotes()),
    currentVersion: getNewestVersionFromReleaseNotes(),
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        toggleGlossaryDialog: (state: ServiceInformationState): void => {
            state.showGlossaryDialog = !state.showGlossaryDialog;
        },
        setShowReleaseNotes: (state: ServiceInformationState, action: PayloadAction<boolean>): void => {
            state.showReleaseNotes = action.payload;
        },
    },
});

export const serviceInformationActions = actions;
export const serviceInformationReducer = reducer;

const getBase = (state: State): Object | undefined => get(state, name);

export const getShowGlossaryDialog = (state: State): boolean => get(getBase(state), 'showGlossaryDialog') ?? false;
export const getShowReleaseNotes = (state: State): boolean => get(getBase(state), 'showReleaseNotes') ?? false;
export const getCurrentVersion = (state: State): string => get(getBase(state), 'currentVersion') ?? '';
