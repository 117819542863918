import { fetchAssets, fetchDrivers, fetchMapCredentials, fetchProductActivations, fetchTags } from '../api/api';
import { Dispatch } from 'redux';
import { ApiCalls, ErrorCauses } from '../api/redux/types';
import { Asset, AssetBookings, AssetItem, Driver, MapCredentials, ProductActivations, Tag } from './redux/types';
import { separateTrucksIntoGroups } from './groupingService';
import { dataActions } from './redux/data.redux';
import { apiCallActions } from '../api/redux/api.redux';
import {
    getColumnOrder,
    getSortBy,
    tableSettingsActions,
} from '../realtime/header/tablesettings/redux/tablesettings.redux';
import { getDrivingTimeDisplayItems, realtimeActions } from '../realtime/redux/realtime.redux';
import { addDriverAndAssetInfoToDisplayItem } from './service/updateDrivingTimesDisplayItemsService';
import { isEmpty, isEqual } from 'lodash';
import { getSubject, getUserAccount, isUserSessionExpired } from '../../configuration/login/selectors';
import {
    assignOrphanDriversToDefaultGroup,
    getDriverGroups,
    removeRestrictedDriverTags,
} from './service/driverService';
import { State } from '../../reducers';
import { getDefaultSortBy, getUpdatedCurrentColumnOrder } from '../tables/drivingtimes/columnService';

const updateDrivingTimesDisplayItems = (
    state: State,
    dispatch: Dispatch,
    vehicles: AssetItem[],
    drivers: Driver[]
): void => {
    const drivingTimeDisplayItems = getDrivingTimeDisplayItems(state);
    if (!isEmpty(drivingTimeDisplayItems)) {
        const enrichedDrivingTimesDisplayItems = addDriverAndAssetInfoToDisplayItem(
            drivingTimeDisplayItems,
            vehicles,
            drivers
        );
        dispatch(realtimeActions.fetchedDrivingTimesDisplayItems(enrichedDrivingTimesDisplayItems));
    }
};

const logWarning = (getState: () => State, message: String) => {
    const subject = getSubject(getState());
    const userAccount = getUserAccount(getState());
    console.warn(`${message} for subject ${subject} in account ${userAccount}`);
};

export const fetchDataThunk = (dispatch: Dispatch, getState: () => State): Promise<void> => {
    if (isUserSessionExpired(getState())) {
        dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.SESSION_EXPIRED_FETCH, value: true }));
        return Promise.resolve();
    }

    const requests = [
        fetchAssets(),
        fetchTags(),
        fetchDrivers(),
        fetchMapCredentials(),
        fetchProductActivations(),
    ] as Promise<Tag[] | Asset[] | Driver[] | MapCredentials | undefined | AssetBookings>[];

    dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.ASSET_TREE, value: true }));
    dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.ASSET_TREE_FETCH, value: false }));

    return Promise.all(requests)
        .then((result) => {
            const assets = result[0] as Asset[];
            const tags = result[1] as Tag[];
            const drivers = assignOrphanDriversToDefaultGroup(removeRestrictedDriverTags(tags, result[2] as Driver[]));
            const mapCredentials = result[3] as MapCredentials | undefined;
            const productActivations = result[4] as ProductActivations | undefined;

            if (mapCredentials) {
                dispatch(dataActions.fetchedMapCredentials(mapCredentials));
            }

            const groupedAssets = separateTrucksIntoGroups(assets, tags);

            updateDrivingTimesDisplayItems(getState(), dispatch, groupedAssets.trucks, drivers);
            dispatch(dataActions.fetchedVehicleGroups(groupedAssets.truckGroups));
            dispatch(dataActions.fetchedTags(tags));

            dispatch(dataActions.setAssetBookings(productActivations?.assetBookings));
            dispatch(dataActions.setFeatures(productActivations?.features || []));
            const currentColumnOrderInState = getColumnOrder(getState());
            const currentSortByInState = getSortBy(getState());
            const updatedCurrentColumnOrder = getUpdatedCurrentColumnOrder(getState());

            if (!isEqual(currentColumnOrderInState, updatedCurrentColumnOrder)) {
                dispatch(tableSettingsActions.setColumnOrder(updatedCurrentColumnOrder));
            }
            if (!updatedCurrentColumnOrder.includes(currentSortByInState)) {
                dispatch(tableSettingsActions.setSortBy(getDefaultSortBy()));
            }
            dispatch(dataActions.fetchedDrivers(drivers));
            dispatch(dataActions.fetchedDriverGroups(getDriverGroups(tags, drivers)));
            dispatch(dataActions.fetchedAssets(groupedAssets.trucks));
            dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.ASSET_TREE, value: false }));
        })
        .catch((error?: Error) => {
            if (error?.message?.includes('UNAUTHENTICATED')) {
                logWarning(getState, error?.message);
            } else if (error?.message?.includes('ACCESS_DENIED')) {
                logWarning(getState, error?.message);
                dispatch(
                    apiCallActions.setErrorForApiCall({
                        type: ErrorCauses.ACCESS_DENIED_FETCH,
                        value: true,
                    })
                );
            } else {
                console.error(error);
            }
            dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.ASSET_TREE_FETCH, value: true }));
            dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.ASSET_TREE, value: false }));
        });
};
