import { MapConfigResponseBody } from '../types';
import { MapCredentials } from '../../data/redux/types';

export const mapToMapCredentials = (body: MapConfigResponseBody): MapCredentials | undefined => {
    if (body.items.length > 0) {
        const mapConfigEntry = body.items[0];
        return {
            apikey: mapConfigEntry.api_key,
            id: mapConfigEntry.id,
            provider: mapConfigEntry.provider,
        };
    } else {
        return undefined;
    }
};
