import { TableColumn } from '../../enums/TableColumn';

const stateChangeColumns = [
    TableColumn.VEHICLE,
    TableColumn.DRIVER,
    TableColumn.STATE_CHANGES,
    TableColumn.STATUS_DURATION,
    TableColumn.ACTIVITIES_TIMESTAMP,
];

const sortableStateChangeColumns = [
    TableColumn.VEHICLE,
    TableColumn.DRIVER,
    TableColumn.STATUS_DURATION,
    TableColumn.ACTIVITIES_TIMESTAMP,
];

export const getStateChangeColumns = (): TableColumn[] => stateChangeColumns;
export const getSortableStateChangeColumns = (): TableColumn[] => sortableStateChangeColumns;
