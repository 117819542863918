import { FormattedDate, FormattedTime } from 'react-intl';
import { Moment } from 'moment';
import { getTimeStampType, TimeStampCategoryConfig } from '../../enums/TimeStampCategory';

const dateProps: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

export const LiveClock = (props: { date?: Moment }): JSX.Element => {
    const { date } = props;

    if (date) {
        const timeStampType = getTimeStampType(date);
        const configElement = TimeStampCategoryConfig[timeStampType];

        return (
            <>
                <FormattedDate value={date?.toDate()} {...dateProps} />
                {' - '}
                <FormattedTime value={date?.toDate()} />
                <span className={`rioglyph rioglyph-sphere text-${configElement.color} padding-left-3`} />
            </>
        );
    }

    // noinspection HtmlUnknownAttribute
    return (
        <>
            <span
                data-test-id="no data"
                className={'rioglyph rioglyph-question-sign text-color-light text-size-large'}
            />
        </>
    );
};
