import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';

export const logDurationWrapper =
    <F extends (...args: any[]) => any>(
        fn: F,
        threshold: number,
        metaInformation: {}
    ): ((...args: any[]) => Promise<any>) =>
    async (...args: any[]) => {
        const fnName = fn.name;
        const start = moment();
        const results = await fn(...args);
        const resultsList = results as any[];
        const end = moment();
        const executionTimeInMs = end.diff(start, 'millisecond');
        if (executionTimeInMs > threshold) {
            datadogLogs.logger.info(
                `${fnName} execution took ${executionTimeInMs} ms for ${
                    resultsList.length
                } items, which hit the threshold of ${threshold} ms with the following metaInformation: ${JSON.stringify(
                    metaInformation
                )}`
            );
        }
        return results;
    };
