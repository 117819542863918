import Button from '@rio-cloud/rio-uikit/Button';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    HistoricViewSwitchButtonDispatchProps,
    HistoricViewSwitchButtonProps,
    HistoricViewSwitchButtonStateProps,
} from '../types';
import { getIsStateChangesDisplayed, realtimeActions } from '../../redux/realtime.redux';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../../configuration/setup/googleTagManager';
import { FormattedMessage } from 'react-intl';
import { Tips } from '../../../onboarding/types';
import { ConnectedOnboardingTip } from '../../../onboarding/ConnectedOnboardingTip';
import { State } from '../../../../reducers';

const trackClickToDrivingTimes = trackingAttributes(
    TrackingCategory.STATE_CHANGES_VIEW,
    TrackingAction.SWITCH_BETWEEN_DRIVING_TIME_AND_STATE_CHANGES,
    'switch to driving times table'
);

const trackClickToStateChanges = trackingAttributes(
    TrackingCategory.DRIVING_TIMES_VIEW,
    TrackingAction.SWITCH_BETWEEN_DRIVING_TIME_AND_STATE_CHANGES,
    'switch to state change table'
);

const HistoricViewSwitchButtons = (props: HistoricViewSwitchButtonProps): JSX.Element => {
    const { isHistoricViewSelected, showDrivingTimes, showHistoricView } = props;

    return (
        <div className={'btn-group'}>
            <Button
                key={'RealTime-tableView-Button'}
                active={!isHistoricViewSelected}
                onClick={showDrivingTimes}
                {...trackClickToDrivingTimes}
            >
                <FormattedMessage id={'intl-msg:timed.table-setting.drivingtimes-view'} />
            </Button>
            <ConnectedOnboardingTip
                tipType={Tips.ACTIVITIES_VIEW}
                titleTranslationId={'intl-msg:timed.glossary.newFeature.historicalView.title'}
                contentTranslationId={'intl-msg:timed.glossary.newFeature.historicalView.text'}
            >
                <Button
                    data-testid={'switchToActivityViewButton'}
                    key={'RealTime-historicalView-Button'}
                    active={isHistoricViewSelected}
                    onClick={showHistoricView}
                    {...trackClickToStateChanges}
                >
                    <FormattedMessage id={'intl-msg:timed.table-setting.activities-view'} />
                </Button>
            </ConnectedOnboardingTip>
        </div>
    );
};

const mapStateToProps = (state: State): HistoricViewSwitchButtonStateProps => ({
    isHistoricViewSelected: getIsStateChangesDisplayed(state),
});

const mapDispatchToProps = (dispatch: Dispatch): HistoricViewSwitchButtonDispatchProps => ({
    showDrivingTimes: (): void => {
        dispatch(realtimeActions.setIsStateChangesDisplayed(false));
    },
    showHistoricView: (): void => {
        dispatch(realtimeActions.selectDrivingTimesTableRow(undefined));
        dispatch(realtimeActions.setIsStateChangesDisplayed(true));
    },
});

export const HistoricViewSwitchButtonsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoricViewSwitchButtons);
