import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { TimeScaleBarComponent } from '../elements/TimeScaleBarComponent';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../../enums/Activities';
import {
    MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK,
    MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES,
    MAXIMUM_DAILY_DRIVING_TIME_MINUTES,
} from '../../legalDrivingRestrictions';
import { ClockCounterComponent } from '../elements/ClockCounterComponent';

interface RemainingDrivingTodayCellProps {
    value?: DurationAndItems;
}

export const calculateBars = (
    duration: number
): {
    percentage10H: number;
    percentage9H: number;
    percentageRemaining: number;
} => {
    const percentageOfTime = (100 * duration) / MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES;
    const percentageOf9Hours = (100 * MAXIMUM_DAILY_DRIVING_TIME_MINUTES) / MAXIMUM_DAILY_DRIVING_TIME_EXTENDED_MINUTES;

    const percentage10H = 100 - (percentageOfTime > percentageOf9Hours ? percentageOfTime : percentageOf9Hours);
    const percentage9H = percentageOfTime >= percentageOf9Hours ? 0 : percentageOf9Hours - percentageOfTime;
    const percentageRemaining = percentageOfTime;
    return { percentage10H, percentage9H, percentageRemaining };
};

export const RemainingDrivingTodayCell = (props: RemainingDrivingTodayCellProps): JSX.Element => {
    const { value } = props;

    if (!value || value.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }
    const { percentage10H, percentage9H, percentageRemaining } = calculateBars(value.duration);

    const progressParts = [
        {
            percentage: percentage10H,
            color: 'lighter',
            tooltipId: 'intl-msg:timed.driving-time-table.10HoursDrivingTime',
        },
        {
            percentage: percentage9H,
            color: INACTIVE_COLOR,
            tooltipId: 'intl-msg:timed.driving-time-table.9HoursDrivingTime',
        },
        {
            percentage: percentageRemaining,
            color: ActivityConfig[Activities.DRIVING].color,
            tooltipId: 'intl-msg:timed.driving-time-table.dailyDrivingTime',
        },
    ];

    return (
        <TimeScaleBarComponent
            numberValue={value.duration}
            progressParts={progressParts}
            infringementLevel={value?.level}
            textColor={ActivityConfig[Activities.DRIVING].color}
        >
            <span className={'margin-right-5'} />
            <ClockCounterComponent
                counter={value.numberOfItems}
                maxCounter={MAX_NUMBER_OF_RESTING_EXCEED_PER_WEEK}
                activeColor={ActivityConfig[Activities.DRIVING].color}
            />
        </TimeScaleBarComponent>
    );
};
