import { calculateProgressPercent, CurrentTime, ProgressBar } from './DrivingTimesCell';
import { FormattedMessage } from 'react-intl';
import { TimerValueComponent } from '../../../tables/elements/TimerValueComponent';

export interface WorkingTimesCellProps {
    currentMinutes?: number;
    remainingMinutes?: number;
    testIdPrefix: string;
    headerMessageId: string;
}

interface RemainingWorkingTimeProps {
    remainingMinutes?: number;
    testIdPrefix: string;
}

const RemainingWorkingTimes = (props: RemainingWorkingTimeProps): JSX.Element | null => {
    const { remainingMinutes, testIdPrefix } = props;
    if (typeof remainingMinutes !== 'number') {
        return null;
    }
    const messageId = remainingMinutes < 0 ? 'intl-msg:timed.widget.exceeded' : 'intl-msg:timed.widget.remaining';
    const color = remainingMinutes < 0 ? 'danger' : 'darker';

    return (
        <div className={'text-center padding-bottom-10'} data-testid={`${testIdPrefix}-remaining`}>
            <span>
                <span className={'text-color-gray'}>
                    <FormattedMessage id={messageId} />:{' '}
                </span>
                <TimerValueComponent
                    color={color}
                    size={'medium'}
                    emptyDataIconSize={'medium'}
                    minutes={Math.abs(remainingMinutes)}
                />
            </span>
        </div>
    );
};

export const WorkingTimesCell = (props: WorkingTimesCellProps): JSX.Element => {
    const { currentMinutes, remainingMinutes, testIdPrefix, headerMessageId } = props;

    const maximumMinutes =
        currentMinutes !== undefined && remainingMinutes !== undefined ? currentMinutes + remainingMinutes : 0;
    const progressPercent = calculateProgressPercent(currentMinutes, maximumMinutes);
    const remainingPercent = 100 - progressPercent;

    const progressBarColor = (remainingMinutes ?? 0) < 0 ? 'progress-bar-danger' : 'progress-bar-dark';

    return (
        <>
            <CurrentTime
                headerMessageId={headerMessageId}
                testIdPrefix={testIdPrefix}
                currentMinutes={currentMinutes}
                color={'darker'}
            />
            <RemainingWorkingTimes testIdPrefix={testIdPrefix} remainingMinutes={remainingMinutes} />
            <ProgressBar
                testIdPrefix={testIdPrefix}
                progressPercent={progressPercent}
                remainingPercent={remainingPercent}
                color={progressBarColor}
            />
        </>
    );
};
