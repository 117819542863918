import { EmptyDataIcon, EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig } from '../../enums/Activities';
import { ClockCounterComponent } from '../elements/ClockCounterComponent';
import { MAX_NUMBER_OF_DAILY_REST_VIOLATIONS } from '../../legalDrivingRestrictions';
import { DurationComponent } from '../../formatting/TimeFormatting';

interface RemainingReducedRestPeriodsCellProps {
    value?: DurationAndItems;
}

export const RemainingReducedRestPeriodsCell = (props: RemainingReducedRestPeriodsCellProps): JSX.Element => {
    const color = ActivityConfig[Activities.RESTING].color;
    const { value } = props;

    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }

    return (
        <div>
            {value.duration ? (
                <DurationComponent minutes={value.duration} displayColor={color} displaySize={'16'} />
            ) : (
                <EmptyDataIcon />
            )}
            <ClockCounterComponent
                counter={value.numberOfItems}
                maxCounter={MAX_NUMBER_OF_DAILY_REST_VIOLATIONS}
                tooltipId={'intl-msg:timed.common.numberOfRemainingReducedRestPeriods'}
                activeColor={ActivityConfig[Activities.RESTING].color}
            />
        </div>
    );
};
