import { RealTimeFetcherContainer } from './RealTimeFetcher.container';
import { RealTimeContainer } from './RealTime.container';

export const RealTimeWrapper = (): JSX.Element => {
    return (
        <div className={'RealTimeContainer'}>
            <RealTimeFetcherContainer />
            <RealTimeContainer />
        </div>
    );
};
