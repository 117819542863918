import { FormattedMessage } from 'react-intl';

import { Explanation } from './Explanation';
import TimedActivitiesStep1 from './assets/timedActivitiesStep1.png';
import TimedActivitiesStep2 from './assets/timedActivitiesStep2.png';
import TimedActivitiesStep3 from './assets/timedActivitiesStep3.png';

const pictureStyle = 'margin-10 width-100pct';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage id={'intl-msg:timed.glossary.activities.step1.title'} />,
    },
    {
        icon: '2',
        label: <FormattedMessage id={'intl-msg:timed.glossary.activities.step2.title'} />,
    },
    {
        icon: '3',
        label: <FormattedMessage id={'intl-msg:timed.glossary.activities.step3.title'} />,
    },
];

export const getExplanation = (step: number): JSX.Element => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.activities.step1.text'} />
                    </div>
                    <img src={TimedActivitiesStep1} alt={'TimedActivitiesStep1'} className={`${pictureStyle}`} />
                </div>
            );
        case 1:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.activities.step2.text'} />
                    </div>
                    <img src={TimedActivitiesStep2} alt={'TimedActivitiesStep2'} className={`${pictureStyle}`} />
                </div>
            );
        case 2:
            return (
                <div>
                    <div>
                        <FormattedMessage id={'intl-msg:timed.glossary.activities.step3.text'} />
                    </div>
                    <img src={TimedActivitiesStep3} alt={'TimedActivitiesStep3'} className={`${pictureStyle}`} />
                </div>
            );
        default:
            return <div />;
    }
};

export const ActivitiesExplanation = (): JSX.Element => <Explanation steps={steps} getExplanation={getExplanation} />;
