import { ProductStatus } from './types';
import { AssetBookings, Product, ProductStatus as ProductStatusFromBookings } from '../data/redux/types';

export const getProductStatusOfSelectedAsset = (
    assetBookings: AssetBookings | undefined,
    assetId: string,
    product: Product
): ProductStatus | undefined => {
    if (assetBookings === undefined) {
        return undefined;
    }

    const assetBookingForProduct = assetBookings[assetId]?.find((assetProduct) => assetProduct.product === product);
    switch (assetBookingForProduct?.status) {
        case ProductStatusFromBookings.ACTIVE:
            return ProductStatus.BOOKED;
        case ProductStatusFromBookings.PENDING:
            return ProductStatus.PENDING;
        default:
            return ProductStatus.NOT_BOOKED;
    }
};
