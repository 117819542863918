import { connect } from 'react-redux';
import { ConnectedTableHead } from '../header/TableHead';
import { getCurrentColumns } from './columnService';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';

const mapStateToProps = (state: State): { columns: TableColumn[] } => ({
    columns: getCurrentColumns(state), // getVisibleColumns(state),
});

export const DrivingTimesTableHeadContainer = connect(mapStateToProps)(ConnectedTableHead);
