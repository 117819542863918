export enum TreeCategory {
    VEHICLES = 'AssetTree-vehicles',
    DRIVERS = 'AssetTree-drivers',
}

export interface TreeState {
    selectedVehicles: string[];
    selectedVehicleGroups: string[];
    selectedDrivers: string[];
    selectedDriverGroups: string[];
    currentCategoryId: TreeCategory;
}
