import * as t from 'io-ts';

export interface BasicResponseStructure {
    _links: {
        next?: {
            href: string | undefined;
        };
    };
    items: any[];
}

const link = t.type({
    href: t.string,
});

const selfLink = t.type({
    self: link,
});

const nextLink = t.partial({
    next: link,
});

export const ResourceLinkList = t.intersection([selfLink, nextLink]);
