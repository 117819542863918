import { TableCardsSortingHeaderDispatchProps, TableCardsSortingHeaderStateProps } from './types';
import { getTableViewMode, tableSettingsActions } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TableCardsSortingHeader } from './TableCardsSortingHeader';
import { getIsStateChangesDisplayed } from '../../realtime/redux/realtime.redux';
import { getCurrentColumns } from '../drivingtimes/columnService';
import { getEnhancedSortByAndDir } from '../sorting/sortingService';
import { State } from '../../../reducers';
import { TableColumn } from '../../enums/TableColumn';
import { getSortableStateChangeColumns } from '../statechanges/stateChangesColumnService';
import { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const mapStateToProps = (state: State): TableCardsSortingHeaderStateProps => {
    const columns = getIsStateChangesDisplayed(state) ? getSortableStateChangeColumns() : getCurrentColumns(state);
    const { sortDir, sortBy } = getEnhancedSortByAndDir(state);
    return {
        columns,
        sortBy,
        sortDir,
        viewType: getTableViewMode(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): TableCardsSortingHeaderDispatchProps => ({
    onSortChange: (sortBy: TableColumn, sortDir: SortDirectionType): void => {
        dispatch(tableSettingsActions.setSortBy(sortBy));
        dispatch(tableSettingsActions.setSortDir(sortDir));
    },
});

export const TableCardsSortingHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(TableCardsSortingHeader);
