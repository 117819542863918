import { State } from '../../../reducers';
import {
    getIsCurrentValuesDisplayed,
    getIsStateChangesDisplayed,
    getSelectedActivities,
    getSelectedDrivingTimesRowId,
    getSelectedStateChangesRowId,
    getSelectedTimestampCategories,
    realtimeActions,
} from '../../realtime/redux/realtime.redux';
import {
    getCurrentCategory,
    getSelectedDriverGroups,
    getSelectedVehicleGroups,
    treeActions,
} from '../../tree/redux/tree.redux';
import { Action, PersistedSpaState, TimeDisplayMode } from '../types';
import { TreeCategory } from '../../tree/redux/types';
import { useLocation, useNavigationType } from 'react-router';
import { useEffect } from 'react';
import { ROUTE_STATE, storage } from '../localStorage/storage';
import { parseRoute } from './urlParsingService';
import { FunctionProperties, isNonEmpty, NonFunctionProperties } from '../../../utils/util';
import { getIdsOfSelectedAssets, getIdsOfSelectedDrivers } from '../../tree/selectionService';
import { getTableViewMode, tableSettingsActions } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Activities } from '../../enums/Activities';
import { TimeStampCategory } from '../../enums/TimeStampCategory';
import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';

interface UrlParsingContainerProps {
    routeState: RouteState;
    updateRouteState: (Per: RouteState) => void;
}

interface RouterAction {
    action: Action;
    urlSearchParams: URLSearchParams;
    isFirstUserInteraction: boolean;
}

export interface RouteState {
    selectedAssetIds: string[];
    selectedVehicleGroupIds: string[];
    selectedDriverIds: string[];
    selectedDriverGroupIds: string[];
    selectedActivityIds: string[];
    selectedTimestampIds: string[];
    selectedTimeDisplayMode: string;
    selectedStateChangesDisplay: boolean;
    selectedDrivingTimesRowId?: string;
    selectedStateChangesRowId?: string;
    viewMode: TableViewTogglesViewType;
    treeCategory: TreeCategory;
}

export const mergePersistedState = (persistedSpaState: PersistedSpaState, state: RouteState): RouteState => {
    if (!persistedSpaState) {
        return state;
    }
    return {
        ...state,
        selectedAssetIds: persistedSpaState.selectedAssetIds
            ? persistedSpaState.selectedAssetIds
            : state.selectedAssetIds,
        selectedVehicleGroupIds: persistedSpaState.selectedVehicleGroupIds
            ? persistedSpaState.selectedVehicleGroupIds
            : state.selectedVehicleGroupIds,
        selectedDriverIds: persistedSpaState.selectedDriverIds
            ? persistedSpaState.selectedDriverIds
            : state.selectedDriverIds,
        selectedDriverGroupIds: persistedSpaState.selectedDriverGroupIds
            ? persistedSpaState.selectedDriverGroupIds
            : state.selectedDriverGroupIds,
        selectedActivityIds: persistedSpaState.selectedActivityIds
            ? persistedSpaState.selectedActivityIds
            : state.selectedActivityIds,
        selectedTimestampIds: persistedSpaState.selectedTimestampIds
            ? persistedSpaState.selectedTimestampIds
            : state.selectedTimestampIds,
        selectedTimeDisplayMode: persistedSpaState.selectedTimeDisplayMode
            ? persistedSpaState.selectedTimeDisplayMode
            : state.selectedTimeDisplayMode,
        selectedStateChangesDisplay: persistedSpaState.selectedStateChangesDisplay
            ? persistedSpaState.selectedStateChangesDisplay
            : state.selectedStateChangesDisplay,
        selectedDrivingTimesRowId: persistedSpaState.selectedDrivingTimesRowId
            ? persistedSpaState.selectedDrivingTimesRowId
            : state.selectedDrivingTimesRowId,
        selectedStateChangesRowId: persistedSpaState.selectedStateChangesRowId
            ? persistedSpaState.selectedStateChangesRowId
            : state.selectedStateChangesRowId,
        viewMode: persistedSpaState.viewMode ? persistedSpaState.viewMode : state.viewMode,
        treeCategory: persistedSpaState.treeCategory ? persistedSpaState.treeCategory : state.treeCategory,
    };
};

const mergeUrlState = (parsedUrlState: PersistedSpaState, state: RouteState): RouteState => {
    return {
        ...mergePersistedState(parsedUrlState, state),
        selectedAssetIds: parsedUrlState.selectedAssetIds || [],
        selectedVehicleGroupIds: parsedUrlState.selectedVehicleGroupIds || [],
        selectedDriverIds: parsedUrlState.selectedDriverIds || [],
        selectedDriverGroupIds: parsedUrlState.selectedDriverGroupIds || [],
        treeCategory: getTreeState(parsedUrlState),
    };
};
const getTreeState = (state: PersistedSpaState): TreeCategory => {
    if (isNonEmpty(state.selectedDriverIds) || isNonEmpty(state.selectedDriverGroupIds)) {
        return TreeCategory.DRIVERS;
    } else {
        return TreeCategory.VEHICLES;
    }
};

const getRouteState = (state: State): RouteState => {
    return {
        selectedAssetIds: getIdsOfSelectedAssets(state),
        selectedVehicleGroupIds: getSelectedVehicleGroups(state),
        selectedDriverIds: getIdsOfSelectedDrivers(state),
        selectedDriverGroupIds: getSelectedDriverGroups(state),
        selectedActivityIds: getSelectedActivities(state),
        selectedTimestampIds: getSelectedTimestampCategories(state),
        selectedTimeDisplayMode: getIsCurrentValuesDisplayed(state) ? 'current' : 'remaining',
        selectedStateChangesDisplay: getIsStateChangesDisplayed(state),
        selectedDrivingTimesRowId: getSelectedDrivingTimesRowId(state),
        selectedStateChangesRowId: getSelectedStateChangesRowId(state),
        viewMode: getTableViewMode(state),
        treeCategory: getCurrentCategory(state),
    };
};

const notPresent = (urlSearchParams: URLSearchParams): boolean => {
    if (!urlSearchParams) {
        return false;
    }
    const urlSearchAsArray: string[] = Array.from(urlSearchParams.values());
    return urlSearchAsArray.length === 0 || urlSearchAsArray.every((value) => (value?.length ?? 0) === 0);
};

const isWebsiteCalledWithNoParameters = (routerAction: RouterAction): boolean =>
    notPresent(routerAction.urlSearchParams) && routerAction.isFirstUserInteraction;

const isUserInteractingWithTheBrowser = (routerAction: RouterAction): boolean => routerAction.action !== 'POP';

const isWidgetDisplayed = (pathname: string): boolean => pathname === '/widget';

const UrlParsing = (props: UrlParsingContainerProps): JSX.Element => {
    const route = useLocation();
    const action = useNavigationType();
    const urlSearchParams = new URLSearchParams(route.search);

    const routerAction = {
        action,
        urlSearchParams,
        isFirstUserInteraction: !navigator.userActivation?.hasBeenActive,
    };

    useEffect(() => {
        const pathname = route.pathname;
        const search = route.search;

        if (isUserInteractingWithTheBrowser(routerAction) || isWidgetDisplayed(pathname)) {
            return;
        }
        const persistedSpaState = storage.loadPersistedSpaState(ROUTE_STATE);

        if (isWebsiteCalledWithNoParameters(routerAction) && persistedSpaState) {
            props.updateRouteState(mergePersistedState(persistedSpaState, props.routeState));
        } else {
            const parsedUrlState = parseRoute(search);
            props.updateRouteState(mergeUrlState(parsedUrlState, props.routeState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    return <div></div>;
};

const mapStateToProps = (state: State): NonFunctionProperties<UrlParsingContainerProps> => ({
    routeState: getRouteState(state),
});

const mapStringToActivity = (activities: string[]): Activities[] =>
    Object.values(Activities).filter((activity) => activities?.includes(activity));

const mapStringToTimestampCategory = (timestampIds: string[]): TimeStampCategory[] =>
    Object.values(TimeStampCategory).filter((timestamp) => timestampIds?.includes(timestamp));

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<UrlParsingContainerProps> => ({
    updateRouteState: (routeState: RouteState) => {
        if (isNonEmpty(routeState.selectedAssetIds) || isNonEmpty(routeState.selectedVehicleGroupIds)) {
            dispatch(treeActions.setSelectedVehicles(routeState.selectedAssetIds));
            dispatch(treeActions.setSelectedVehicleGroups(routeState.selectedVehicleGroupIds));
        } else {
            dispatch(treeActions.setSelectedDrivers(routeState.selectedDriverIds || []));
            dispatch(treeActions.setSelectedDriverGroups(routeState.selectedDriverGroupIds || []));
        }

        dispatch(realtimeActions.setSelectedActivities(mapStringToActivity(routeState.selectedActivityIds)));
        dispatch(
            realtimeActions.setSelectedTimeStampCategories(
                mapStringToTimestampCategory(routeState.selectedTimestampIds)
            )
        );
        dispatch(
            realtimeActions.setIsCurrentValuesDisplayed(
                routeState.selectedTimeDisplayMode !== TimeDisplayMode.REMAINING
            )
        );
        dispatch(realtimeActions.setIsStateChangesDisplayed(routeState.selectedStateChangesDisplay));
        dispatch(realtimeActions.selectDrivingTimesTableRow(routeState.selectedDrivingTimesRowId));
        dispatch(realtimeActions.selectStateChangesTableRow(routeState.selectedStateChangesRowId));

        dispatch(tableSettingsActions.setTableViewMode(routeState.viewMode));
        dispatch(treeActions.setCurrentCategory(routeState.treeCategory));
    },
});

export const UrlParsingContainer = connect(mapStateToProps, mapDispatchToProps)(UrlParsing);
