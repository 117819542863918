/* eslint-disable */
import * as t from 'io-ts';

const driverId = t.type({
    value: t.string,
    occurred_at: t.string,
});

const coordinates = t.type({
    latitude: t.number,
    longitude: t.number,
});

const position = t.type({
    value: coordinates,
    occurred_at: t.string,
});

const address = t.type({
    value: t.string,
    occurred_at: t.string,
});

const heading = t.type({
    value: t.number,
    occurred_at: t.string,
});

const speed = t.type({
    value: t.number,
    occurred_at: t.string,
});

const mileage = t.type({
    value: t.number,
    occurred_at: t.string,
});

const fuelLevel = t.type({
    value: t.number,
    occurred_at: t.string,
});

const stateOfCharge = t.type({
    value: t.number,
    occurred_at: t.string,
});

const electricRange = t.type({
    value: t.number,
    occurred_at: t.string,
});

const lastMovement = t.type({
    occurred_at: t.string,
});

const assetLiveStateOptional = t.partial({
    driver_id: driverId,
    position: position,
    address: address,
    heading: heading,
    speed: speed,
    mileage: mileage,
    fuel_level: fuelLevel,
    state_of_charge: stateOfCharge,
    electric_range: electricRange,
    last_movement: lastMovement,
});

const assetLiveStateMandatory = t.type({
    asset_id: t.string,
});

const assetLiveState = t.intersection([assetLiveStateMandatory, assetLiveStateOptional]);

export type ApiAssetLiveState = t.TypeOf<typeof assetLiveState>;

export const apiAssetLiveStateCodec = assetLiveState;
