import { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { get } from 'lodash';

import { DetailSidebarContainer } from '../detailssidebar/DetailSidebarContainer';

import { DEFAULT_LOCALE, getLanguageData, getLocale, LanguageDataInterface } from '../../configuration';
import { TranslatedAppTitleUpdateContainer } from './TranslatedAppTitleUpdate.container';
import { RouteUpdaterContainer } from '../browsercommunication/RouteUpdater.container';
import { hideSessionExpiredDialog } from './actions/App.actions';
import { createVirtPathEvent, dataLayerPush } from '../../configuration/setup/googleTagManager';
import { ApplicationHeaderContainer } from './AppHeader';
import { RealTimeWrapper } from '../realtime/RealTimeWrapper.container';
import { Dispatch } from 'redux';
import { AssetTreeContainer } from '../tree/AssetTree.container';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import { FunctionProperties, NonFunctionProperties } from '../../utils/util';
import { State } from '../../reducers';
import { LocalStorageUpdateContainer } from '../browsercommunication/LocalStorageUpdater.container';
import { BottomSheetSurvey } from '../bottomSheets/BottomSheetSurvey';
import { UrlParsingContainer } from '../browsercommunication/url/UrlParsing.container';

interface AppProperties {
    languageData: LanguageDataInterface;
    showSessionExpired: boolean;
    userLocale: string;
    hideSessionDialog: () => void;
}

const AppLayout = (props: AppProperties): JSX.Element => {
    const { userLocale, languageData, showSessionExpired } = props;

    useEffect(() => dataLayerPush(createVirtPathEvent('realtime/')), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const TWELVE_HOURS_IN_MS = 43200000;
    useEffect(() => {
        // force reload app after 12 hours, in order to be sure customers are using the latest version of the app
        setTimeout(() => {
            window.location.reload();
        }, TWELVE_HOURS_IN_MS);
    });

    return (
        <IntlProvider
            key={`intl-provider-${userLocale}`}
            locale={userLocale}
            messages={languageData}
            defaultLocale={DEFAULT_LOCALE}
        >
            <RouteUpdaterContainer />
            <LocalStorageUpdateContainer />
            <UrlParsingContainer />
            <TranslatedAppTitleUpdateContainer />
            <ApplicationLayout key={`applayout-component-${userLocale}`} className={'TachographServices'}>
                <ApplicationHeaderContainer />
                <ApplicationLayout.Sidebar>
                    <AssetTreeContainer />
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className={'right'}>
                    <DetailSidebarContainer />
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body className={'responsive'}>
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={window.location.reload}
                        show={showSessionExpired}
                    />
                    <RealTimeWrapper />
                    <BottomSheetSurvey />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

const mapStateToProps = (state: State): NonFunctionProperties<AppProperties> => ({
    languageData: getLanguageData(state),
    userLocale: getLocale(state),
    showSessionExpired: get(state, 'login.userSessionExpired') ?? false,
});

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<AppProperties> => ({
    hideSessionDialog: (): { type: string } => dispatch(hideSessionExpiredDialog()),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppLayout);
