import { ApiDrivingTimesItem } from '../../api/types';
import moment, { Moment } from 'moment';
import { get } from 'lodash';
import { StatusDuration } from '../../tables/cells/types';
import { convertFromApiValues } from '../../enums/Activities';

export const getAsNumberOfUndefinedFromApiResponse = (numberAsString: string | undefined): number | undefined =>
    numberAsString !== undefined && !isNaN(+numberAsString) ? +numberAsString : undefined;

export const getTimestampFromApiResponse = (item: ApiDrivingTimesItem): Moment => {
    return moment(item.sample_date_time);
};

const mapNullToUndefined = (value: number | undefined | null): number | undefined =>
    value === null ? undefined : value;

export const getStatusDurationFromApiResponse = (item: ApiDrivingTimesItem): StatusDuration | undefined => {
    const duration = mapNullToUndefined(
        get(item, 'embedded.driving_and_resting_details.current_duration_of_selected_activity', undefined)
    );
    const status = convertFromApiValues(get(item, 'working_state.state', undefined));
    if (status === undefined) {
        return undefined;
    }
    return { status, duration };
};
