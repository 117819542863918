import { useEffect, useRef } from 'react';

export const useEffectOnlyOnUpdate = (callback: Function, deps: any[]): void => {
    const isMount = useRef(true);

    useEffect(() => {
        if (isMount.current) {
            isMount.current = false;
        } else {
            return callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export const useEffectOnlyOnMount = (callback: Function): void => {
    useEffect(() => {
        return callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
