/* eslint-disable no-underscore-dangle */

import { Asset } from '../../data/redux/types';
import { ApiResponseAsset } from '../iots/assetsCodec';

const mapApiAssetToAsset = (apiAsset: ApiResponseAsset): Asset => ({
    accountId: apiAsset.account_id,
    id: apiAsset.id,
    name: apiAsset.name,
    status: apiAsset.status,
    type: apiAsset.type,
    tagIds: apiAsset._embedded.tags.items.map((it) => it.id),
});

export const mapToAssetList = (items: ApiResponseAsset[]): Asset[] => items.map(mapApiAssetToAsset);
