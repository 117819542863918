import { range } from 'lodash';
import { connect } from 'react-redux';
import { getFromOfTimeRangeSelection, getToOfTimeRangeSelection } from '../../realtime/redux/realtime.redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import moment, { Moment } from 'moment';
import { State } from '../../../reducers';

const FULL_WIDTH = 100;

const getTimeDisplay = (time: Moment, withDate: boolean): JSX.Element => (
    <div>
        <div>{withDate && <FormattedDate value={time.toDate()} day={'2-digit'} month={'2-digit'} />}</div>
        <div className={'text-muted'}>
            <FormattedTime value={time.toDate()} />
        </div>
    </div>
);

const displayClasses = 'display-none-sm display-none-xs display-block-md display-block-lg display-block-xl';

const getTimeLineJSX = (leftMargin: number, stepDate: moment.Moment, isTimeDisplayed: boolean): JSX.Element => (
    <div
        className={`position-absolute ${displayClasses}`}
        style={{
            left: `${leftMargin}%`,
            bottom: '-10px',
        }}
        key={`state_change_table_header-indicator-${stepDate.toISOString()}`}
    >
        <div className={'position-relative'}>
            {isTimeDisplayed && (
                <div
                    className={'StateChangesHeaderTimeDisplay position-absolute'}
                    style={{ left: '-15px', top: '-15px' }}
                >
                    {getTimeDisplay(stepDate, false)}
                </div>
            )}
            <div
                className={`height-${isTimeDisplayed ? '10' : '5'} bg-light`}
                style={{ width: '1px' }}
                data-testid={'timeline-tick'}
            />
        </div>
    </div>
);

const getLineStepLabelFunc =
    (rangeStart: Moment, rangeEnd: Moment, displayedHourSteps: number) =>
    (index: number): JSX.Element => {
        const step = index;
        const stepDate = rangeStart.clone().add(step, 'hours');
        const leftMargin = (FULL_WIDTH * step * 60) / rangeEnd.diff(rangeStart, 'minutes');
        const isTimeDisplayed = step % displayedHourSteps === 0;

        return getTimeLineJSX(leftMargin, stepDate, isTimeDisplayed);
    };

const getDistanceBetweenDisplayedHoursInHours = (rangeStart: Moment, rangeEnd: Moment): number => {
    const displayedHours = rangeEnd.diff(rangeStart, 'hours');

    if (displayedHours > 24) {
        return 8;
    } else if (displayedHours > 4) {
        return 4;
    } else {
        return 1;
    }
};

interface TimeLineProps {
    startRange: Moment;
    endRange: Moment;
}

export const TimelineComponent = (props: TimeLineProps): JSX.Element => {
    const { startRange, endRange } = props;

    const timeInHoursBetweenDisplayedTimeLines = getDistanceBetweenDisplayedHoursInHours(startRange, endRange);

    const timeLineSteps = range(endRange.diff(startRange, 'hours') + 1).map(
        getLineStepLabelFunc(startRange, endRange, timeInHoursBetweenDisplayedTimeLines)
    );

    return <div className={'w-100pct position-relative height-20'}>{timeLineSteps}</div>;
};

const mapStateToProps = (state: State): TimeLineProps => {
    const endRange = moment(getToOfTimeRangeSelection(state));
    return {
        startRange: moment(getFromOfTimeRangeSelection(state)),
        endRange: endRange.isSame(moment(), 'day') ? moment() : endRange.add(1, 'second'),
    };
};

export const TimeLineContainer = connect(mapStateToProps)(TimelineComponent);
