import * as t from 'io-ts';
import { BasicResponseStructure, ResourceLinkList } from './resourceLinkCodec';

const additionalInformation = t.partial({
    remaining_10h_driving_times: t.number,
    remaining_reduced_daily_rest_periods: t.number,
    unknown_periods: t.boolean,
    data_availability_on_driver_card: t.boolean,
    weekly_rest_period_calculation_status: t.boolean,
    multi_manning_detection_status: t.boolean,
    time_overlap_detection_status: t.boolean,
});

const drivingAndRestingDetails = t.partial({
    specification: t.string,
    source: t.string,
    continuous_driving_time: t.number,
    cumulative_break_time: t.number,
    current_duration_of_selected_activity: t.number,
    cumulated_driving_time_previous_and_current_week: t.number,
    end_of_last_daily_rest_period: t.number,
    end_of_last_weekly_rest_period: t.number,
    end_of_second_last_weekly_rest_period: t.number,
    current_daily_driving_time: t.number,
    current_weekly_driving_time: t.number,
    time_left_until_new_daily_rest_period: t.number,
    time_left_until_new_weekly_rest_period: t.number,
    number_of_times_9h_daily_driving_times_exceeded: t.number,
    cumulative_uninterrupted_rest_time: t.number,
    minimum_daily_rest: t.number,
    minimum_weekly_rest: t.number,
    maximum_daily_period: t.number,
    maximum_daily_driving_time: t.number,
    number_of_used_reduced_daily_rest_periods: t.number,
    remaining_current_driving_time: t.number,
    remaining_driving_time_on_current_shift: t.number,
    remaining_driving_time_of_current_week: t.number,
    remaining_2_weeks_driving_time: t.number,
    time_left_until_next_driving_period: t.number,
    duration_of_next_driving_period: t.number,
    duration_of_next_break_rest: t.number,
    remaining_time_of_current_break_rest: t.number,
    remaining_time_until_next_break_or_rest: t.number,
    open_compensation_in_the_last_week: t.number,
    open_compensation_in_week_before_last: t.number,
    open_compensation_in_2nd_week_before_last: t.number,
    additional_information: additionalInformation,
});

const euInfringement = t.type({
    type: t.string,
    category: t.string,
    target: t.string,
    actual: t.string,
    deviation: t.string,
    level: t.string,
});

const euShiftTimes = t.type({
    shift_start: t.string,
    shift_end: t.string,
    shift_time: t.number,
    shift_max: t.number,
    shift_exceeded: t.boolean,
    remaining_shift_time: t.number,
    daily_rest_warning: t.boolean,
});

const drivingTimeSampleOptionalFields = t.partial({
    driving_and_resting_details: drivingAndRestingDetails,
    eu_infringements: t.array(euInfringement),
    eu_shift_times: euShiftTimes,
});

const drivingTimeSampleRequiredFields = t.type({
    sample_id: t.string,
    driver_id: t.string,
    asset_id: t.string,
    identification: t.string,
    identification_type: t.string,
    sample_date_time: t.string,
    activity_type: t.string,
});

const apiResponseDrivingTimeSample = t.intersection([drivingTimeSampleRequiredFields, drivingTimeSampleOptionalFields]);

export const apiResponseDrivingTimeSampleListCodec = t.type({
    _links: ResourceLinkList,
    items: t.array(apiResponseDrivingTimeSample),
});

export type ApiResponseDrivingTimeSample = t.TypeOf<typeof apiResponseDrivingTimeSample>;

export type ApiResponseDrivingTimes = BasicResponseStructure & t.TypeOf<typeof apiResponseDrivingTimeSampleListCodec>;
