import { getLatestAssetId } from '../redux/detailsSidebar.redux';
import { LiveMonitorUrlParameterKeys } from '../types';
import { State } from '../../../reducers';

const baseUrl = 'https://livemonitor.rio.cloud/#map';

export const buildUrl = (state: State): string => {
    const assetId = getLatestAssetId(state);

    if (!assetId) {
        return baseUrl;
    }

    return `${baseUrl}/${assetId}?${LiveMonitorUrlParameterKeys.SELECTED_ASSET_IDS}=${assetId}`;
};
