import { FormattedMessage } from 'react-intl';

import { Explanation } from './Explanation';
import TimedWorkingTimesStep1 from './assets/timedWorkingTimesStep1.png';

const pictureStyle = 'margin-10 width-100pct';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage id={'intl-msg:timed.glossary.workingTimes.step1.title'} />,
    },
    {
        icon: '2',
        label: <FormattedMessage id={'intl-msg:timed.glossary.workingTimes.step2.title'} />,
    },
];

const getExplanation = (step: number): JSX.Element => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div className={'text-left'}>
                        <FormattedMessage id={'intl-msg:timed.glossary.workingTimes.step1.text'} />
                    </div>
                    <img src={TimedWorkingTimesStep1} alt={'TimedWorkingTimesStep1'} className={`${pictureStyle}`} />
                </div>
            );
        case 1:
            return (
                <div>
                    <div className={'text-left'}>
                        <FormattedMessage
                            id={'intl-msg:timed.glossary.workingTimes.step2.text'}
                            values={{
                                ul: (chunks) => <ul>{chunks}</ul>,
                                li: (chunks) => <li>{chunks}</li>,
                                br: <br />,
                            }}
                        />
                    </div>
                </div>
            );
        default:
            return <div />;
    }
};

export const WorkingtimesExplanation = (): JSX.Element => <Explanation steps={steps} getExplanation={getExplanation} />;
