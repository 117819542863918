import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { TimeScaleBarComponent } from '../elements/TimeScaleBarComponent';
import { DurationAndItems } from './types';
import { INACTIVE_COLOR } from '../../enums/Activities';

interface WorkingTimesTableCellProps {
    selectedValue?: DurationAndItems;
    restValue?: DurationAndItems;
    showRemaining: boolean;
}

export const WorkingTimesTableCell = (props: WorkingTimesTableCellProps): JSX.Element => {
    const { selectedValue, restValue, showRemaining } = props;

    if (!selectedValue || selectedValue.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }

    const maximumMinutes = restValue?.duration !== undefined ? selectedValue.duration + restValue?.duration : 0;
    const progressPercent = maximumMinutes ? Math.min(selectedValue.duration / maximumMinutes, 1) * 100 : 0;

    const progressParts = [
        {
            percentage: progressPercent,
            color: 'darker',
        },
        {
            percentage: 100 - progressPercent,
            color: INACTIVE_COLOR,
        },
    ];
    if (showRemaining) {
        progressParts.reverse();
    }

    return (
        <TimeScaleBarComponent
            numberValue={Math.max(selectedValue.duration, 0)}
            progressParts={progressParts}
            infringementLevel={selectedValue?.level}
            textColor={'bg-darker'}
        />
    );
};
