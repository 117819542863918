import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getTableViewMode, tableSettingsActions } from './tablesettings/redux/tablesettings.redux';
import { TableModeButtonDispatchProps, TableModeButtonProps, TableModeButtonStateProps } from './types';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../configuration/setup/googleTagManager';
import { State } from '../../../reducers';

const TableModeButtons = (props: TableModeButtonProps): JSX.Element => {
    const { viewType, onViewTypeChange } = props;

    const onViewTypeChangeTracked = (viewMode: TableViewTogglesViewType): void => {
        onViewTypeChange(viewMode);
        dataLayerPush(
            createGoogleAnalyticsEvent(TrackingCategory.ANY_TABLE, TrackingAction.CHANGE_TABLE_VIEW_MODE, viewMode)
        );
    };

    return (
        <div className={'btn-group'}>
            <TableViewToggles initialViewType={viewType} onViewTypeChange={onViewTypeChangeTracked} />
        </div>
    );
};

const mapStateToProps = (state: State): TableModeButtonStateProps => ({
    viewType: getTableViewMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch): TableModeButtonDispatchProps => ({
    onViewTypeChange: (viewMode: TableViewTogglesViewType): void => {
        dispatch(tableSettingsActions.setTableViewMode(viewMode));
    },
});

export const TableModeButtonContainer = connect(mapStateToProps, mapDispatchToProps)(TableModeButtons);
