import { get } from 'lodash';
import { LoginState, UserProfileType } from '../index';
import { Moment } from 'moment';
import { State } from '../../reducers';

const getBase = (state: State): LoginState | undefined => get(state, 'login');

export const getTokenExpiredAt = (state: State): Moment | null | undefined =>
    get(state, 'tokenHandling.tokenExpiredAt', null);

export const isUserSessionExpired = (state: State): boolean => get(getBase(state), 'userSessionExpired') ?? false;

export const getUserProfile = (state: State): UserProfileType | null => get(getBase(state), 'userProfile', null);
export const hasUserSessionEverExpired = (state: State): boolean =>
    get(getUserProfile(state), 'hasUserSessionEverExpired') ?? false;
export const getSubject = (state: State): string | undefined => get(getUserProfile(state), 'sub', '');
export const getUserAccount = (state: State): string | undefined => get(getUserProfile(state), 'account', '');
