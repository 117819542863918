import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableSettingsState } from './types';
import { get } from 'lodash';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { State } from '../../../../../reducers';
import { TableColumn } from '../../../../enums/TableColumn';
import {
    getAvailableCurrentColumns,
    getDefaultHiddenColumns,
    getDefaultSortBy,
} from '../../../../tables/drivingtimes/columnService';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const name = 'tableSettings';

const initialState: TableSettingsState = {
    sortBy: undefined,
    sortDir: SortDirection.ASCENDING,
    tableViewMode: TableViewToggles.VIEW_TYPE_TABLE as TableViewTogglesViewType, // TODO remove this cast after UIKit upgrade
    showTableSettings: false,
    columnOrder: undefined,
    hiddenColumns: undefined,
    drivingTimesChunkNumber: 1,
    stateChangesChunkNumber: 1,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setSortBy: (state: TableSettingsState, action: PayloadAction<TableColumn>): void => {
            state.sortBy = action.payload;
        },
        setSortDir: (state: TableSettingsState, action: PayloadAction<SortDirectionType>): void => {
            state.sortDir = action.payload;
        },
        setTableViewMode: (state: TableSettingsState, action: PayloadAction<TableViewTogglesViewType>): void => {
            state.tableViewMode = action.payload;
        },
        toggleShowTableSettings: (state: TableSettingsState): void => {
            state.showTableSettings = !state.showTableSettings;
        },
        setHiddenColumns: (state: TableSettingsState, action: PayloadAction<TableColumn[]>): void => {
            state.hiddenColumns = action.payload;
        },
        setColumnOrder: (state: TableSettingsState, action: PayloadAction<TableColumn[]>): void => {
            state.columnOrder = action.payload;
        },
        increaseDrivingTimesChunkNumber: (state: TableSettingsState): void => {
            state.drivingTimesChunkNumber = state.drivingTimesChunkNumber + 1;
        },
        increaseStateChangesChunkNumber: (state: TableSettingsState): void => {
            state.stateChangesChunkNumber = state.stateChangesChunkNumber + 1;
        },
    },
});

export const tableSettingsActions = actions;
export const tableSettingsReducer = reducer;

const getBase = (state: State): TableSettingsState | undefined => get(state, name);

export const getSortBy = (state: State): TableColumn => get(getBase(state), 'sortBy', getDefaultSortBy());
export const getSortDir = (state: State): SortDirectionType => get(getBase(state), 'sortDir', SortDirection.ASCENDING);
export const getTableViewMode = (state: State): TableViewTogglesViewType =>
    get(getBase(state), 'tableViewMode', TableViewToggles.VIEW_TYPE_TABLE as TableViewTogglesViewType); // TODO remove this cast after UIKit upgrade
export const getShowTableSettings = (state: State): boolean => get(getBase(state), 'showTableSettings', false);
export const getColumnOrder = (state: State): TableColumn[] =>
    get(getBase(state), 'columnOrder', getAvailableCurrentColumns(state));
export const getHiddenColumns = (state: State): TableColumn[] =>
    get(getBase(state), 'hiddenColumns', getDefaultHiddenColumns());
export const getDrivingTimesChunkNumber = (state: State): number => get(getBase(state), 'drivingTimesChunkNumber', 1);
export const getStateChangesChunkNumber = (state: State): number => get(getBase(state), 'stateChangesChunkNumber', 1);
