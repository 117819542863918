import { useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import {
    getFromOfTimeRangeSelection,
    getIsTimeRangePreSelectionSelected,
    getToOfTimeRangeSelection,
    realtimeActions,
} from '../../redux/realtime.redux';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import { FormattedMessage } from 'react-intl';
import { fetchStateChangesDataThunk } from '../../thunks/stateChangesData.thunk';
import { ThunkDispatch } from 'redux-thunk';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../../../configuration/setup/googleTagManager';
import { State } from '../../../../reducers';
import { getLocale } from '../../../../configuration';

type TimeRangeSelectionProps = TimeRangeSelectionStateProps & TimeRangeSelectionDispatchProps;

const TimeRangeSelection = (props: TimeRangeSelectionProps): JSX.Element => {
    const { from, to, isPreSelectionSet, onSelectFrom, onSelectTo, fetchStateChanges, preSelectionSelected } = props;

    const locale = useSelector(getLocale);

    const setFromAndToForWholeDay = (daysInThePast: number) => (): void => {
        preSelectionSelected(true);
        onSelectFrom(moment().subtract(daysInThePast, 'day'));
        onSelectTo(moment().subtract(daysInThePast, 'day'));
        fetchStateChangesAndTrack();
    };

    const onSelectYesterdayAndToday = (): void => {
        preSelectionSelected(true);
        onSelectFrom(moment().subtract(1, 'day'));
        onSelectTo(moment());
        fetchStateChangesAndTrack();
    };

    const timeRangePresets = [
        {
            value: <FormattedMessage id={'intl-msg:timed.table-setting.time-selection.today'} />,
            onSelect: setFromAndToForWholeDay(0),
        },
        {
            value: <FormattedMessage id={'intl-msg:timed.table-setting.time-selection.yesterday'} />,
            onSelect: setFromAndToForWholeDay(1),
        },
        {
            value: (
                <div data-testid={'yesterday-and-today'}>
                    <FormattedMessage id={'intl-msg:timed.table-setting.time-selection.yesterday-and-today'} />
                </div>
            ),
            onSelect: onSelectYesterdayAndToday,
        },
    ];

    const isValidDateForToSelection = (currentDate: Moment): boolean => {
        const start = moment(from).startOf('day');
        const end = moment(from).clone().add(1, 'day').endOf('day');

        return currentDate.isBetween(start, end, 'minutes', '[]') && !currentDate.isAfter(moment().endOf('day'));
    };

    const isValidDateForFromSelection = (currentDate: Moment): boolean => {
        const start = moment().subtract(90, 'day');
        const end = moment();

        return currentDate.isBetween(start, end, 'day', '[]');
    };

    const setAllowedDateOfInputTo = (selectedDate: Moment): void => {
        selectedDate.local();
        const diffInDays = moment(to).diff(selectedDate.clone().endOf('day'), 'day');
        if (diffInDays < 0 || diffInDays > 1) {
            onSelectTo(selectedDate.clone().add(1, 'day'));
        }
    };

    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) {
            if (!isPreSelectionSet) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document.querySelectorAll('.DatePicker input')[1].focus();
            }
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from]);

    const fetchStateChangesAndTrack = (): void => {
        fetchStateChanges();
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.STATE_CHANGES_VIEW,
                TrackingAction.CHANGE_SELECTED_TIME_RANGE,
                `Time range duration: ${moment(to).diff(moment(from), 'day') + 1} day(s). Time range start: ${moment()
                    .endOf('day')
                    .diff(from, 'day')} day(s) in the past.a Pre-selection: ${isPreSelectionSet}`
            )
        );
    };

    return (
        <div className="display-flex align-items-end gap-10">
            <div data-testid={'TimeRangePreSelection'}>
                <SimpleButtonDropdown
                    title={<span className={'rioglyph rioglyph-settings'} aria-hidden={'true'} />}
                    items={timeRangePresets}
                    iconOnly
                />
            </div>

            <div>
                <label>
                    <FormattedMessage id={'intl-msg:timed.activity-table.from'} />
                </label>
                <DatePicker
                    value={moment(from)}
                    locale={locale}
                    onChange={(selectedDate: Moment | string, isValid: boolean): void => {
                        if (!isValid || typeof selectedDate === 'string') {
                            return;
                        }
                        onSelectFrom(selectedDate.startOf('day'));
                        setAllowedDateOfInputTo(selectedDate);
                        preSelectionSelected(false);
                    }}
                    inputProps={{ readOnly: true, className: 'form-control bg-white width-150' }}
                    isValidDate={isValidDateForFromSelection}
                    timeFormat={false}
                    className={'min-width-150'}
                />
            </div>
            <div>
                <label>
                    <FormattedMessage id={'intl-msg:timed.activity-table.to'} />
                </label>
                <DatePicker
                    value={moment(to)}
                    locale={locale}
                    onChange={(selectedDate: Moment | string, isValid: boolean): void => {
                        if (!isValid || typeof selectedDate === 'string') {
                            return;
                        }
                        onSelectTo(selectedDate.endOf('day'));
                        preSelectionSelected(false);
                        fetchStateChangesAndTrack();
                    }}
                    inputProps={{ readOnly: true, className: 'form-control bg-white width-150' }}
                    isValidDate={isValidDateForToSelection}
                    timeFormat={false}
                    className={'min-width-150'}
                />
            </div>
        </div>
    );
};

interface TimeRangeSelectionStateProps {
    to: string;
    from: string;
    isPreSelectionSet: boolean;
}

const mapStateToProps = (state: State): TimeRangeSelectionStateProps => ({
    from: getFromOfTimeRangeSelection(state),
    to: getToOfTimeRangeSelection(state),
    isPreSelectionSet: getIsTimeRangePreSelectionSelected(state),
});

interface TimeRangeSelectionDispatchProps {
    onSelectFrom: (from: Moment) => void;
    onSelectTo: (to: Moment) => void;
    fetchStateChanges: () => void;
    preSelectionSelected: (isSelected: boolean) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Dispatch, State, any>): TimeRangeSelectionDispatchProps => ({
    onSelectFrom: (from: Moment): void => {
        dispatch(realtimeActions.setFromOfTimeRangeSelection(from.startOf('day').toISOString(true)));
    },
    onSelectTo: (to: Moment): void => {
        dispatch(realtimeActions.setToOfTimeRangeSelection(to.endOf('day').toISOString(true)));
    },
    fetchStateChanges: (): void => {
        dispatch(fetchStateChangesDataThunk(true));
    },
    preSelectionSelected: (isSelected: boolean): void => {
        dispatch(realtimeActions.setIsTimeRangePreSelectionSelected(isSelected));
    },
});

export const TimeRangeSelectionContainer = connect(mapStateToProps, mapDispatchToProps)(TimeRangeSelection);
