import { StatusDuration } from '../cells/types';
import { Activities } from '../../enums/Activities';
import { Moment } from 'moment';
import { getTimeStampType, TimeStampCategory } from '../../enums/TimeStampCategory';

export const isActivityInSelectedActivities = (
    statusDuration: StatusDuration | undefined,
    selectedActivities: Activities[]
): boolean => {
    const isNoFilterSelected = (): boolean => selectedActivities.length === 0;

    if (isNoFilterSelected()) {
        return true;
    }
    if (!statusDuration || !statusDuration.status) {
        return false;
    }
    return selectedActivities.includes(statusDuration.status);
};

export const isTimestampInSelectedTimestampCategories = (
    timestamp: Moment | undefined,
    selectedTimestampCategories: TimeStampCategory[]
): boolean => {
    const isNoFilterSelected = (): boolean => selectedTimestampCategories.length === 0;

    if (isNoFilterSelected()) {
        return true;
    }

    if (!timestamp) {
        return false;
    }

    return selectedTimestampCategories.includes(getTimeStampType(timestamp));
};

export const isAssetIdInSelectedAssetIds = (assetId: string, selectedAssetIds: string[]): boolean => {
    const isNoFilterSelected = (): boolean => selectedAssetIds.length === 0;

    if (isNoFilterSelected()) {
        return true;
    }
    return selectedAssetIds.includes(assetId);
};

export const isDriverIdInSelectedDriverIds = (
    driverId: string | null | undefined,
    selectedDriverIds: string[]
): boolean => {
    const isNoFilterSelected = (): boolean => selectedDriverIds.length === 0;
    if (isNoFilterSelected()) {
        return true;
    }
    if (!driverId) {
        return false;
    }
    return selectedDriverIds.includes(driverId);
};
