import moment, { Moment } from 'moment';

export enum TimeStampCategory {
    UP_TO_DATE = 'up_to_date',
    SLIGHTLY_OUTDATED = 'slightly_outdated',
    OUTDATED = 'outdated',
}

const DANGER_TEXT_CLASSES = 'color-dark';
const WARNING_TEXT_CLASSES = 'success opacity-50';
const UP_TO_DATE_TEXT_CLASSES = 'success';

export const TimeStampCategoryConfig = {
    [TimeStampCategory.UP_TO_DATE]: {
        color: UP_TO_DATE_TEXT_CLASSES,
        translationId: 'intl-msg:timed.common.timestamp.up_to_date',
    },
    [TimeStampCategory.SLIGHTLY_OUTDATED]: {
        color: WARNING_TEXT_CLASSES,
        translationId: 'intl-msg:timed.common.timestamp.slightly_outdated',
    },
    [TimeStampCategory.OUTDATED]: {
        color: DANGER_TEXT_CLASSES,
        translationId: 'intl-msg:timed.common.timestamp.outdated',
    },
};

export const getTimeStampType = (value: Moment, momentToCompareTo = moment()): TimeStampCategory => {
    const secondsFromNow = momentToCompareTo.diff(value, 'seconds');
    if (secondsFromNow <= 2 * 60) {
        return TimeStampCategory.UP_TO_DATE;
    } else if (secondsFromNow <= 5 * 60) {
        return TimeStampCategory.SLIGHTLY_OUTDATED;
    }
    return TimeStampCategory.OUTDATED;
};
