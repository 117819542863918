import qs from 'qs';
import moment from 'moment';

const OPTIONS = {
    comma: true, // required to parse comma separated string into array
    arrayFormat: 'comma' as const, // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    decode: false,
    skipNulls: true, // required to remove empty params
};

export const getActivitiesUrlEncodedParameters = (from: string, to: string): string => {
    const params = {
        limit: 10000,
        from: moment(from).toISOString(),
        to: moment(to).toISOString(),
    };

    return `?${qs.stringify(params, OPTIONS)}`;
};
