import { accessToken } from './accessToken';
import { ACCESS_TOKEN_STORED, ID_TOKEN_STORED, TOKEN_EXPIRATION_AT } from './actions';

const initialState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACCESS_TOKEN_STORED:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ID_TOKEN_STORED:
            return {
                ...state,
                idToken: action.payload,
            };
        case TOKEN_EXPIRATION_AT:
            return {
                ...state,
                tokenExpiredAt: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
