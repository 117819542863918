import { FormattedDate, FormattedTime } from 'react-intl';
import { Moment } from 'moment/moment';

interface FormattedTimestampProps {
    date: Moment;
    displayYear?: boolean;
}

const FormattedTimeStamp = (props: FormattedTimestampProps): JSX.Element => {
    const { date, displayYear } = props;

    const dateProps: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
    };

    if (displayYear) {
        dateProps.year = 'numeric';
    }

    return (
        <span>
            <FormattedDate value={date.toDate()} {...dateProps} /> <FormattedTime value={date.toDate()} />
        </span>
    );
};

export default FormattedTimeStamp;
