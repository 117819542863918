/* eslint-disable */
import * as t from 'io-ts';
import { BasicResponseStructure, ResourceLinkList } from './resourceLinkCodec';

const tag = t.type({
    id: t.string,
});

const embedded = t.type({
    tags: t.type({
        items: t.array(tag),
    }),
});

const asset = t.type({
    id: t.string,
    account_id: t.string,
    name: t.string,
    status: t.string,
    type: t.string,
    _embedded: embedded,
});

export const apiResponseAssetListCodec = t.type({
    _links: ResourceLinkList,
    items: t.array(asset),
});

export type ApiResponseAssets = BasicResponseStructure & t.TypeOf<typeof apiResponseAssetListCodec>;
export type ApiResponseAsset = t.TypeOf<typeof asset>;
