import { FormattedDate, FormattedTime } from 'react-intl';
import { Moment } from 'moment';

const FormattedTimeStamp = (props: FormattedTimeStampProps) => {
    const { date } = props;

    if (!date) {
        return '';
    }

    const dateFormat: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    return (
        <span>
            <FormattedDate value={date.toDate()} {...dateFormat} /> <FormattedTime value={date.toDate()} />
        </span>
    );
};

interface FormattedTimeStampProps {
    date?: Moment;
}

export default FormattedTimeStamp;
