import { ApiTag } from '../types';
import { Tag } from '../../data/redux/types';
import { ApiResponseTags } from '../iots/tagCodec';

const mapApiTagToTag = (apiTag: ApiTag): Tag => ({
    id: apiTag.id,
    type: apiTag.type,
    name: apiTag.name,
    accountId: apiTag.account_id,
});

export const mapToTagList = (data: ApiResponseTags): Tag[] => data.items.map(mapApiTagToTag);
