import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeCategory, TreeState } from './types';
import { get } from 'lodash';
import { State } from '../../../reducers';

const name = 'tree';

export const initialState: TreeState = {
    selectedVehicles: [],
    selectedVehicleGroups: [],
    selectedDrivers: [],
    selectedDriverGroups: [],
    currentCategoryId: TreeCategory.VEHICLES,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setSelectedVehicles: (state: TreeState, action: PayloadAction<string[]>): void => {
            state.selectedVehicles = action.payload;
            state.selectedDrivers = [];
            state.selectedDriverGroups = [];
        },
        setSelectedVehicleGroups: (state: TreeState, action: PayloadAction<string[]>): void => {
            state.selectedVehicleGroups = action.payload;
            state.selectedDrivers = [];
            state.selectedDriverGroups = [];
        },
        setSelectedDrivers: (state: TreeState, action: PayloadAction<string[]>): void => {
            state.selectedDrivers = action.payload;
            state.selectedVehicleGroups = [];
            state.selectedVehicles = [];
        },
        setSelectedDriverGroups: (state: TreeState, action: PayloadAction<string[]>): void => {
            state.selectedDriverGroups = action.payload;
            state.selectedVehicleGroups = [];
            state.selectedVehicles = [];
        },
        setCurrentCategory: (state: TreeState, action: PayloadAction<TreeCategory>): void => {
            state.currentCategoryId = action.payload;
        },
    },
});

export const treeActions = actions;
export const treeReducer = reducer;

const getBase = (state: State): TreeState | undefined => get(state, name, undefined);

export const getSelectedVehicles = (state: State): string[] => get(getBase(state), 'selectedVehicles', []);
export const getSelectedVehicleGroups = (state: State): string[] => get(getBase(state), 'selectedVehicleGroups', []);
export const getSelectedDrivers = (state: State): string[] => get(getBase(state), 'selectedDrivers', []);
export const getSelectedDriverGroups = (state: State): string[] => get(getBase(state), 'selectedDriverGroups', []);
export const getCurrentCategory = (state: State): TreeCategory =>
    get(getBase(state), 'currentCategoryId', TreeCategory.VEHICLES);
