import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import IframeResizer from 'iframe-resizer-react';

import { get } from 'lodash/fp';

import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';

import { ReleaseNotesContainer } from '../serviceInformation/ReleaseNotes.container';
import { getIdToken, IdToken } from '../../configuration';
import { NavLink } from 'react-router-dom';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { State } from '../../reducers';

const serviceInfoItem = <ReleaseNotesContainer />;
const getHomeRoute = get('config.homeRoute');

interface ApplicationHeaderProperties {
    idToken: IdToken;
    homeRoute: string;
}

const AppHeader = (props: ApplicationHeaderProperties): JSX.Element => {
    const appTitle = <FormattedMessage id={'intl-msg:timed.common.module.name'} />;
    const notifications = <RioNotifications />;
    const environment = import.meta.env.PROD ? 'production' : 'local';
    const userMenu = <DefaultUserMenu environment={environment} />;
    const navItems = [
        {
            key: 'realtime',
            route: (
                <NavLink to={'/realtime'}>
                    <FormattedMessage id={'intl-msg:timed.common.subModuleName.realtime'} />
                </NavLink>
            ),
        },
    ];
    const appNavigator = <IframeResizer className="iFrameResizer" src="https://menu.rio.cloud/" checkOrigin={false} />;

    return (
        <ApplicationLayout.Header>
            <ApplicationHeader
                label={appTitle}
                // eslint-disable-next-line
                homeRoute={<a href={props.homeRoute} />}
                appNavigator={appNavigator}
                navItems={navItems}
                actionBarItems={[serviceInfoItem, notifications, userMenu]}
            />
        </ApplicationLayout.Header>
    );
};

const mapStateToProps = (state: State): ApplicationHeaderProperties => ({
    idToken: getIdToken(state),
    homeRoute: getHomeRoute(state),
});

export const ApplicationHeaderContainer = connect(mapStateToProps)(AppHeader);
