import { LatestPosition } from '../types';
import { ApiAssetLiveState } from '../iots/assetLiveStateCodec';

export const mapToLatestPosition = (assetLiveState: ApiAssetLiveState): LatestPosition | undefined => {
    const latitude = assetLiveState.position?.value.latitude;
    const longitude = assetLiveState.position?.value.longitude;
    const driverId = assetLiveState.driver_id?.value;
    if (latitude !== undefined && longitude !== undefined) {
        return {
            address: assetLiveState.address?.value ?? undefined,
            bearing: assetLiveState.heading?.value ?? undefined,
            driverId,
            eventTimestamp: assetLiveState.position?.occurred_at ?? undefined,
            assetId: assetLiveState.asset_id,
            latitude,
            longitude,
        };
    }
    return undefined;
};
