import { decodeLocalStorage } from './localStorageCodec';
import { localStorageMapper, PersistedWidgetState } from './localStorageMapper';

const localStorage = window.localStorage;

const STORAGE_PREFIX = 'drivingtime';

const saveDomainObject = (key: string, value: PersistedWidgetState): void => {
    try {
        localStorage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(value));
    } catch (_) {
        /* Intentionally left blank */
    }
};

const loadPersistedWidgetState = (key: string): PersistedWidgetState | undefined => {
    const item = localStorage.getItem(`${STORAGE_PREFIX}.${key}`);
    try {
        if (item) {
            const decoded = decodeLocalStorage(JSON.parse(item));
            return localStorageMapper(decoded);
        }
    } catch (_) {
        /* Intentionally left blank */
    }
    return undefined;
};

export const localStorageOfWidget = { savePersistedWidgetState: saveDomainObject, loadPersistedWidgetState };
