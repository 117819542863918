import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { DurationAndItems } from './types';
import { Activities, ActivityConfig } from '../../enums/Activities';
import { TimerValueComponent } from '../elements/TimerValueComponent';

interface WeeklyRestMinimumCellProps {
    value?: DurationAndItems;
}

export const WeeklyRestMinimumCell = (props: WeeklyRestMinimumCellProps): JSX.Element => {
    const { value } = props;

    if (!value || value.duration === undefined) {
        return <EmptyDataIconWithTooltip />;
    }

    return (
        <div className={'display-flex'}>
            <TimerValueComponent
                minutes={value.duration}
                color={ActivityConfig[Activities.RESTING].color}
                size={'16'}
            />
        </div>
    );
};
