import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { ApiResponseDrivingTimes, apiResponseDrivingTimeSampleListCodec } from './drivingtimesCodec';
import { ApiResponseActivities, apiResponseActivitiesListCodec } from './activitiesCodec';
import { ApiDriversResponse, apiResponseDriverCodec } from './driverCodec';
import { ApiAssetLiveState, apiAssetLiveStateCodec } from './assetLiveStateCodec';
import { ApiResponseAssets, apiResponseAssetListCodec } from './assetsCodec';
import { ApiResponseTags, apiTagCodec } from './tagCodec';
import { productActivationsApiCodec, ProductActivationsApiResponse } from './productActivationsCodec';
import { ApiResponseWorkingTimes, apiResponseWorkingTimeSampleListCodec } from './workingTimesCodec';

const decode = <T>(parsedObject: unknown, decoder: t.Decoder<unknown, T>): Promise<T> => {
    const decodedObject = decoder.decode(parsedObject);
    if (isRight(decodedObject)) {
        return Promise.resolve(decodedObject.right);
    }
    return Promise.reject(new Error(`${PathReporter.report(decodedObject)}`));
};

export const decodeApiResponseDrivingTime = (parsedObject: unknown): Promise<ApiResponseDrivingTimes> =>
    decode(parsedObject, apiResponseDrivingTimeSampleListCodec);

export const decodeApiResponseWorkingTime = (parsedObject: unknown): Promise<ApiResponseWorkingTimes> =>
    decode(parsedObject, apiResponseWorkingTimeSampleListCodec);

export const decodeApiResponseActivities = (parsedObject: unknown): Promise<ApiResponseActivities> =>
    decode(parsedObject, apiResponseActivitiesListCodec);

export const decodeApiResponseDrivers = (parsedObject: unknown): Promise<ApiDriversResponse> =>
    decode(parsedObject, apiResponseDriverCodec);

export const decodeApiResponseAssetLiveState = (parsedObject: unknown): Promise<ApiAssetLiveState> =>
    decode(parsedObject, apiAssetLiveStateCodec);

export const decodeApiResponseAssets = (parsedObject: unknown): Promise<ApiResponseAssets> =>
    decode(parsedObject, apiResponseAssetListCodec);

export const decodeProductActivationsApiResponse = (parsedObject: unknown): Promise<ProductActivationsApiResponse> =>
    decode(parsedObject, productActivationsApiCodec);

export const decodeApiResponseTag = (parsedObject: unknown): Promise<ApiResponseTags> =>
    decode(parsedObject, apiTagCodec);
