import RioglyphIcon from '../../wrapper/RioglyphIcon';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';

interface ClockCounterComponentProps {
    counter?: number;
    maxCounter?: number;
    tooltipId?: string;
    activeColor: string;
}

// visible for testing
export const getIsClockActiveArray = (counter?: number, maxCounter?: number): boolean[] | undefined => {
    if (!maxCounter) {
        return undefined;
    }
    if (typeof counter !== 'number') {
        return undefined;
    }
    const isClockActiveList: boolean[] = [];
    for (let i = 0; i < maxCounter; i++) {
        isClockActiveList.push(i >= maxCounter - counter);
    }
    return isClockActiveList;
};

export const ClockCounterComponent = (props: ClockCounterComponentProps): JSX.Element | null => {
    const { tooltipId, counter, maxCounter, activeColor } = props;

    const isClockActiveArray = getIsClockActiveArray(counter, maxCounter);

    if (!isClockActiveArray) {
        return null;
    }

    return (
        <TooltipWrapper tooltipId={tooltipId} tooltipSuffix={counter ? `: ${counter}` : ''} width={250}>
            <div>
                {isClockActiveArray.map((isClockActive, index) => (
                    <RioglyphIcon
                        type={isClockActive ? 'time' : 'remove-sign'}
                        key={index}
                        color={isClockActive ? activeColor : 'light'}
                        size={'16'}
                    />
                ))}
            </div>
        </TooltipWrapper>
    );
};
