import { ApiDrivingTimesItem, ApiEuInfringement, ApiWorkingTimesItem } from '../../api/types';
import { AssetItem, Driver } from '../../data/redux/types';
import { getVehicleNameOfAssetId } from '../../data/service/vehicleService';
import { getDriverNameOfDriverId } from '../../data/service/driverService';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import { DurationAndItems } from '../../tables/cells/types';
import {
    getAsNumberOfUndefinedFromApiResponse,
    getStatusDurationFromApiResponse,
    getTimestampFromApiResponse,
} from './mapperHelper';
import moment from 'moment';
import { TableColumn } from '../../enums/TableColumn';
import {
    extractMostSevereInfringementLevel,
    filterInfringementsOfRelevantTypes,
    mapToInfringementLevel,
} from './euInfringementService';

type OptDuration = DurationAndItems | undefined;

const extractDataFromEntryWithType = (
    euInfringements: ApiEuInfringement[] | undefined,
    type: string,
    current: boolean
): DurationAndItems | undefined => {
    if (!euInfringements) {
        return undefined;
    }

    const relevantInfringement = filterInfringementsOfRelevantTypes(euInfringements, [type])[0];
    if (!relevantInfringement) {
        return undefined;
    }

    return {
        duration: getAsNumberOfUndefinedFromApiResponse(
            current ? relevantInfringement.actual : relevantInfringement.deviation
        ),
        level: mapToInfringementLevel(relevantInfringement.level),
    };
};

const calculateDrivingTimesToday = (normal: OptDuration, extended: OptDuration): OptDuration => {
    if (!normal && !extended) {
        return undefined;
    }
    return !extended ? normal : extended;
};

const getDrivingTimesToday = (
    euInfringements: ApiEuInfringement[] | undefined,
    current: boolean
): DurationAndItems | undefined => {
    const normal = extractDataFromEntryWithType(euInfringements, 'exceeded_daily_driving_time', current);
    const extended = extractDataFromEntryWithType(euInfringements, 'exceeded_daily_driving_time_extended', current);

    const dailyDrivingTimes = calculateDrivingTimesToday(normal, extended);
    const dailyExtensions = extractDataFromEntryWithType(
        euInfringements,
        'exceeded_number_of_daily_driving_time_extended',
        false
    );

    if (!dailyExtensions || !dailyDrivingTimes) {
        return dailyDrivingTimes;
    }

    return {
        ...dailyDrivingTimes,
        numberOfItems: dailyExtensions.duration,
    };
};

const getDrivingTimesWeekly = (
    euInfringements: ApiEuInfringement[] | undefined,
    current: boolean
): DurationAndItems | undefined =>
    extractDataFromEntryWithType(euInfringements, 'exceeded_weekly_driving_time', current);

const getMinimumDailyRest = (euInfringements: ApiEuInfringement[] | undefined): DurationAndItems | undefined => {
    const weeklyDrivingTimes = extractDataFromEntryWithType(euInfringements, 'insufficient_daily_rest_regular', true);
    const numberOfReducedDailyRestTime = extractDataFromEntryWithType(
        euInfringements,
        'exceeded_number_of_reduced_daily_rest_time',
        false
    );

    if (!numberOfReducedDailyRestTime) {
        return weeklyDrivingTimes;
    }

    return {
        ...weeklyDrivingTimes,
        numberOfItems: numberOfReducedDailyRestTime.duration,
    };
};

const getWeeklyRestMinimum = (item: ApiDrivingTimesItem) => ({
    duration: item.embedded.driving_and_resting_details?.minimum_weekly_rest,
});

const extractDurationAndInfringementLevelFromWorkingTime = (
    duration: string | undefined,
    euInfringements: ApiEuInfringement[],
    relevantInfringementTypes: string[]
): DurationAndItems | undefined => {
    return duration
        ? {
              duration: moment.duration(duration).asMinutes(),
              level: extractMostSevereInfringementLevel(euInfringements, relevantInfringementTypes),
          }
        : undefined;
};

const convertToDisplayItem = (
    drivingTimesItem: ApiDrivingTimesItem,
    workingTimesItem: ApiWorkingTimesItem | undefined,
    vehicles: AssetItem[],
    drivers: Driver[]
): DrivingTimesDisplayItem => {
    const euInfringements = drivingTimesItem.embedded.eu_infringements;
    const driverName = getDriverNameOfDriverId(drivingTimesItem.driver_id, drivers);

    const displayItemWithoutWorkingTimes = {
        id: drivingTimesItem.id,
        isActive: false,
        assetId: drivingTimesItem.asset_id,
        driverId: drivingTimesItem.driver_id,
        [TableColumn.VEHICLE]: getVehicleNameOfAssetId(drivingTimesItem.asset_id, vehicles),
        [TableColumn.DRIVER]: driverName ? driverName : drivingTimesItem.identification,
        [TableColumn.STATUS_DURATION]: getStatusDurationFromApiResponse(drivingTimesItem),
        [TableColumn.CONTINUOUS_DRIVING]: extractDataFromEntryWithType(
            euInfringements,
            'exceeded_current_driving_time',
            true
        ),
        [TableColumn.REMAINING_CURRENT_DRIVING_TIME]: extractDataFromEntryWithType(
            euInfringements,
            'exceeded_current_driving_time',
            false
        ),
        [TableColumn.RESTING_TODAY_CURRENT]: extractDataFromEntryWithType(
            euInfringements,
            'status_cumulated_rest_time',
            true
        ),
        [TableColumn.REMAINING_TIME_OF_CURRENT_BREAK]: extractDataFromEntryWithType(
            euInfringements,
            'status_cumulated_rest_time',
            false
        ),
        [TableColumn.DRIVING_TWO_WEEKLY_CURRENT]: extractDataFromEntryWithType(
            euInfringements,
            'exceeded_weekly_driving_time_fortnightly',
            true
        ),
        [TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT]: extractDataFromEntryWithType(
            euInfringements,
            'exceeded_weekly_driving_time_fortnightly',
            false
        ),
        [TableColumn.DRIVING_TODAY_CURRENT]: getDrivingTimesToday(euInfringements, true),
        [TableColumn.REMAINING_DRIVING_TODAY_CURRENT]: getDrivingTimesToday(euInfringements, false),
        [TableColumn.DRIVING_WEEKLY_CURRENT]: getDrivingTimesWeekly(euInfringements, true),
        [TableColumn.REMAINING_WEEK_DRIVING_REMAINING]: getDrivingTimesWeekly(euInfringements, false),
        [TableColumn.MINIMUM_DAILY_REST_MINIMUM]: getMinimumDailyRest(euInfringements),
        [TableColumn.WEEKLY_REST_MINIMUM]: getWeeklyRestMinimum(drivingTimesItem),
        [TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM]: getMinimumDailyRest(euInfringements),
        [TableColumn.DRIVING_TIMES_TIMESTAMP]: getTimestampFromApiResponse(drivingTimesItem),
    };

    if (workingTimesItem) {
        return {
            ...displayItemWithoutWorkingTimes,
            [TableColumn.CURRENT_DAILY_WORKING_TIME]: extractDurationAndInfringementLevelFromWorkingTime(
                workingTimesItem.current_daily_working_time,
                workingTimesItem.current_working_time_infringements,
                ['exceeded_daily_working_time']
            ),
            [TableColumn.REMAINING_DAILY_WORKING_TIME]: extractDurationAndInfringementLevelFromWorkingTime(
                workingTimesItem.remaining_daily_working_time,
                workingTimesItem.current_working_time_infringements,
                ['exceeded_daily_working_time']
            ),
            [TableColumn.CURRENT_WEEKLY_WORKING_TIME]: extractDurationAndInfringementLevelFromWorkingTime(
                workingTimesItem.current_weekly_working_time,
                workingTimesItem.current_working_time_infringements,
                ['exceeded_weekly_working_time', 'exceeded_weekly_working_time_on_average']
            ),
            [TableColumn.REMAINING_WEEKLY_WORKING_TIME]: extractDurationAndInfringementLevelFromWorkingTime(
                workingTimesItem.remaining_weekly_working_time,
                workingTimesItem.current_working_time_infringements,
                ['exceeded_weekly_working_time', 'exceeded_weekly_working_time_on_average']
            ),
            [TableColumn.WORKING_TIMES_TIMESTAMP]: moment(workingTimesItem.latest_data_at),
            dddDataUntil: workingTimesItem.ddd_data_until ? moment(workingTimesItem.ddd_data_until) : undefined,
        };
    }
    return displayItemWithoutWorkingTimes;
};

const findMatchingWorkingTimes = (
    driverId: string,
    workingTimeItems: ApiWorkingTimesItem[]
): ApiWorkingTimesItem | undefined => {
    return workingTimeItems.find((item) => item.driver_id === driverId);
};
export const convertToDrivingTimesDisplayItem = (
    drivingTimeItems: ApiDrivingTimesItem[],
    workingTimeItems: ApiWorkingTimesItem[],
    vehicles: AssetItem[],
    drivers: Driver[]
): DrivingTimesDisplayItem[] => {
    return drivingTimeItems.map((drivingTimeItem) =>
        convertToDisplayItem(
            drivingTimeItem,
            findMatchingWorkingTimes(drivingTimeItem.driver_id, workingTimeItems),
            vehicles,
            drivers
        )
    );
};
