import { useState } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import Tree, { TreeGroup, TreeItem } from '@rio-cloud/rio-uikit/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import { FormattedMessage, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { TreeCategory as TreeGategoryType, TreeCategory as Category } from './redux/types';
import { Driver, Tag } from '../data/redux/types';
import { UNASSIGNED_VEHICLES } from '../data/groupingService';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    TrackingCategory,
} from '../../configuration/setup/googleTagManager';
import { getDriverGroups, getDrivers, getTags, getVehicleGroups, getVehicles } from '../data/redux/data.redux';
import {
    getCurrentCategory,
    getSelectedDriverGroups,
    getSelectedDrivers,
    getSelectedVehicleGroups,
    getSelectedVehicles,
    treeActions,
} from './redux/tree.redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TreeSelection } from './types';
import { UNASSIGNED_DRIVERS } from '../data/service/driverService';
import { realtimeActions } from '../realtime/redux/realtime.redux';
import { State } from '../../reducers';

interface AssetTreeStateProps {
    tags: Tag[];
    vehicleGroups: TreeGroup[];
    vehicles: TreeItem[];
    drivers: Driver[];
    driverGroups: TreeGroup[];
    currentCategoryId: TreeGategoryType;
    selectedDrivers: string[];
    selectedVehicles: string[];
    selectedVehicleGroups: string[];
    selectedDriverGroups: string[];
}

interface AssetTreeDispatchProps {
    setCurrentCategory: (category: TreeGategoryType) => void;
    onVehicleSelectionChange: (selection: TreeSelection) => void;
    onDriverSelectionChange: (selection: TreeSelection) => void;
}

type AssetTreeProps = AssetTreeStateProps & AssetTreeDispatchProps & WrappedComponentProps;

const mapStateToProps = (state: State): AssetTreeStateProps => ({
    tags: getTags(state),
    vehicleGroups: getVehicleGroups(state),
    vehicles: getVehicles(state),
    driverGroups: getDriverGroups(state),
    drivers: getDrivers(state),
    currentCategoryId: getCurrentCategory(state),
    selectedDrivers: getSelectedDrivers(state),
    selectedVehicles: getSelectedVehicles(state),
    selectedVehicleGroups: getSelectedVehicleGroups(state),
    selectedDriverGroups: getSelectedDriverGroups(state),
});

const metaGroupIds = [UNASSIGNED_VEHICLES, UNASSIGNED_DRIVERS];

const translateMetaGroups = (groups: TreeGroup[], intl: IntlShape): TreeGroup[] =>
    groups.map((group) => ({
        ...group,
        name:
            group.id && metaGroupIds.includes(group.id)
                ? intl.formatMessage({ id: 'intl-msg:asset-tree.groups.ungrouped' })
                : group.name,
    }));

const convertDriverToTreeItem = (driver: Driver): TreeItem => ({
    id: driver.id,
    name: driver.displayName,
    groupIds: driver.tagIds,
    type: 'driver',
});

const AssetTreeComponent = (props: AssetTreeProps): JSX.Element => {
    const {
        vehicleGroups,
        vehicles,
        driverGroups,
        drivers,
        currentCategoryId,
        setCurrentCategory,
        onDriverSelectionChange,
        onVehicleSelectionChange,
        selectedDrivers,
        selectedVehicles,
        selectedVehicleGroups,
        selectedDriverGroups,
        intl,
    } = props;

    const [isTreeOpen, setTreeIsOpen] = useState(true);
    const onToggleTree = (): void => setTreeIsOpen(!isTreeOpen);
    const [expandedVehicleGroups, setExpandedVehicleGroups] = useState([] as string[]);
    const [expandedDriverGroups, setExpandedDriverGroups] = useState([] as string[]);
    const setCurrentCategoryTracked = (category: string): void => {
        setCurrentCategory(category as Category);
        dataLayerPush(
            createGoogleAnalyticsEvent(TrackingCategory.ANY_TABLE, TrackingAction.CHANGE_TREE_OPTION, category)
        );
    };

    return (
        <>
            <div data-testid={'AssetTreeComponent'} />
            <AssetTree
                resizable={true}
                minWidth={300}
                maxWidth={450}
                isOpen={isTreeOpen}
                onToggleTree={onToggleTree}
                currentCategoryId={currentCategoryId as string}
                onCategoryChange={setCurrentCategoryTracked}
            >
                {
                    <TreeCategory
                        key={Category.VEHICLES}
                        id={Category.VEHICLES}
                        label={<FormattedMessage id={'intl-msg:glossary.vehicles'} />}
                        icon={'rioglyph-truck'}
                        hasSelection={!isEmpty(selectedVehicles) || !isEmpty(selectedVehicleGroups)}
                    >
                        <Tree
                            groups={translateMetaGroups(vehicleGroups, intl)}
                            items={vehicles}
                            expandedGroups={expandedVehicleGroups}
                            onExpandGroupsChange={setExpandedVehicleGroups}
                            selectedGroups={selectedVehicleGroups}
                            selectedItems={selectedVehicles}
                            onSelectionChange={onVehicleSelectionChange}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.vehicles',
                            })}
                            showEmptyGroups={false}
                        />
                    </TreeCategory>
                }
                {
                    <TreeCategory
                        key={Category.DRIVERS}
                        id={Category.DRIVERS}
                        label={<FormattedMessage id={'intl-msg:glossary.drivers'} />}
                        icon={'rioglyph-driver'}
                        hasSelection={!isEmpty(selectedDrivers)}
                    >
                        <Tree
                            groups={translateMetaGroups(driverGroups, intl)}
                            items={drivers.map((it) => convertDriverToTreeItem(it))}
                            expandedGroups={expandedDriverGroups}
                            onExpandGroupsChange={setExpandedDriverGroups}
                            selectedGroups={selectedDriverGroups}
                            selectedItems={selectedDrivers}
                            onSelectionChange={onDriverSelectionChange}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.driver',
                            })}
                            showEmptyGroups={false}
                        />
                    </TreeCategory>
                }
            </AssetTree>
        </>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, Dispatch, any>): AssetTreeDispatchProps => ({
    setCurrentCategory: (category: Category): void => {
        dispatch(treeActions.setCurrentCategory(category));
    },
    onVehicleSelectionChange: (selection: TreeSelection): void => {
        dispatch(treeActions.setSelectedVehicles(selection.items));
        dispatch(treeActions.setSelectedVehicleGroups(selection.groups));
        dispatch(realtimeActions.selectDrivingTimesTableRow());
    },
    onDriverSelectionChange: (selection: TreeSelection): void => {
        dispatch(treeActions.setSelectedDrivers(selection.items));
        dispatch(treeActions.setSelectedDriverGroups(selection.groups));
        dispatch(realtimeActions.selectDrivingTimesTableRow());
    },
});

export const AssetTreeContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssetTreeComponent));
