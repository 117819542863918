import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { getLanguageData, getLocale, LanguageDataInterface } from '../../configuration';
import { createVirtPathEvent } from '../../configuration/setup/googleTagManager';
import { MessageHandlerContainer, sendMessage } from './MessageHandler';
import { WidgetViewContainer } from './views/WidgetView.container';
import { WidgetDataFetcherContainer } from './WidgetDataFetcher.container';
import { WidgetVisibilityTrackerContainer } from '../../configuration/setup/VisbilityTracker.container';
import { useEffectOnlyOnMount } from '../../common/hookUtils/customHooks';
import { NonFunctionProperties } from '../../utils/util';
import { State } from '../../reducers';
import { WidgetLocalStorageUpdaterContainer } from './browsercommunication/WidgetLocalStorageUpdater.container';

interface WidgetProperties {
    languageData: LanguageDataInterface;
    userLocale: string;
}

const WidgetLayout = (props: WidgetProperties): JSX.Element => {
    const { userLocale, languageData } = props;

    useEffectOnlyOnMount(() => {
        sendMessage({ type: 'EVENT_WIDGET_LOADED' });
    });

    useEffectOnlyOnMount(() => {
        document.documentElement.classList.add('is-iframe-widget');
    });

    return (
        <div>
            <WidgetVisibilityTrackerContainer googleAnalyticsEvent={createVirtPathEvent('widget/')} />
            <IntlProvider
                key={`intl-provider-${userLocale}`}
                locale={userLocale}
                messages={languageData}
                defaultLocale={'en-GB'}
            >
                <WidgetDataFetcherContainer />
                <WidgetLocalStorageUpdaterContainer />
                <MessageHandlerContainer />
                <WidgetViewContainer />
            </IntlProvider>
        </div>
    );
};

const mapStateToProps = (state: State): NonFunctionProperties<WidgetProperties> => ({
    languageData: getLanguageData(state),
    userLocale: getLocale(state),
});

export const WidgetContainer = connect(mapStateToProps)(WidgetLayout);
