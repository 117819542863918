/* eslint-disable @typescript-eslint/ban-ts-comment */

export const dataLayerPush = (event: GoogleTagManagerEvent): void => {
    // @ts-ignore
    if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push(event);
    }
};

export const WIDGET_CAMPAIGN = 'drivingtime-widget-timed-upselling';
export const TABLE_CAMPAIGN = 'drivingtime-spa-table-timed-upselling';

export enum TrackingCategory {
    WIDGET_VIEW = 'drivingtime, widget',
    STATE_CHANGES_VIEW = 'drivingtime, stateChanges',
    DRIVING_TIMES_VIEW = 'drivingtime, drivingTimes',
    ANY_TABLE = 'drivingtime, all table views',
}

export enum TrackingAction {
    SWITCH_BETWEEN_DRIVING_TIME_AND_STATE_CHANGES = 'switchTable',
    CHANGE_TABLE_VIEW_MODE = 'changeTableViewMode',
    CHANGE_TIME_DISPLAY_MODE = 'changeTimeDisplayMode',
    SELECT_TABLE_ITEM = 'selectTableItem',
    SHOW_TABLE_SETTINGS_DIALOG = 'showTableSettingsDialog',
    CLICK_ON_UPSELLING_LINK_TO_MARKETPLACE = 'clickOnUpsellingLink',
    APPLY_TABLE_SETTINGS_CHANGES = 'applyTableSettingsChanges',
    CHANGE_SELECTED_ACTIVITIES = 'changeSelectedActivities',
    CHANGE_SELECTED_RECENCY = 'changeSelectedRecency',
    CHANGE_SELECTED_TIME_RANGE = 'changeSelectedTimeRange',
    CHANGE_TREE_OPTION = 'changeTreeOption',
    CHANGE_TABLE_SORTING = 'changeTableSorting',
    CHANGE_STATE_CHANGES_TIME_WINDOW = 'changeStateChangesTimeWindow',
    LOAD_MORE_DATA = 'loadMoreData',
    SHOW_UPSELLING_LINK_TO_MARKETPLACE = 'showUpsellingLink',
    SHOW_PENDING_ACTIVATION_FOR_ASSET = 'showPendingActivationForAsset',
    SHOW_NO_DATA_FOUND_FOR_ASSET = 'showNoDataFoundForAsset',
    SHOW_OUTDATED_DATA_FOR_ASSET = 'showOutdatedDataForAsset',
    SHOW_UP_TO_DATE_DATA_FOR_ASSET = 'showUpToDateDataForAsset',
    CLICK_ON_LINK_TO_DRIVINGTIMES_SPA = 'clickOnLinkToDrivingTimesSPA',
    CLICK_ON_SHOW_OUTDATED_DATA_BUTTON = 'clickOnShowOutdatedDataButton',
    CLICK_ON_BOTTOMSHEET = 'clickOnBottomSheet',
    CLICK_ON_BOTTOMSHEET_DIALOG = 'clickOnBottomSheetDialog',
}

interface TrackingAttributes {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
}

export const trackingAttributes = (
    category: TrackingCategory,
    action: TrackingAction,
    label?: string
): TrackingAttributes => {
    const attributes: TrackingAttributes = {
        'data-track-ga-event-trigger': 'click',
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    return attributes;
};

interface VirtPathEvent {
    event: string;
    virtPath: string;
}

interface GoogleAnalyticsEvent {
    event: string;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
}

export type GoogleTagManagerEvent = VirtPathEvent | GoogleAnalyticsEvent;

export const createVirtPathEvent = (path: string): VirtPathEvent => ({
    event: 'virtPath',
    virtPath: `/virtPath/${path}`,
});

export const createGoogleAnalyticsEvent = (
    category: TrackingCategory,
    action: TrackingAction,
    label?: string
): GoogleAnalyticsEvent => ({
    event: 'ga_event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
});
