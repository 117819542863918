import { connect } from 'react-redux';

import { ConnectedTableHead } from '../header/TableHead';
import { TableColumn } from '../../enums/TableColumn';
import { getStateChangeColumns } from './stateChangesColumnService';

const mapStateToProps = (): { columns: TableColumn[] } => ({
    columns: getStateChangeColumns(),
});

export const StateChangesTableHeadContainer = connect(mapStateToProps)(ConnectedTableHead);
