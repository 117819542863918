import {
    getSelectedDriverGroups,
    getSelectedDrivers,
    getSelectedVehicleGroups,
    getSelectedVehicles,
} from './redux/tree.redux';
import { getDrivers, getVehicles } from '../data/redux/data.redux';
import { createSelector } from '@reduxjs/toolkit';
import { Driver } from '../data/redux/types';
import { TreeItem } from '@rio-cloud/rio-uikit/Tree';

export const getAllSelectedItems = (items: TreeItem[], selectedItems: string[], selectedGroups: string[]): string[] => {
    const itemsForSelectedGroups = items
        .filter((it) => (it.groupIds ? it.groupIds : []).some((groupId) => selectedGroups.includes(groupId)))
        .map((item) => item.id);

    const allSelectedItems: Set<string> = new Set();
    selectedItems.forEach((item) => allSelectedItems.add(item));
    itemsForSelectedGroups.forEach((item) => allSelectedItems.add(item));

    return Array.from(allSelectedItems);
};

export const getAllSelectedDrivers = (
    drivers: Driver[],
    selectedItems: string[],
    selectedGroups: string[]
): string[] => {
    return Array.from(
        new Set(
            drivers
                .filter((driver) => selectedGroups.some((tagId) => driver.tagIds.includes(tagId)))
                .map((driver) => driver.id)
                .concat(selectedItems)
        )
    );
};

export const getIdsOfSelectedAssets = createSelector(
    getVehicles,
    getSelectedVehicles,
    getSelectedVehicleGroups,
    getAllSelectedItems
);

export const getIdsOfSelectedDrivers = createSelector(
    getDrivers,
    getSelectedDrivers,
    getSelectedDriverGroups,
    getAllSelectedDrivers
);
