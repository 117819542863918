import { Moment } from 'moment';
import FormattedTimeStamp from '../../formatting/FormattedTimeStamp';
import { TooltipWrapper } from '../../onboarding/TooltipWrapper';
import { getTimeStampType, TimeStampCategoryConfig } from '../../enums/TimeStampCategory';
import { EmptyDataIconWithTooltip } from '../../tables/elements/EmptyDataIcon';

interface InlineTimeStampProps {
    value?: Moment;
    receivedTimestamp?: Moment;
}

export const InlineTimeStamp = (props: InlineTimeStampProps): JSX.Element => {
    const { value, receivedTimestamp } = props;

    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }

    const timeStampType = getTimeStampType(value, receivedTimestamp);
    const configElement = TimeStampCategoryConfig[timeStampType];

    return (
        <div className={'cards-justify-content'}>
            <TooltipWrapper tooltipId={configElement.translationId}>
                <span>
                    <span className={`rioglyph rioglyph-sphere text-${configElement.color} margin-right-5`} />
                    <FormattedTimeStamp date={value} displayYear={true} />
                </span>
            </TooltipWrapper>
        </div>
    );
};
