import { FormattedMessage } from 'react-intl';

import BadgeTimerChart from './BadgeTimerChart';
import { Activities, ActivityConfig, INACTIVE_COLOR } from '../enums/Activities';
import { NextActivityPanelProps } from './types';

const NextDrivingPanel = (props: NextActivityPanelProps) => {
    const { nextActivityTimeLeft, nextActivityDuration, isDataUpToDate } = props;

    return (
        <div className={'panel-body padding-bottom-0'}>
            <div className={'display-flex justify-content-between margin-bottom-5'}>
                <div className={'margin-right-10 text-left'}>
                    <FormattedMessage id={'intl-msg:timed.sidebar.timeUntilNextDrivingTime'} />
                </div>
                <div className={'margin-left-10 text-right'}>
                    <FormattedMessage id={'intl-msg:timed.sidebar.durationOfNextDrivingTime'} />
                </div>
            </div>
            <BadgeTimerChart
                minutesLeft={nextActivityTimeLeft}
                minutesRight={nextActivityDuration}
                icon={ActivityConfig[Activities.DRIVING].type}
                color={isDataUpToDate ? ActivityConfig[Activities.DRIVING].color : INACTIVE_COLOR}
            />
        </div>
    );
};

export default NextDrivingPanel;
