import { ApiWorkingTimesItem } from '../types';
import { ApiResponseWorkingTimesSample } from '../iots/workingTimesCodec';

export const mapToWorkingTimes = (data: ApiResponseWorkingTimesSample[]): ApiWorkingTimesItem[] => {
    return data.map((item) => {
        return {
            driver_id: item.driver_id,
            latest_data_at: item.latest_data_at,
            current_daily_working_time: item.current_daily_working_time,
            remaining_daily_working_time: item.remaining_daily_working_time,
            current_weekly_working_time: item.current_weekly_working_time,
            remaining_weekly_working_time: item.remaining_weekly_working_time,
            ddd_data_until: item.ddd_data_until,
            current_working_time_infringements: item.current_working_time_infringements,
        };
    });
};
