import { DetailsSidebarDisplayItem, ShiftTimes } from '../../detailssidebar/types';
import { ApiDrivingTimesItem } from '../../api/types';
import { AssetItem, Driver } from '../../data/redux/types';
import { get } from 'lodash';
import moment from 'moment';
import { getVehicleNameOfAssetId } from '../../data/service/vehicleService';
import { getDriverNameOfDriverId } from '../../data/service/driverService';
import {
    getAsNumberOfUndefinedFromApiResponse,
    getStatusDurationFromApiResponse,
    getTimestampFromApiResponse,
} from './mapperHelper';

const convertToDisplayItem = (
    item: ApiDrivingTimesItem,
    vehicles: AssetItem[],
    drivers: Driver[]
): DetailsSidebarDisplayItem => {
    const euInfringements = item.embedded.eu_infringements;
    const driverName = getDriverNameOfDriverId(item.driver_id, drivers);
    return {
        id: item.id,
        vehicleName: getVehicleNameOfAssetId(item.asset_id, vehicles),
        assetId: item.asset_id,
        driverName: driverName ? driverName : item.identification,
        driverId: item.driver_id,
        timeStamp: getTimestampFromApiResponse(item),
        multiManning: get(
            item,
            'embedded.driving_and_resting_details.additional_information.multi_manning_detection_status',
            undefined
        ),
        // euInfringements?.find(it => it.type.toLowerCase() === type.toLowerCase());
        remainingRestingExceptions: getAsNumberOfUndefinedFromApiResponse(
            euInfringements?.find((it) => it.type.toLowerCase() === 'exceeded_number_of_reduced_daily_rest_time')
                ?.deviation
        ),
        nextDrivingTimeLeft: get(
            item,
            'embedded.driving_and_resting_details.time_left_until_next_driving_period',
            undefined
        ),
        nextDrivingDuration: get(
            item,
            'embedded.driving_and_resting_details.duration_of_next_driving_period',
            undefined
        ),
        nextRestingTimeLeft: get(
            item,
            'embedded.driving_and_resting_details.remaining_time_until_next_break_or_rest',
            undefined
        ),
        nextRestingDuration: get(item, 'embedded.driving_and_resting_details.duration_of_next_break_rest', undefined),
        statusDuration: getStatusDurationFromApiResponse(item),
        minimumDailyRestMinimum: get(item, 'embedded.driving_and_resting_details.minimum_daily_rest', undefined),
        shiftTimes: getShiftTimes(item),
    };
};

const getShiftTimes = (item: ApiDrivingTimesItem): ShiftTimes | undefined => {
    if (!item.embedded.eu_shift_times) {
        return undefined;
    }

    return {
        shiftStart: moment(item.embedded.eu_shift_times.shift_start),
        shiftEnd: moment(item.embedded.eu_shift_times.shift_end),
        shiftTime: item.embedded.eu_shift_times.shift_time,
        shiftMax: item.embedded.eu_shift_times.shift_max,
        shiftExceeded: item.embedded.eu_shift_times.shift_exceeded,
        timeLeft: item.embedded.eu_shift_times.remaining_shift_time,
        dailyRestWarning: item.embedded.eu_shift_times.daily_rest_warning,
    };
};
export const convertToDetailsSidebarDisplayItem = (
    drivingTimeItems: ApiDrivingTimesItem[],
    vehicles: AssetItem[],
    drivers: Driver[]
): DetailsSidebarDisplayItem[] => {
    return drivingTimeItems.map((drivingTimeItem) => convertToDisplayItem(drivingTimeItem, vehicles, drivers));
};
