import { isEmpty } from 'lodash';
import { Asset, AssetItem, Tag } from './redux/types';
import { GroupedAssets } from './types';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';

export const UNASSIGNED_VEHICLES = 'unassignedVehicles';

const convertAssetToAssetItem = (asset: Asset, type: string, tags: Tag[]): AssetItem => {
    const tagIds = tags.map((tag) => tag.id);
    const idsOfGroupsIncludingAsset = asset.tagIds.filter((id) => tagIds.includes(id));
    return {
        id: asset.id,
        name: asset.name,
        type,
        groupIds: isEmpty(idsOfGroupsIncludingAsset) ? [UNASSIGNED_VEHICLES] : [...idsOfGroupsIncludingAsset],
    };
};

const convertTagToTreeGroup = (tag: Tag): TreeGroup => ({
    id: tag.id,
    name: tag.name,
});

const unassignedVehicleGroup: TreeGroup = {
    id: UNASSIGNED_VEHICLES,
    name: 'unassignedVehicles',
    position: 'last',
};

const addUnassignedAssetGroupIfNecessary = (treeGroups: TreeGroup[], assets: AssetItem[]): TreeGroup[] => {
    const unassignedAssetsExist = assets.some((asset) =>
        asset.groupIds ? asset.groupIds.includes(UNASSIGNED_VEHICLES) : false
    );

    if (unassignedAssetsExist) {
        treeGroups.push(unassignedVehicleGroup);
    }
    return treeGroups;
};

export const separateTrucksIntoGroups = (assets: Asset[], tags: Tag[]): GroupedAssets => {
    const trucks: AssetItem[] = [];
    const truckGroups: TreeGroup[] = tags.map(convertTagToTreeGroup);

    assets.forEach((asset) => {
        switch (asset.type) {
            case 'truck':
            case 'van':
            case 'bus':
                trucks.push(convertAssetToAssetItem(asset, asset.type, tags));
                break;
        }
    });

    return {
        trucks,
        truckGroups: addUnassignedAssetGroupIfNecessary(truckGroups, trucks),
    };
};
