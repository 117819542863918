import { PersistedSpaState, TimeDisplayMode } from '../types';
import qs from 'qs';
import { isEmpty } from 'lodash';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { OPTIONS } from './config';
import { TreeCategory } from '../../tree/redux/types';

const getSelectedStateChangesDisplay = (parameters: PersistedSpaState): true | undefined =>
    true === parameters.selectedStateChangesDisplay ? true : undefined;

const getValueOrUndefinedIfDefault = <T>(value: T | undefined, defaultValue: T): T | undefined =>
    defaultValue === value ? undefined : value;

const getUndefinedIfEmpty = <T>(data: T[] | undefined): T[] | undefined => (isEmpty(data) ? undefined : data);

export const makeRoute = (parameters: PersistedSpaState): string => {
    const params = {
        assetIds: getUndefinedIfEmpty(parameters.selectedAssetIds),
        vehicleGroupIds: getUndefinedIfEmpty(parameters.selectedVehicleGroupIds),
        driverIds: getUndefinedIfEmpty(parameters.selectedDriverIds),
        driverGroupIds: getUndefinedIfEmpty(parameters.selectedDriverGroupIds),
        activityIds: getUndefinedIfEmpty(parameters.selectedActivityIds),
        timestampIds: getUndefinedIfEmpty(parameters.selectedTimestampIds),
        selectedTimeDisplayMode: getValueOrUndefinedIfDefault(
            parameters.selectedTimeDisplayMode,
            TimeDisplayMode.CURRENT
        ),
        selectedStateChangesDisplay: getSelectedStateChangesDisplay(parameters),
        selectedDrivingTimesRowId: parameters.selectedDrivingTimesRowId,
        selectedStateChangesRowId: parameters.selectedStateChangesRowId,
        viewMode: getValueOrUndefinedIfDefault(parameters.viewMode, TableViewToggles.VIEW_TYPE_TABLE),
        treeCategory: getValueOrUndefinedIfDefault(parameters.treeCategory, TreeCategory.VEHICLES),
    };

    const queryParameters = qs.stringify(params, OPTIONS);
    return `/realtime${queryParameters ? `?${queryParameters}` : ''}`;
};
