import React from 'react';
import { DriverActivityOverview } from './elements/DriverActivityOverview';
import { DrivingTimesView } from './DrivingTimesView';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import {
    createGoogleAnalyticsEvent,
    TrackingAction,
    TrackingCategory,
} from '../../../configuration/setup/googleTagManager';
import { LiveClock } from '../../realtime/header/LiveClock';
import moment, { Moment } from 'moment';
import RioglyphIcon from '../../wrapper/RioglyphIcon';
import { WidgetVisibilityTrackerContainer } from '../../../configuration/setup/VisbilityTracker.container';
import { WorkingTimesViewContainer } from './WorkingTimesViewContainer';
import { TableColumn } from '../../enums/TableColumn';
import { connect } from 'react-redux';
import { FunctionProperties, NonFunctionProperties } from '../../../utils/util';
import { State } from '../../../reducers';
import { Dispatch } from 'redux';
import { getShowOutdatedData, widgetActions } from '../redux/widget.redux';
import { ShowOutdatedDataButton } from './ShowOutdatedDataButton';
import { getFeatures } from '../../data/redux/data.redux';
import { Feature } from '../../data/redux/types';

interface ActivityAndDrivingTimesViewComponentProps {
    displayItem: DrivingTimesDisplayItem;
}

interface ActivityAndDrivingTimesViewProps {
    isWorkingTimesFeatureSupported: boolean;
    showOutdatedData: boolean;
    setShowOutdatedData: (isVisible: boolean) => void;
}

interface ActivityAndDrivingTimesViewState {
    assetId: string;
}

interface TimestampAndDriverNameViewProps {
    displayItem: DrivingTimesDisplayItem;
}

const TimestampAndDriverNameComponent = ({ displayItem }: TimestampAndDriverNameViewProps): JSX.Element => (
    <>
        <div data-testid={'DriverWithActivity'}>
            {displayItem[TableColumn.DRIVER] ? (
                `${displayItem[TableColumn.DRIVER]}`
            ) : (
                <RioglyphIcon type={'question-sign'} size={'18'} color={'gray'} />
            )}
        </div>
        <div className={'flex-1-0 text-right'}>
            <LiveClock date={displayItem[TableColumn.DRIVING_TIMES_TIMESTAMP]} />
        </div>
    </>
);

const OutdatedView = ({
    displayItem,
    onShowOutdatedDataClick,
}: {
    displayItem: DrivingTimesDisplayItem;
    onShowOutdatedDataClick: () => void;
}): JSX.Element => {
    return (
        <div className="border rounded" data-testid={'outdated-driving-times-view'}>
            <WidgetVisibilityTrackerContainer
                googleAnalyticsEvent={createGoogleAnalyticsEvent(
                    TrackingCategory.WIDGET_VIEW,
                    TrackingAction.SHOW_OUTDATED_DATA_FOR_ASSET
                )}
            />
            <div className="padding-x-15 padding-top-5 padding-bottom-5 display-flex">
                <TimestampAndDriverNameComponent displayItem={displayItem} />
            </div>
            <div className="border border-top-only text-center padding-top-20 padding-bottom-20">
                <ShowOutdatedDataButton onClick={onShowOutdatedDataClick} />
            </div>
        </div>
    );
};

interface UpToDateViewProps {
    displayItem: DrivingTimesDisplayItem;
    isWorkingTimesFeatureSupported: boolean;
}

const UpToDateView = ({ displayItem, isWorkingTimesFeatureSupported }: UpToDateViewProps): JSX.Element => (
    <div data-testid={'uptodate-driving-times-view'}>
        <WidgetVisibilityTrackerContainer
            googleAnalyticsEvent={createGoogleAnalyticsEvent(
                TrackingCategory.WIDGET_VIEW,
                TrackingAction.SHOW_UP_TO_DATE_DATA_FOR_ASSET
            )}
        />
        <DriverActivityOverview displayItem={displayItem} />
        <DrivingTimesView displayItem={displayItem} />
        {isWorkingTimesFeatureSupported && <WorkingTimesViewContainer displayItem={displayItem} />}
    </div>
);

const isDataOutdated = (timestamp?: Moment): boolean => {
    return moment().diff(timestamp, 'minutes') > 5;
};

class ActivityAndDrivingTimesViewComponent extends React.Component<
    ActivityAndDrivingTimesViewProps & ActivityAndDrivingTimesViewComponentProps,
    ActivityAndDrivingTimesViewState
> {
    constructor(props: ActivityAndDrivingTimesViewProps & ActivityAndDrivingTimesViewComponentProps) {
        super(props);
        this.state = {
            assetId: props.displayItem.assetId,
        };
    }

    componentDidUpdate(prevProps: ActivityAndDrivingTimesViewProps & ActivityAndDrivingTimesViewComponentProps): void {
        if (prevProps.displayItem.assetId !== this.props.displayItem.assetId) {
            this.props.setShowOutdatedData(false);
        }
    }

    onShowOutdatedDataClick(): void {
        this.props.setShowOutdatedData(true);
    }

    render(): JSX.Element {
        const displayItem = this.props.displayItem;
        const timestamp = displayItem[TableColumn.DRIVING_TIMES_TIMESTAMP];

        if (!this.props.showOutdatedData && timestamp && isDataOutdated(timestamp)) {
            return (
                <OutdatedView
                    displayItem={displayItem}
                    onShowOutdatedDataClick={this.onShowOutdatedDataClick.bind(this)}
                />
            );
        } else {
            return (
                <UpToDateView
                    displayItem={displayItem}
                    isWorkingTimesFeatureSupported={this.props.isWorkingTimesFeatureSupported}
                />
            );
        }
    }
}

const mapStateToProps = (state: State): NonFunctionProperties<ActivityAndDrivingTimesViewProps> => ({
    showOutdatedData: getShowOutdatedData(state),
    isWorkingTimesFeatureSupported: getFeatures(state).includes(Feature.WORKING_TIMES),
});
const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<ActivityAndDrivingTimesViewProps> => ({
    setShowOutdatedData: (isVisible: boolean) => dispatch(widgetActions.setShowOutdatedData(isVisible)),
});
export const ActivityAndDrivingTimesViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityAndDrivingTimesViewComponent);
