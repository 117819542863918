import { FormattedMessage, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import TableSettingsDialog from '@rio-cloud/rio-uikit/TableSettingsDialog';
import Button from '@rio-cloud/rio-uikit/Button';

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    getColumnOrder,
    getHiddenColumns,
    getShowTableSettings,
    tableSettingsActions,
} from './redux/tablesettings.redux';
import { getIsStateChangesDisplayed } from '../../redux/realtime.redux';
import {
    createGoogleAnalyticsEvent,
    dataLayerPush,
    TrackingAction,
    trackingAttributes,
    TrackingCategory,
} from '../../../../configuration/setup/googleTagManager';
import { State } from '../../../../reducers';
import { getColumnHeaderMessageId } from '../../../tables/header/columnHeaderFormattingService';
import { TableColumn } from '../../../enums/TableColumn';
import { getAvailableCurrentColumns } from '../../../tables/drivingtimes/columnService';

interface TableSettingDialogWrapperStateProps {
    historicalViewSelected: boolean;
    showColumnOrderDialog: boolean;
    columnOrder: TableColumn[];
    defaultColumnOrder: TableColumn[];
    hiddenColumnNames: TableColumn[];
}

interface TableSettingDialogWrapperDispatchProps {
    toggleShowTableSettings: () => void;
    onColumnOrderChange: (newOrder: string[], newHiddenColumns: string[]) => void;
}

type TableSettingDialogWrapperProps = TableSettingDialogWrapperStateProps &
    TableSettingDialogWrapperDispatchProps &
    WrappedComponentProps;

const getTranslatedLabels = (intl: IntlShape): { [key: string]: string } => {
    const translatedLabels: { [key: string]: string } = {};
    Object.values(TableColumn).map((key) => {
        const messageId = getColumnHeaderMessageId(key);
        return (translatedLabels[key] = messageId ? intl.formatMessage({ id: messageId }) : '');
    });
    return translatedLabels;
};

const trackShowTableSettingsDialog = trackingAttributes(
    TrackingCategory.DRIVING_TIMES_VIEW,
    TrackingAction.SHOW_TABLE_SETTINGS_DIALOG
);

const TableSettingDialogWrapper = (props: TableSettingDialogWrapperProps): JSX.Element => {
    const {
        historicalViewSelected,
        toggleShowTableSettings,
        showColumnOrderDialog,
        onColumnOrderChange,
        hiddenColumnNames,
        columnOrder,
        defaultColumnOrder,
        intl,
    } = props;

    const showTableSettingsDialogButton = (
        <Button
            className={'btn btn-default btn-icon-only'}
            onClick={toggleShowTableSettings}
            {...trackShowTableSettingsDialog}
        >
            <span className={'rioglyph rioglyph-cog'} />
        </Button>
    );
    return (
        <>
            {historicalViewSelected ? null : showTableSettingsDialogButton}
            <FormattedMessage id={'intl-msg:timed.driving-time-table.searchColumn'}>
                {(placeholder: React.ReactNode | string | undefined): JSX.Element => (
                    <TableSettingsDialog
                        show={showColumnOrderDialog}
                        onHide={toggleShowTableSettings}
                        title={<FormattedMessage id={'intl-msg:timed.table-setting.tableSettings'} />}
                        notFoundMessage={intl.formatMessage({ id: 'intl-msg:timed.table-setting.nothingFound' })}
                        searchPlaceholder={placeholder}
                        resetButtonText={<FormattedMessage id={'intl-msg:timed.table-setting.resetToDefault'} />}
                        closeButtonText={<FormattedMessage id={'intl-msg:timed.common.close'} />}
                        cancelButtonText={<FormattedMessage id={'intl-msg:timed.table-setting.abort'} />}
                        applyButtonText={<FormattedMessage id={'intl-msg:timed.table-setting.apply'} />}
                        defaultColumnOrder={defaultColumnOrder}
                        defaultHiddenColumns={[]}
                        columnOrder={columnOrder}
                        hiddenColumns={hiddenColumnNames}
                        columnLabels={getTranslatedLabels(intl)}
                        disabledColumns={[TableColumn.VEHICLE, TableColumn.DRIVER]}
                        onColumnChange={onColumnOrderChange}
                    />
                )}
            </FormattedMessage>
        </>
    );
};

const mapStateToProps = (state: State): TableSettingDialogWrapperStateProps => ({
    columnOrder: getColumnOrder(state),
    defaultColumnOrder: getAvailableCurrentColumns(state),
    hiddenColumnNames: getHiddenColumns(state),
    historicalViewSelected: getIsStateChangesDisplayed(state),
    showColumnOrderDialog: getShowTableSettings(state),
});

const mapDispatchToProps = (dispatch: Dispatch): TableSettingDialogWrapperDispatchProps => ({
    onColumnOrderChange: (newOrder, newHiddenColumns): void => {
        dataLayerPush(
            createGoogleAnalyticsEvent(
                TrackingCategory.DRIVING_TIMES_VIEW,
                TrackingAction.APPLY_TABLE_SETTINGS_CHANGES,
                newOrder.filter((column) => !newHiddenColumns.includes(column)).join(', ')
            )
        );
        dispatch(tableSettingsActions.setColumnOrder(newOrder as TableColumn[]));
        dispatch(tableSettingsActions.setHiddenColumns(newHiddenColumns as TableColumn[]));
    },
    toggleShowTableSettings: (): void => {
        dispatch(tableSettingsActions.toggleShowTableSettings());
    },
});

export const TableSettingDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TableSettingDialogWrapper));
