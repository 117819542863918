import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WidgetState } from './types';
import { get } from 'lodash';
import { State } from '../../../reducers';

const name = 'widget';

const initialState: WidgetState = {
    isVisible: false,
    showWorkingTimesInfoBox: true,
    showOutdatedData: false,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setWidgetId: (state: WidgetState, action: PayloadAction<string>): void => {
            state.widgetId = action.payload;
        },
        setWidgetVisible: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.isVisible = action.payload;
        },
        setShowWorkingTimesInfoBox: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.showWorkingTimesInfoBox = action.payload;
        },
        setShowOutdatedData: (state: WidgetState, action: PayloadAction<boolean>): void => {
            state.showOutdatedData = action.payload;
        },
    },
});

export const widgetActions = actions;
export const widgetReducer = reducer;

const getBase = (state: State): WidgetState | undefined => get(state, name);

export const getWidgetId = (state: State): string => get(getBase(state), 'widgetId') ?? '';
export const isWidgetVisible = (state: State): boolean => get(getBase(state), 'isVisible') ?? false;
export const showWorkingTimesInfoBox = (state: State): boolean =>
    get(getBase(state), 'showWorkingTimesInfoBox') ?? true;
export const getShowOutdatedData = (state: State): boolean => get(getBase(state), 'showOutdatedData') ?? false;
