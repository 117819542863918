import { StateActivity, StateChangesDisplayItem } from './types';
import { getDrivers, getVehicles } from '../../data/redux/data.redux';
import {
    getSelectedActivities,
    getSelectedStateChangesRowId,
    getSelectedTimestampCategories,
    getStateChangesItems,
} from '../../realtime/redux/realtime.redux';
import { AssetItem, Driver } from '../../data/redux/types';
import { getVehicleNameOfAssetId } from '../../data/service/vehicleService';
import { getDriverNameOfDriverId } from '../../data/service/driverService';
import moment, { Moment } from 'moment';
import { get, isEmpty } from 'lodash';
import { StatusDuration } from '../cells/types';
import { Activities, convertFromApiValues } from '../../enums/Activities';
import { getSortBy, getSortDir } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { sortStateChangesDisplayItems } from '../sorting/sortingService';
import { ApiStateChangeItem, StateChange } from '../../api/types';
import {
    isActivityInSelectedActivities,
    isAssetIdInSelectedAssetIds,
    isDriverIdInSelectedDriverIds,
    isTimestampInSelectedTimestampCategories,
} from '../filtering/filteringService';
import { getIdsOfSelectedAssets, getIdsOfSelectedDrivers } from '../../tree/selectionService';
import { TimeStampCategory } from '../../enums/TimeStampCategory';
import { createSelector } from '@reduxjs/toolkit';
import { TableColumn } from '../../enums/TableColumn';
import { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const getTimestamp = (item: StateChange): Moment | undefined => {
    const timestamp = get(item, 'state_change_end', get(item, 'state_change_start'));
    return timestamp ? moment(timestamp) : undefined;
};

export const convertToStateActivity = (stateChange: ApiStateChangeItem): StateActivity | undefined => {
    const from = get(stateChange, 'state_change_start');
    const to = get(stateChange, 'state_change_end');
    const activity = convertFromApiValues(get(stateChange, 'working_state.state'));

    if (!from || !to || !activity) {
        return undefined;
    }

    return { from: moment(from), to: moment(to), activity };
};

const getStateChanges = (item: StateChange): StateActivity[] | undefined => {
    if (isEmpty(item.stateChanges)) {
        return undefined;
    }

    return item.stateChanges
        .map(convertToStateActivity)
        .filter((stateActivity) => stateActivity !== undefined) as StateActivity[];
};

const getStatusDuration = (item: ApiStateChangeItem): StatusDuration | undefined => {
    const stateActivity = convertToStateActivity(item);

    if (!stateActivity) {
        return undefined;
    }

    return {
        status: stateActivity.activity,
        duration: moment(stateActivity.to).diff(moment(stateActivity.from), 'minutes'),
    };
};

const convertToDisplayItem = (
    item: StateChange,
    vehicles: AssetItem[],
    drivers: Driver[],
    selectedDrivingTimesRowId: string | undefined
): StateChangesDisplayItem => {
    const driverName = getDriverNameOfDriverId(item.driver_id, drivers);
    return {
        id: item.id,
        isActive: item.id === selectedDrivingTimesRowId,
        [TableColumn.VEHICLE]: getVehicleNameOfAssetId(item.asset_id, vehicles),
        [TableColumn.DRIVER]: driverName ? driverName : item.identification,
        [TableColumn.STATUS_DURATION]: getStatusDuration(item),
        [TableColumn.STATE_CHANGES]: getStateChanges(item),
        [TableColumn.ACTIVITIES_TIMESTAMP]: getTimestamp(item),
    };
};

const createRelevantStateChangeDisplayItems = (
    stateChangeItems: StateChange[],
    selectedAssetIds: string[],
    selectedDriverIds: string[],
    vehicles: AssetItem[],
    drivers: Driver[],
    selectedStateChangesRowId: string | undefined,
    selectedActivities: Activities[],
    selectedTimestampCategories: TimeStampCategory[],
    sortBy: TableColumn,
    sortDir: SortDirectionType
): StateChangesDisplayItem[] => {
    const displayItems = stateChangeItems
        .filter((stateChangeItem) => isAssetIdInSelectedAssetIds(stateChangeItem.asset_id, selectedAssetIds))
        .filter((stateChangeItem) => isDriverIdInSelectedDriverIds(stateChangeItem.driver_id, selectedDriverIds))
        .map((stateChangeItem) => convertToDisplayItem(stateChangeItem, vehicles, drivers, selectedStateChangesRowId));

    const filteredItems = displayItems
        .filter((stateChangeItem) =>
            isActivityInSelectedActivities(stateChangeItem[TableColumn.STATUS_DURATION], selectedActivities)
        )
        .filter((stateChangeItem) =>
            isTimestampInSelectedTimestampCategories(
                stateChangeItem[TableColumn.ACTIVITIES_TIMESTAMP],
                selectedTimestampCategories
            )
        );

    return sortStateChangesDisplayItems(filteredItems, sortBy, sortDir);
};

export const getStateChangesDisplayItems = createSelector(
    getStateChangesItems,
    getIdsOfSelectedAssets,
    getIdsOfSelectedDrivers,
    getVehicles,
    getDrivers,
    getSelectedStateChangesRowId,
    getSelectedActivities,
    getSelectedTimestampCategories,
    getSortBy,
    getSortDir,
    createRelevantStateChangeDisplayItems
);
