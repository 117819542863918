import { Dispatch } from 'redux';
import { ApiCalls, ErrorCauses } from '../../api/redux/types';
import { fetchActivities } from '../../api/api';
import { StateChange } from '../../api/types';
import { getFromOfTimeRangeSelection, getToOfTimeRangeSelection, realtimeActions } from '../redux/realtime.redux';
import { apiCallActions } from '../../api/redux/api.redux';
import moment from 'moment';
import { isUserSessionExpired } from '../../../configuration/login/selectors';
import { logDurationWrapper } from '../../../common/timeUtils/timeMeasurementHelper';
import { State } from '../../../reducers';

export const fetchStateChangesDataThunk =
    (isLoadingSpinnerDisplayed: boolean) =>
    (dispatch: Dispatch, getState: () => State): void => {
        const state = getState();
        if (isUserSessionExpired(state)) {
            dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.SESSION_EXPIRED_FETCH, value: true }));
            return;
        }

        if (isLoadingSpinnerDisplayed) {
            dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.STATE_CHANGES, value: true }));
        }
        const requests = [
            logDurationWrapper(fetchActivities, 1000, { endpoint: 'activities' })(
                getFromOfTimeRangeSelection(state),
                getToOfTimeRangeSelection(state)
            ),
        ] as Promise<StateChange[]>[];

        Promise.all(requests)
            .then((result) => {
                dispatch(realtimeActions.fetchedStateChanges(result[0]));
                dispatch(realtimeActions.setReceivedTimestampOfStateChanges(moment()));

                dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.STATE_CHANGES_FETCH, value: false }));
                dispatch(
                    apiCallActions.setErrorForApiCall({
                        type: ErrorCauses.SESSION_EXPIRED_FETCH,
                        value: false,
                    })
                );
                dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.STATE_CHANGES, value: false }));
            })
            .catch(() => {
                dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.STATE_CHANGES_FETCH, value: true }));
                dispatch(apiCallActions.setLoadingForApiCall({ type: ApiCalls.STATE_CHANGES, value: false }));
            });
    };
