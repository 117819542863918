import { LocalStorageState } from './localStorageCodec';

export interface PersistedWidgetState {
    showWorkingTimesInfoBox: boolean;
}

export const localStorageMapper = (input: LocalStorageState): PersistedWidgetState => {
    return {
        showWorkingTimesInfoBox: input.showWorkingTimesInfoBox,
    };
};
