import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import ReleaseNotesDialog from '@rio-cloud/rio-uikit/ReleaseNotesDialog';

import {
    getCurrentVersion,
    getShowGlossaryDialog,
    getShowReleaseNotes,
    serviceInformationActions,
} from './serviceInformation.redux';
import { GlossaryDialog } from './GlossaryDialog';
import { getLanguageData, getLocale, LanguageDataInterface } from '../../configuration';
import { releaseNotes } from './releaseNotes';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { isNewVersion, RELEASE_NOTES_ITEM } from './lastUsedVersion';
import { State } from '../../reducers';

const getLanguageForLinksInReleaseNotes = (locale: string): string => {
    if (locale === 'de-DE') {
        return 'de';
    } else {
        return 'en';
    }
};

type ReleaseNotesProps = ReleaseNotesStateProps & ReleaseNotesDispatchProps;

interface ReleaseNotesStateProps {
    showReleaseNotes: boolean;
    translations: LanguageDataInterface;
    locale: string;
    showGlossaryDialog: boolean;
    currentVersion: string;
}

interface ReleaseNotesDispatchProps {
    setShowReleaseNotes: (payload: boolean) => void;
    toggleGlossaryDialog: () => void;
}

export class ReleaseNotes extends React.Component<ReleaseNotesProps> {
    getPopoverContent(hasNewReleaseNotes: boolean): JSX.Element {
        return (
            <div>
                <div className={'line-height-largest'}>
                    {/* eslint-disable-next-line */}
                    <a className={'ShowReleaseNotesLink'} onClick={() => this.props.setShowReleaseNotes(true)}>
                        <FormattedMessage id={'intl-msg:timed.glossary.whatsNew'} />
                        {hasNewReleaseNotes && <span className={'badge bg-primary margin-left-5'}>{'1'}</span>}
                    </a>
                </div>
                <div className={'line-height-largest'}>
                    {/* eslint-disable-next-line */}
                    <a
                        href={'#'}
                        className={'ShowGlossaryLink'}
                        onClick={(): void => this.props.toggleGlossaryDialog()}
                    >
                        <FormattedMessage id={'intl-msg:timed.glossary.help'} />
                    </a>
                </div>
            </div>
        );
    }

    render(): JSX.Element {
        const { showReleaseNotes, translations, locale } = this.props;

        const hasNewReleaseNotes = isNewVersion(this.props.currentVersion);
        const actionBarItemTitle = `${this.props.translations['intl-msg:timed.common.module.name']} v.${this.props.currentVersion}`;

        return (
            <ActionBarItem id={'serviceInfo'}>
                <ActionBarItem.Icon>
                    <span className={'icon rioglyph rioglyph-info-sign'} />
                    {hasNewReleaseNotes && <span className={'badge bg-primary'}>{'1'}</span>}
                </ActionBarItem.Icon>
                <ActionBarItem.Popover title={actionBarItemTitle}>
                    {this.getPopoverContent(hasNewReleaseNotes)}
                </ActionBarItem.Popover>
                <ReleaseNotesDialog
                    showReleaseNotes={showReleaseNotes}
                    setShowReleaseNotes={this.props.setShowReleaseNotes}
                    moduleName={translations['intl-msg:timed.common.module.name']}
                    whatsNewTitle={translations['intl-msg:timed.glossary.whatsNew']}
                    closeButtonText={translations['intl-msg:timed.common.close']}
                    translatedReleaseNotes={releaseNotes(
                        this.props.toggleGlossaryDialog,
                        getLanguageForLinksInReleaseNotes(locale)
                    )}
                    currentVersion={this.props.currentVersion}
                    RELEASE_NOTES_ITEM={RELEASE_NOTES_ITEM}
                />
                <GlossaryDialog show={this.props.showGlossaryDialog} toggleDialog={this.props.toggleGlossaryDialog} />
            </ActionBarItem>
        );
    }
}

const mapStateToProps = (state: State): ReleaseNotesStateProps => ({
    showReleaseNotes: getShowReleaseNotes(state),
    translations: getLanguageData(state),
    locale: getLocale(state),
    showGlossaryDialog: getShowGlossaryDialog(state),
    currentVersion: getCurrentVersion(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<Dispatch, State, any>): ReleaseNotesDispatchProps => ({
    setShowReleaseNotes: (payload): void => {
        dispatch(serviceInformationActions.setShowReleaseNotes(payload));
    },
    toggleGlossaryDialog: (): void => {
        dispatch(serviceInformationActions.toggleGlossaryDialog());
    },
});

export const ReleaseNotesContainer = connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
