import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { treeActions } from '../tree/redux/tree.redux';
import { getVehicles } from '../data/redux/data.redux';
import { getWidgetId, widgetActions } from './redux/widget.redux';
import { State } from '../../reducers';
import { TreeItem } from '@rio-cloud/rio-uikit/Tree';

export enum MessageType {
    SELECT_ASSET = 'EVENT_SELECT_ASSET',
    SET_WIDGET_ID = 'EVENT_SET_WIDGET_ID',
    SET_VISIBLE_WIDGETS = 'EVENT_SET_VISIBLE_WIDGETS',
}

const receiveSelectAssetMessage = (
    event: SelectAssetMessageEvent,
    knownVehicles: TreeItem[],
    dispatch: (action: Action) => void
): void => {
    const id = event?.data?.payload?.id;
    dispatch(treeActions.setSelectedVehicles(id ? [id] : []));
};

const receiveSetWidgetId = (event: SetWidgetIdMessageEvent, dispatch: (action: Action) => void): void => {
    const payload = event?.data?.payload;
    if (payload) {
        dispatch(widgetActions.setWidgetId(payload));
    }
};

const receiveSetVisibleWidgets = (
    event: SetVisibleWidgetsMessageEvent,
    widgetId: string,
    dispatch: (action: Action) => void
): void => {
    const payload = event?.data?.payload;
    if (payload) {
        const isWidgetVisible = !!payload?.includes(widgetId);
        dispatch(widgetActions.setWidgetVisible(isWidgetVisible));
    }
};

const MessageHandler = (props: MessageHandlerProps): null => {
    const { dispatch, vehicles, widgetId } = props;
    useEffect(() => {
        const listenerCallback = (event: MessageEvent): void => {
            switch (event?.data?.type) {
                case MessageType.SELECT_ASSET:
                    receiveSelectAssetMessage(event as SelectAssetMessageEvent, vehicles, dispatch);
                    break;
                case MessageType.SET_WIDGET_ID:
                    receiveSetWidgetId(event as SetWidgetIdMessageEvent, dispatch);
                    break;
                case MessageType.SET_VISIBLE_WIDGETS:
                    receiveSetVisibleWidgets(event as SetVisibleWidgetsMessageEvent, widgetId, dispatch);
                    break;
            }
        };
        window.addEventListener('message', listenerCallback, false);
        return (): void => window.removeEventListener('message', listenerCallback, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicles, widgetId]);
    return null;
};

const mapStateToProps = (state: State): MessageHandlerStateProps => ({
    vehicles: getVehicles(state),
    widgetId: getWidgetId(state),
});

const mapDispatchToProps = (dispatch: Dispatch): MessageHandlerDispatchProps => ({
    dispatch: (action: Action): void => {
        dispatch(action);
    },
});

type MessageHandlerProps = MessageHandlerStateProps & MessageHandlerDispatchProps;

interface MessageEvent {
    data?: {
        type?: string;
    };
}

type SelectAssetMessageEvent = MessageEvent & {
    data?: {
        payload?: {
            id?: string;
        };
    };
};

type SetWidgetIdMessageEvent = MessageEvent & {
    data?: {
        payload?: string;
    };
};

type SetVisibleWidgetsMessageEvent = MessageEvent & {
    data?: {
        payload?: string[];
    };
};

interface MessageHandlerStateProps {
    vehicles: TreeItem[];
    widgetId: string;
}

interface MessageHandlerDispatchProps {
    dispatch: (action: Action) => void;
}

export const sendMessage = (message: Action): void => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const MessageHandlerContainer = connect(mapStateToProps, mapDispatchToProps)(MessageHandler);
