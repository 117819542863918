import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModeSwitchButtonDispatchProps, ModeSwitchButtonProps, ModeSwitchButtonStateProps } from '../types';
import { getIsCurrentValuesDisplayed, realtimeActions } from '../../redux/realtime.redux';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../../configuration/setup/googleTagManager';
import { State } from '../../../../reducers';

const trackClickShowCurrentValues = trackingAttributes(
    TrackingCategory.DRIVING_TIMES_VIEW,
    TrackingAction.CHANGE_TIME_DISPLAY_MODE,
    'display current times'
);

const trackClickShowRemainingValues = trackingAttributes(
    TrackingCategory.DRIVING_TIMES_VIEW,
    TrackingAction.CHANGE_TIME_DISPLAY_MODE,
    'display remaining times'
);

export const ModeSwitchButtons = (props: ModeSwitchButtonProps): JSX.Element => {
    const { isShowCurrentValues, showCurrentValues, showRemainingValues } = props;

    return (
        <div className={'btn-group'}>
            <Button
                key={'RealTime-drivingTimes-Button'}
                active={isShowCurrentValues}
                onClick={showCurrentValues}
                {...trackClickShowCurrentValues}
            >
                <FormattedMessage id={'intl-msg:timed.driving-time-table.ongoing'} />
            </Button>
            <Button
                key={'RealTime-remainingTimes-Button'}
                active={!isShowCurrentValues}
                onClick={showRemainingValues}
                {...trackClickShowRemainingValues}
            >
                <FormattedMessage id={'intl-msg:timed.common.remaining'} />
            </Button>
        </div>
    );
};

const mapStateToProps = (state: State): ModeSwitchButtonStateProps => ({
    isShowCurrentValues: getIsCurrentValuesDisplayed(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ModeSwitchButtonDispatchProps => ({
    showCurrentValues: (): void => {
        dispatch(realtimeActions.setIsCurrentValuesDisplayed(true));
    },
    showRemainingValues: (): void => {
        dispatch(realtimeActions.setIsCurrentValuesDisplayed(false));
    },
});

export const ModeSwitchButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(ModeSwitchButtons);
