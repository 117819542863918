/* eslint-disable */
import * as t from 'io-ts';
import { BasicResponseStructure, ResourceLinkList } from './resourceLinkCodec';

const activity = t.type({
    activity_id: t.string,
    driver_id: t.string,
    asset_id: t.string,
    identification: t.string,
    identification_type: t.string,
    activity_type: t.string,
    start: t.string,
    end: t.string,
});

export const apiResponseActivitiesListCodec = t.type({
    _links: ResourceLinkList,
    items: t.array(activity),
});

export type ApiResponseActivities = BasicResponseStructure & t.TypeOf<typeof apiResponseActivitiesListCodec>;

export type ApiResponseActivity = t.TypeOf<typeof activity>;
