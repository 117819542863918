import { connect } from 'react-redux';
import { useEffect } from 'react';
import { isWidgetVisible } from '../../features/widget/redux/widget.redux';
import { dataLayerPush, GoogleTagManagerEvent } from './googleTagManager';
import { State } from '../../reducers';

interface VisibilityTrackerGoogleAnalyticsProps {
    googleAnalyticsEvent: GoogleTagManagerEvent;
}

interface VisibilityTrackerVisibleProps {
    componentIsVisible: boolean;
}

export const VisibilityTrackerComponent = (
    props: VisibilityTrackerGoogleAnalyticsProps & VisibilityTrackerVisibleProps
): null => {
    const { googleAnalyticsEvent, componentIsVisible } = props;
    useEffect(() => {
        if (componentIsVisible) {
            dataLayerPush(googleAnalyticsEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentIsVisible]);
    return null;
};

const mapStateToProps = (state: State): VisibilityTrackerVisibleProps => ({
    componentIsVisible: isWidgetVisible(state),
});

export const WidgetVisibilityTrackerContainer = connect(mapStateToProps)(VisibilityTrackerComponent);
