/* eslint-disable no-underscore-dangle */
import { getRequest } from './requests';
import { config } from '../../config';
import { mapToAssetList } from './mapper/assetListMapper';
import { mapToDriverList } from './mapper/driverListMapper';
import { mapToMapCredentials } from './mapper/mapCredentialsmapper';
import { mapToTagList } from './mapper/tagListMapper';
import { reportErrorToSentry, sendErrorToSentry } from '../../configuration/setup/sentry';
import { Asset, Driver, MapCredentials, ProductActivations, Tag } from '../data/redux/types';
import { getActivitiesUrlEncodedParameters } from './parameterService';
import { ApiDrivingTimesItem, ApiWorkingTimesItem, LatestPosition, StateChange } from './types';
import { mapToLatestPosition } from './mapper/assetLiveStateMapper';
import { mapToDrivingTimes } from './mapper/drivingTimesMapper';
import { mapToActivities } from './mapper/activitiesMapper';
import {
    decodeApiResponseActivities,
    decodeApiResponseAssetLiveState,
    decodeApiResponseAssets,
    decodeApiResponseDrivers,
    decodeApiResponseDrivingTime,
    decodeApiResponseTag,
    decodeApiResponseWorkingTime,
    decodeProductActivationsApiResponse,
} from './iots/iotsDecoder';
import { BasicResponseStructure } from './iots/resourceLinkCodec';
import { mapToProductActivations } from './mapper/productActivationsMapper';
import { mapToWorkingTimes } from './mapper/workingTimesMapper';

const jsonOrReject = (response: Response = {} as Response, propagateNotFoundErrorToSentry = true): Promise<any> => {
    if (response.status === 401) {
        return Promise.reject(new Error(`UNAUTHENTICATED while calling ${response.url}`));
    } else if (response.status === 403) {
        return Promise.reject(new Error(`ACCESS_DENIED while calling ${response.url}`));
    } else if (response.ok) {
        return response.json().catch((error) => {
            const payloadError = new Error(`${response.status} Invalid payload from ${response.url}: ${error}`);
            sendErrorToSentry(payloadError);
            return Promise.reject(payloadError);
        });
    } else if (response.status === 404) {
        if (propagateNotFoundErrorToSentry) {
            sendErrorToSentry(
                new Error(`${response.status} Backend error while calling ${response.url}: ${response.statusText}`)
            );
        }
    } else {
        sendErrorToSentry(
            new Error(`${response.status} Backend error while calling ${response.url}: ${response.statusText}`)
        );
    }
    return Promise.reject();
};

export const fetchAssets = async (): Promise<Asset[]> =>
    exhaustPagination(
        `${config.backend.ASSET_ADMINISTRATION}/assets?embed=(tags)`,
        mapToAssetList,
        decodeApiResponseAssets
    );

export const fetchTags = async (): Promise<Tag[]> =>
    fetch(`${config.backend.TAGS_SERVICE}/tags`, getRequest())
        .then(jsonOrReject)
        .then(decodeApiResponseTag)
        .then(mapToTagList);

export const fetchDrivers = async (): Promise<Driver[]> =>
    exhaustPagination(
        `${config.backend.DRIVER_SERVICE}/drivers?embed=(tags)`,
        mapToDriverList,
        decodeApiResponseDrivers
    );

export const fetchDrivingTimes = async (): Promise<ApiDrivingTimesItem[]> =>
    exhaustPagination(
        `${config.backend.DRIVINGTIME_CONTEXT}/drivers?limit=1000`,
        mapToDrivingTimes,
        decodeApiResponseDrivingTime
    );

export const fetchWorkingTimes = async (): Promise<ApiWorkingTimesItem[]> =>
    exhaustPagination(
        `${config.backend.DRIVINGTIME_CONTEXT}/drivers-working-times?limit=1000`,
        mapToWorkingTimes,
        decodeApiResponseWorkingTime
    );

const exhaustPagination = async <ApiItem, DomainItem, ApiResponse extends BasicResponseStructure>(
    startUrl: string,
    mapper: (data: ApiItem[]) => DomainItem[],
    decoder: (parsedObject: unknown) => Promise<ApiResponse>
): Promise<DomainItem[]> => {
    const MAX_BACKEND_CALLS = 1000;
    const items: ApiItem[] = [];
    let calls = 0;
    let nextUrl: string | undefined = startUrl;

    while (calls < MAX_BACKEND_CALLS && nextUrl) {
        calls++;
        nextUrl = await fetch(nextUrl, getRequest())
            .then(jsonOrReject)
            .then(decoder)
            .then((response) => {
                items.push(...response.items);
                return response._links.next?.href;
            });
    }

    if (calls >= MAX_BACKEND_CALLS) {
        reportErrorToSentry(`maximal number of pages for ${startUrl} exceeded!`);
    }

    return Promise.resolve(mapper(items));
};

export const fetchActivities = async (from: string, to: string): Promise<StateChange[]> => {
    const encodedParameters = getActivitiesUrlEncodedParameters(from, to);
    return exhaustPagination(
        `${config.backend.DRIVINGTIME_CONTEXT}/drivers-activities${encodedParameters}`,
        mapToActivities,
        decodeApiResponseActivities
    );
};

export const fetchProductActivations = async (): Promise<ProductActivations> =>
    fetch(`${config.backend.PRODUCT_ACTIVATIONS_API}/product-activations`, getRequest())
        .then(jsonOrReject)
        .then(decodeProductActivationsApiResponse)
        .then((it) => mapToProductActivations(it));

export const fetchMapCredentials = async (): Promise<MapCredentials | undefined> =>
    fetch(`${config.backend.MAP_SERVICE}/configurations`, getRequest()).then(jsonOrReject).then(mapToMapCredentials);

export const fetchLatestPositionOfAsset = async (
    assetId: string,
    locale: string
): Promise<LatestPosition | undefined> =>
    fetch(`${config.backend.ASSET_LIVE_STATE}/live-state/assets/${assetId}?locale=${locale}`, getRequest())
        .then((payload) => jsonOrReject(payload, false))
        .then(decodeApiResponseAssetLiveState)
        .then(mapToLatestPosition);
