/* tslint:disable:jsx-no-lambda */
import { FormattedMessage } from 'react-intl';
import TimedHistoricalViewStep2 from './assets/timedHistoricalViewStep2.png';
import TimedTableSettings from './assets/release_timed_table_settings.jpg';
import TimedActivitiesHistorySelection from './assets/timedActivitiesHistorySelection.png';
import TimedAndComplianceMenu from './assets/timedAndComplianceMenu.png';
import SidebarMap from './assets/release_sidebar_map.jpg';
import FleetmonitorRelease from './assets/release_for_fleetmonitor.jpg';
import WorkingTimeRelease from './assets/release_working_times.png';
import FleetboardDataAvailability from './assets/fleetboardDataAvailability.png';
import { noop } from 'lodash/fp';
import semverCompare from 'semver-compare';
import { ReleaseNotesDialogProps } from '@rio-cloud/rio-uikit/ReleaseNotesDialog';

const pictureStyle = 'margin-10 width-100pct';

export const releaseNotes = (
    toggleGlossaryDialog: (page: string) => void,
    language: string
): ReleaseNotesDialogProps['translatedReleaseNotes'] => ({
    '2.14.0': {
        date: '2023-11-22',
        content: [
            <div key={'releaseNotes.2.14.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage
                    id={'intl-msg:timed.releaseNotes.2.14.0'}
                    values={{
                        br: <br />,
                    }}
                />
                <img src={WorkingTimeRelease} alt={'Workingtime Release'} className={`${pictureStyle}`} />
            </div>,
        ],
    },
    '2.13.0': {
        date: '2023-09-12',
        content: [
            <div key={'releaseNotes.2.13.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.13.0'} />
            </div>,
        ],
    },
    '2.12.0': {
        date: '2023-05-22',
        content: [
            <div key={'releaseNotes.2.12.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage
                    id={'intl-msg:timed.releaseNotes.2.12.0'}
                    values={{
                        link: (chunks) => (
                            <a href={`https://rio.cloud/${language}/mixed-fleet`} target={'_blank'} rel="noreferrer">
                                {chunks}
                            </a>
                        ),
                    }}
                />
                <img
                    src={FleetboardDataAvailability}
                    alt={'FleetboardDataAvailability'}
                    className={`${pictureStyle}`}
                />
            </div>,
        ],
    },
    '2.11.0': {
        date: '2021-03-24',
        content: [
            <div key={'releaseNotes.2.11.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.11.0'} />
                <img src={TimedAndComplianceMenu} alt={'Timed Table Settings'} className={'margin-10'} />
            </div>,
        ],
    },
    '2.10.0': {
        date: '2020-09-10',
        content: [
            <div key={'releaseNotes.2.10.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.10.0'} />
                <img src={TimedActivitiesHistorySelection} alt={'Timed Table Settings'} className={`${pictureStyle}`} />
            </div>,
        ],
    },
    '2.9.0': {
        date: '2019-12-05',
        content: [
            <div key={'releaseNotes.2.9.0'}>
                <div>
                    <span>{'Timed: '}</span>
                    <FormattedMessage id={'intl-msg:timed.releaseNotes.2.9.0'} />
                </div>
                <div>
                    <button
                        className={'btn btn-primary btn-link'}
                        onClick={(): void => toggleGlossaryDialog('timed.historicalView')}
                    >
                        <span className={'rioglyph rioglyph-info-sign'} />
                        <FormattedMessage id={'intl-msg:timed.glossary.moreDetailsOnHelpPage'} />
                    </button>
                </div>
                <img src={TimedHistoricalViewStep2} alt={'Timeline'} className={`${pictureStyle}`} />
            </div>,
        ],
    },
    '2.5.0': {
        date: '2019-06-10',
        content: [
            <div key={'releaseNotes.2.5.0'}>
                <span>{'Timed: '}</span>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.timed.tableSettings'} />
                <img src={TimedTableSettings} alt={'Timed Table Settings'} className={`${pictureStyle}`} />
            </div>,
        ],
    },
    '2.2.0': {
        date: '2019-06-04',
        content: [
            <div key={'releaseNotes.2.2.0'} className={'form-group'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.2.0'} />
                <img src={SidebarMap} alt={'Sidebar Map'} className={`${pictureStyle}`} />
            </div>,
            <div key={'releaseNotes.2.2.0.fleetmonitor'} className={'form-group'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.2.0.fleetmonitor'} />
                <img src={FleetmonitorRelease} alt={'Timed on Fleetmonitor'} className={`${pictureStyle}`} />
            </div>,
        ],
    },
    '2.1.0': {
        date: '2019-04-09',
        content: [
            <div key={'releaseNotes.2.1.0.realtimeSidebar'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.1.0.realtimeSidebar'} />
            </div>,
            <div key={'releaseNotes.2.1.0.newSignals'} className={'form-group'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.1.0.newSignals'} />
            </div>,
        ],
    },
    '2.0.0': {
        date: '2019-03-04',
        content: [
            <div key={'releaseNotes.2.0.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.2.0.0'} />
            </div>,
        ],
    },
    '1.7.0': {
        date: '2019-02-04',
        content: [
            <div key={'releaseNotes.1.7.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.1.7.0'} />
            </div>,
        ],
    },
    '1.6.0': {
        date: '2018-11-19',
        content: [
            <div key={'releaseNotes.1.6.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.1.6.0'} />
            </div>,
        ],
    },
    '1.4.0': {
        date: '2018-09-04',
        content: [
            <div key={'releaseNotes.1.4.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.1.4.0'} />
            </div>,
        ],
    },
    '1.3.0': {
        date: '2018-07-23',
        content: [
            <div key={'releaseNotes.1.3.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.1.3.0'} />
            </div>,
        ],
    },
    '1.2.0': {
        date: '2018-06-27',
        content: [
            <div key={'releaseNotes.1.2.0'}>
                <FormattedMessage id={'intl-msg:timed.releaseNotes.1.2.0'} />
            </div>,
        ],
    },
});

export const getNewestVersionFromReleaseNotes = (): string =>
    Object.keys(releaseNotes(() => noop(), 'de'))
        .sort(semverCompare)
        .reverse()[0];
