import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { LatestPosition } from '../../api/types';
import { DetailsSidebarDisplayItem } from '../types';
import { State } from '../../../reducers';

const name = 'sidebar';

export interface SidebarState {
    displayItems: DetailsSidebarDisplayItem[];
    latestPosition: LatestPosition | undefined;
}

const initialState: SidebarState = {
    displayItems: [],
    latestPosition: undefined,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        fetchedLastPosition: (state: SidebarState, action: PayloadAction<LatestPosition>): void => {
            state.latestPosition = action.payload;
        },
        resetLastPosition: (state: SidebarState): void => {
            state.latestPosition = undefined;
        },
        fetchedDetailsSidebarDisplayItems: (
            state: SidebarState,
            action: PayloadAction<DetailsSidebarDisplayItem[]>
        ): void => {
            state.displayItems = action.payload;
        },
    },
});

export const realtimeSidebarActions = actions;
export const realtimeSidebarReducer = reducer;

const getBase = (state: State): SidebarState | undefined => get(state, `realtime.${name}`);
const getLatestPosition = (state: State): LatestPosition | undefined => get(getBase(state), 'latestPosition');

export const getDetailsSidebarDisplayItems = (state: State): DetailsSidebarDisplayItem[] =>
    get(getBase(state), 'displayItems', []);
export const getLatestLatitude = (state: State): number | undefined =>
    get(getLatestPosition(state), 'latitude', undefined);
export const getLatestLongitude = (state: State): number | undefined =>
    get(getLatestPosition(state), 'longitude', undefined);
export const getLatestBearing = (state: State): number | undefined =>
    get(getLatestPosition(state), 'bearing', undefined);
export const getLatestAddress = (state: State): string | undefined =>
    get(getLatestPosition(state), 'address', undefined);
export const getTimestampOfLatestPosition = (state: State): string | undefined =>
    get(getLatestPosition(state), 'eventTimestamp', undefined);
export const getLatestAssetId = (state: State): string | undefined =>
    get(getLatestPosition(state), 'assetId', undefined);
export const getLatestDriverId = (state: State): string | undefined =>
    get(getLatestPosition(state), 'driverId', undefined);
