import getOr from 'lodash/fp/getOr';
import { changeLocale, languageDataFetched } from './actions';
import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from './lang';
import { trace } from '../setup/trace';
import { sendErrorToSentry } from '../setup/sentry';
import { getMomentLocale, importLocale } from './momentLocales';

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported) => getOr(imported, 'default', imported);

export const getLanguageData = (locale) =>
    import(/* webpackChunkName: "[request]" */ `../../features/translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch((error) => {
            sendErrorToSentry(error);
            return error;
        });

export const configureFetchLanguageData =
    (store, fetchLanguageData = getLanguageData, getSupportedLocale = defaultGetSupportedLocale) =>
    (locale) => {
        if (!locale) {
            // eslint-disable-next-line no-console
            console.warn('No locale supplied when fetching language data!');
            return Promise.reject();
        }

        store.dispatch(changeLocale(locale));
        const supportedLocale = getSupportedLocale(locale);

        const momentLocale = getMomentLocale(supportedLocale);

        const momentPromise = importLocale(momentLocale)
            .then(() => {
                trace(`imported momentLocale of country: ${momentLocale}`);
            })
            .catch((error) => {
                console.error(`momentLocale of country ${momentLocale} could not be imported`, error);
                sendErrorToSentry(error);
                return error;
            });

        const displayMessagesPromise = fetchLanguageData(supportedLocale)
            .then((languageData) => {
                trace(`Language data fetched for "${supportedLocale}"`);
                store.dispatch(languageDataFetched(supportedLocale, languageData));
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(`Language data for "${supportedLocale}" could not be fetched.`, error);
                sendErrorToSentry(error);
                store.dispatch(changeLocale(DEFAULT_LOCALE));
                return error;
            });

        return Promise.all([momentPromise, displayMessagesPromise]);
    };
