import { datadogLogs } from '@datadog/browser-logs';
import { config } from '../../config';
import cloneDeepWith from 'lodash/cloneDeepWith';

const isLocalhost = () => window.location.hostname === 'localhost';

const tokenStringReplacer = (val) => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
};

if (!isLocalhost()) {
    datadogLogs.init({
        clientToken: 'pub22e4b73af0330c5d3189a7b7aef24436',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        version: config.version,
        service: 'web-timed',
        env: 'production',
        beforeSend: (obj) => cloneDeepWith(obj, tokenStringReplacer),
    });
    datadogLogs.setGlobalContextProperty('account_id', '120915254295');
    datadogLogs.setGlobalContextProperty('team', 'rio-team-transport-two');
    datadogLogs.setGlobalContextProperty('team_identifier', 'rio-team-transport-two');
}
