import { UpsellingView } from './UpsellingView';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import { ProductStatus } from '../../upselling/types';
import { isEmpty } from 'lodash';
import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import { getIdsOfSelectedAssets } from '../../tree/selectionService';
import { getFilteredAndSortedDrivingTimesDisplayItems } from '../../tables/drivingtimes/drivingTimesDataPreparationService';
import { getProductStatusOfSelectedAsset } from '../../upselling/productStatusCalculation.service';
import { connect } from 'react-redux';
import { NoSignalViewContainer } from './NoSignalViewContainer';
import { getHasAccessDeniedFetchError } from '../../api/redux/api.redux';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../reducers';
import { Product } from '../../data/redux/types';
import { getAssetBookings } from '../../data/redux/data.redux';
import { TableColumn } from '../../enums/TableColumn';
import { ActivityAndDrivingTimesViewContainer } from './ActivityAndDrivingTimesView.container';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const WidgetViewComponent = (props: WidgetViewProps): JSX.Element => {
    const { displayItem, productStatus, hasAccessDeniedError } = props;

    if (hasAccessDeniedError) {
        return (
            <div data-testid={'widgetForbiddenState'}>
                <ForbiddenState
                    headline={<FormattedMessage id={'intl-msg:timed.common.accessDenied.headline'} />}
                    message={<FormattedMessage id={'intl-msg:timed.common.accessDenied.message'} />}
                />
            </div>
        );
    }

    if (productStatus === ProductStatus.BOOKED) {
        if (displayItem) {
            return <ActivityAndDrivingTimesViewContainer displayItem={displayItem} />;
        }
        return <NoSignalViewContainer />;
    }
    return <UpsellingView productStatus={productStatus} />;
};

const getLatestDisplayItemOfSelectedAsset = (
    state: State,
    sortBy: TableColumn,
    sortDir: SortDirectionType
): DrivingTimesDisplayItem | undefined => {
    const idsOfSelectedAssets = getIdsOfSelectedAssets(state);
    if (isEmpty(idsOfSelectedAssets) || idsOfSelectedAssets.length > 1) {
        return undefined;
    }

    const filteredAndSortedDrivingTimesDisplayItems = getFilteredAndSortedDrivingTimesDisplayItems(
        state,
        sortBy,
        sortDir
    );
    if (isEmpty(filteredAndSortedDrivingTimesDisplayItems)) {
        return undefined;
    }

    return filteredAndSortedDrivingTimesDisplayItems[0];
};

const getProductStatus = (state: State): ProductStatus | undefined => {
    const idsOfSelectedAssets = getIdsOfSelectedAssets(state);
    if (idsOfSelectedAssets.length !== 1) {
        return undefined;
    }

    return getProductStatusOfSelectedAsset(getAssetBookings(state), idsOfSelectedAssets[0], Product.TIMED);
};

interface WidgetViewProps {
    displayItem?: DrivingTimesDisplayItem;
    productStatus?: ProductStatus;
    hasAccessDeniedError: boolean;
}

const mapStateToProps = (state: State): WidgetViewProps => ({
    displayItem: getLatestDisplayItemOfSelectedAsset(
        state,
        TableColumn.DRIVING_TIMES_TIMESTAMP,
        SortDirection.DESCENDING
    ),
    productStatus: getProductStatus(state),
    hasAccessDeniedError: getHasAccessDeniedFetchError(state),
});
export const WidgetViewContainer = connect(mapStateToProps)(WidgetViewComponent);
