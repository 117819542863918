import { TableColumn } from '../../enums/TableColumn';

const DRIVING_ICON = 'status-driving';
const WORKING_ICOM = 'status-working';
const RESTING_ICON = 'status-resting';
const columnIcon: { [key: string]: string | undefined } = {
    [TableColumn.VEHICLE]: undefined,
    [TableColumn.DRIVER]: undefined,
    [TableColumn.STATUS_DURATION]: undefined,
    [TableColumn.STATE_CHANGES]: undefined,
    [TableColumn.CONTINUOUS_DRIVING]: DRIVING_ICON,
    [TableColumn.REMAINING_CURRENT_DRIVING_TIME]: DRIVING_ICON,
    [TableColumn.RESTING_TODAY_CURRENT]: RESTING_ICON,
    [TableColumn.REMAINING_TIME_OF_CURRENT_BREAK]: RESTING_ICON,
    [TableColumn.DRIVING_TODAY_CURRENT]: DRIVING_ICON,
    [TableColumn.REMAINING_DRIVING_TODAY_CURRENT]: DRIVING_ICON,
    [TableColumn.DRIVING_WEEKLY_CURRENT]: DRIVING_ICON,
    [TableColumn.REMAINING_WEEK_DRIVING_REMAINING]: DRIVING_ICON,
    [TableColumn.DRIVING_TWO_WEEKLY_CURRENT]: DRIVING_ICON,
    [TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT]: DRIVING_ICON,
    [TableColumn.MINIMUM_DAILY_REST_MINIMUM]: RESTING_ICON,
    [TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM]: RESTING_ICON,
    [TableColumn.CURRENT_DAILY_WORKING_TIME]: WORKING_ICOM,
    [TableColumn.REMAINING_DAILY_WORKING_TIME]: WORKING_ICOM,
    [TableColumn.CURRENT_WEEKLY_WORKING_TIME]: WORKING_ICOM,
    [TableColumn.REMAINING_WEEKLY_WORKING_TIME]: WORKING_ICOM,
    [TableColumn.WEEKLY_REST_MINIMUM]: RESTING_ICON,
    [TableColumn.DRIVING_TIMES_TIMESTAMP]: DRIVING_ICON,
    [TableColumn.WORKING_TIMES_TIMESTAMP]: WORKING_ICOM,
};

const columnLabelIds: { [key: string]: string } = {
    [TableColumn.VEHICLE]: 'intl-msg:glossary.vehicle',
    [TableColumn.DRIVER]: 'intl-msg:glossary.driver',
    [TableColumn.STATUS_DURATION]: 'intl-msg:timed.common.activity.latest',
    [TableColumn.STATE_CHANGES]: '',
    [TableColumn.CONTINUOUS_DRIVING]: 'intl-msg:timed.driving-time-table.continuousDrivingTime',
    [TableColumn.REMAINING_CURRENT_DRIVING_TIME]: 'intl-msg:timed.driving-time-table.currentRemainingDrivingTime',
    [TableColumn.RESTING_TODAY_CURRENT]: 'intl-msg:timed.driving-time-table.timed.cumulativeBreak',
    [TableColumn.REMAINING_TIME_OF_CURRENT_BREAK]: 'intl-msg:timed.driving-time-table.remainingBreakRestTime',
    [TableColumn.DRIVING_TODAY_CURRENT]: 'intl-msg:timed.driving-time-table.day',
    [TableColumn.REMAINING_DRIVING_TODAY_CURRENT]: 'intl-msg:timed.driving-time-table.day',
    [TableColumn.DRIVING_WEEKLY_CURRENT]: 'intl-msg:timed.driving-time-table.week',
    [TableColumn.REMAINING_WEEK_DRIVING_REMAINING]: 'intl-msg:timed.driving-time-table.week',
    [TableColumn.DRIVING_TWO_WEEKLY_CURRENT]: 'intl-msg:timed.driving-time-table.doubleWeek',
    [TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT]: 'intl-msg:timed.driving-time-table.doubleWeek',
    [TableColumn.MINIMUM_DAILY_REST_MINIMUM]: 'intl-msg:timed.driving-time-table.dailyRest',
    [TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM]: 'intl-msg:timed.driving-time-table.dailyRest',
    [TableColumn.WEEKLY_REST_MINIMUM]: 'intl-msg:timed.driving-time-table.weeklyRest',
    [TableColumn.CURRENT_DAILY_WORKING_TIME]: 'intl-msg:timed.driving-time-table.dailyWorkingTime',
    [TableColumn.REMAINING_DAILY_WORKING_TIME]: 'intl-msg:timed.driving-time-table.dailyWorkingTime',
    [TableColumn.CURRENT_WEEKLY_WORKING_TIME]: 'intl-msg:timed.driving-time-table.weeklyWorkingTime',
    [TableColumn.REMAINING_WEEKLY_WORKING_TIME]: 'intl-msg:timed.driving-time-table.weeklyWorkingTime',
    [TableColumn.DRIVING_TIMES_TIMESTAMP]: 'intl-msg:timed.common.drivingTimesTimestamp',
    [TableColumn.ACTIVITIES_TIMESTAMP]: 'intl-msg:timed.common.timestamp',
    [TableColumn.WORKING_TIMES_TIMESTAMP]: 'intl-msg:timed.common.workingTimesTimestamp',
};

export const getColumnHeaderIcon = (column: TableColumn): string | undefined => columnIcon[column];

export const getColumnHeaderMessageId = (column: TableColumn): string | undefined => columnLabelIds[column];
