import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { localStorageOfWidget } from './storageForWidget';
import { PersistedWidgetState } from './localStorageMapper';
import { useEffect } from 'react';
import { showWorkingTimesInfoBox, widgetActions } from '../redux/widget.redux';
import { FunctionProperties, NonFunctionProperties } from '../../../utils/util';
import { State } from '../../../reducers';

interface WidgetLocalStorageUpdaterProps {
    shouldShowWorkingTimesInfoBox: boolean;
    setShowWorkingTimesInfoBox: (value: boolean) => void;
}

const widgetStateStorageKey = 'widgetState';

const WidgetLocalStorageUpdater = (props: WidgetLocalStorageUpdaterProps): null => {
    const { shouldShowWorkingTimesInfoBox, setShowWorkingTimesInfoBox } = props;

    const updateReduxStore = (persistedWidgetState: PersistedWidgetState): void => {
        setShowWorkingTimesInfoBox(persistedWidgetState.showWorkingTimesInfoBox);
    };

    useEffect(() => {
        const persistedWidgetState = localStorageOfWidget.loadPersistedWidgetState(widgetStateStorageKey);
        if (persistedWidgetState !== undefined) {
            updateReduxStore(persistedWidgetState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorageOfWidget.savePersistedWidgetState(widgetStateStorageKey, {
            showWorkingTimesInfoBox: shouldShowWorkingTimesInfoBox,
        });
    }, [shouldShowWorkingTimesInfoBox]);
    return null;
};

const mapDispatchToProps = (dispatch: Dispatch): FunctionProperties<WidgetLocalStorageUpdaterProps> => ({
    setShowWorkingTimesInfoBox: (value: boolean): void => {
        dispatch(widgetActions.setShowWorkingTimesInfoBox(value));
    },
});

const mapStateToProps = (state: State): NonFunctionProperties<WidgetLocalStorageUpdaterProps> => ({
    shouldShowWorkingTimesInfoBox: showWorkingTimesInfoBox(state),
});

export const WidgetLocalStorageUpdaterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WidgetLocalStorageUpdater);
