import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealTimeState } from './types';
import { StateChange } from '../../api/types';
import { get } from 'lodash';
import { Activities } from '../../enums/Activities';
import { TimeStampCategory } from '../../enums/TimeStampCategory';
import { DrivingTimesDisplayItem } from '../../tables/drivingtimes/types';
import moment, { Moment } from 'moment';
import { State } from '../../../reducers';

const name = 'table';

const initialState: RealTimeState = {
    stateChanges: [],
    drivingTimesDisplayItems: [],
    selectedActivities: [],
    selectedTimestampCategories: [],
    isCurrentValuesDisplayed: false,
    isStateChangesDisplayed: false,
    receivedTimestamp: undefined,
    receivedTimestampOfDrivingTimes: undefined,
    receivedTimestampOfStateChanges: undefined,
    timeRangeSelectionFrom: undefined,
    timeRangeSelectionTo: undefined,
    isTimeRangePreSelectionSelected: false,
    selectedDrivingTimesRowId: undefined,
    selectedStateChangesRowId: undefined,
};

const toggleExistingValueOrSetNewOne = (
    oldValue: string | undefined,
    newValue: string | undefined
): string | undefined => (oldValue === newValue ? undefined : newValue);

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        fetchedDrivingTimesDisplayItems: (
            state: RealTimeState,
            action: PayloadAction<DrivingTimesDisplayItem[]>
        ): void => {
            state.drivingTimesDisplayItems = action.payload;
        },
        fetchedStateChanges: (state: RealTimeState, action: PayloadAction<StateChange[]>): void => {
            state.stateChanges = action.payload;
        },
        setSelectedActivities: (state: RealTimeState, action: PayloadAction<Activities[]>): void => {
            state.selectedActivities = action.payload;
        },
        setSelectedTimeStampCategories: (state: RealTimeState, action: PayloadAction<TimeStampCategory[]>): void => {
            state.selectedTimestampCategories = action.payload;
        },
        setIsCurrentValuesDisplayed: (state: RealTimeState, action: PayloadAction<boolean>): void => {
            state.isCurrentValuesDisplayed = action.payload;
        },
        setIsStateChangesDisplayed: (state: RealTimeState, action: PayloadAction<boolean>): void => {
            state.isStateChangesDisplayed = action.payload;
        },
        setReceivedTimestampOfDrivingTimes: (state: RealTimeState, action: PayloadAction<Moment>): void => {
            state.receivedTimestampOfDrivingTimes = action.payload;
        },
        setReceivedTimestampOfStateChanges: (state: RealTimeState, action: PayloadAction<Moment>): void => {
            state.receivedTimestampOfStateChanges = action.payload;
        },
        setFromOfTimeRangeSelection: (state: RealTimeState, action: PayloadAction<string>): void => {
            state.timeRangeSelectionFrom = action.payload;
        },
        setToOfTimeRangeSelection: (state: RealTimeState, action: PayloadAction<string>): void => {
            state.timeRangeSelectionTo = action.payload;
        },
        setIsTimeRangePreSelectionSelected: (state: RealTimeState, action: PayloadAction<boolean>): void => {
            state.isTimeRangePreSelectionSelected = action.payload;
        },
        selectDrivingTimesTableRow: (state: RealTimeState, action: PayloadAction<string | undefined>): void => {
            state.selectedDrivingTimesRowId = toggleExistingValueOrSetNewOne(
                state.selectedDrivingTimesRowId,
                action.payload
            );
        },
        selectStateChangesTableRow: (state: RealTimeState, action: PayloadAction<string | undefined>): void => {
            state.selectedStateChangesRowId = toggleExistingValueOrSetNewOne(
                state.selectedStateChangesRowId,
                action.payload
            );
        },
    },
});

export const realtimeActions = actions;
export const realtimeReducer = reducer;

const getBase = (state: State): RealTimeState | undefined => get(state, `realtime.${name}`);

export const getDrivingTimeDisplayItems = (state: State): DrivingTimesDisplayItem[] =>
    get(getBase(state), 'drivingTimesDisplayItems', []);
export const getStateChangesItems = (state: State): StateChange[] => get(getBase(state), 'stateChanges', []);
export const getSelectedActivities = (state: State): Activities[] => get(getBase(state), 'selectedActivities', []);
export const getSelectedTimestampCategories = (state: State): TimeStampCategory[] =>
    get(getBase(state), 'selectedTimestampCategories', []);
export const getIsCurrentValuesDisplayed = (state: State): boolean =>
    get(getBase(state), 'isCurrentValuesDisplayed', false);
export const getIsStateChangesDisplayed = (state: State): boolean =>
    get(getBase(state), 'isStateChangesDisplayed', false);
export const getReceivedTimestampOfDrivingTimes = (state: State): Moment | undefined =>
    get(getBase(state), 'receivedTimestampOfDrivingTimes', undefined);
export const getReceivedTimestampOfStateChanges = (state: State): Moment | undefined =>
    get(getBase(state), 'receivedTimestampOfStateChanges', undefined);
export const getFromOfTimeRangeSelection = (state: State): string =>
    get(getBase(state), 'timeRangeSelectionFrom', moment().startOf('day').toISOString(true));
export const getToOfTimeRangeSelection = (state: State): string =>
    get(getBase(state), 'timeRangeSelectionTo', moment().endOf('day').toISOString(true));
export const getSelectedDrivingTimesRowId = (state: State): string | undefined =>
    get(getBase(state), 'selectedDrivingTimesRowId', undefined);
export const getSelectedStateChangesRowId = (state: State): string | undefined =>
    get(getBase(state), 'selectedStateChangesRowId', undefined);
export const getIsTimeRangePreSelectionSelected = (state: State): boolean =>
    get(getBase(state), 'isTimeRangePreSelectionSelected', false);
