import { StateChangesDisplayItem } from './types';
import { VehicleCell } from '../cells/VehicleCell';
import { DriverCell } from '../cells/DriverCell';
import { StatusChangesCell } from '../cells/StatusChangesCell';
import { TimestampCell } from '../cells/TimestampCell';
import { StatusDurationCell } from '../cells/StatusDurationCell';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TrackingAction, trackingAttributes, TrackingCategory } from '../../../configuration/setup/googleTagManager';
import { Moment } from 'moment';
import { getTimeStampType, TimeStampCategory } from '../../enums/TimeStampCategory';
import { getColumnHeaderMessageId } from '../header/columnHeaderFormattingService';
import { TableColumn } from '../../enums/TableColumn';
import { getStateChangeColumns } from './stateChangesColumnService';

const renderTableData = (stateChangesDisplayItem: StateChangesDisplayItem, column: TableColumn): JSX.Element | null => {
    switch (column) {
        case TableColumn.VEHICLE:
            return <VehicleCell value={stateChangesDisplayItem[column]} />;
        case TableColumn.DRIVER:
            return <DriverCell value={stateChangesDisplayItem[column]} />;
        case TableColumn.STATE_CHANGES:
            return <StatusChangesCell value={stateChangesDisplayItem[column]} />;
        case TableColumn.STATUS_DURATION:
            return <StatusDurationCell value={stateChangesDisplayItem[TableColumn.STATUS_DURATION]} />;
        case TableColumn.ACTIVITIES_TIMESTAMP:
            return <TimestampCell value={stateChangesDisplayItem[TableColumn.ACTIVITIES_TIMESTAMP]} />;
        default:
            return null;
    }
};

interface StateChangesTableRowProps {
    stateChangesItem: StateChangesDisplayItem;
    onRowSelected: () => void;
}

const trackSelectTableItem = trackingAttributes(
    TrackingCategory.STATE_CHANGES_VIEW,
    TrackingAction.SELECT_TABLE_ITEM,
    'show details dialog'
);

const getDisableClassName = (timestamp: Moment | undefined): string => {
    if (timestamp) {
        const timeStampType = getTimeStampType(timestamp);
        return timeStampType === TimeStampCategory.OUTDATED ? 'disabled' : '';
    }
    return 'disabled';
};

const StateChangesTableRowRaw = (props: StateChangesTableRowProps & WrappedComponentProps): JSX.Element => {
    const { stateChangesItem, intl, onRowSelected } = props;

    const activateRowIfSelected = stateChangesItem.isActive ? 'active' : '';
    const disabledRowIfDateIsOutdated = stateChangesItem[TableColumn.ACTIVITIES_TIMESTAMP]
        ? getDisableClassName(stateChangesItem[TableColumn.ACTIVITIES_TIMESTAMP])
        : '';

    return (
        <tr
            data-key={stateChangesItem.id}
            onClick={onRowSelected}
            className={`${activateRowIfSelected} ${disabledRowIfDateIsOutdated} pointer-events-all cursor-pointer`}
            {...trackSelectTableItem}
        >
            {getStateChangeColumns().map((column: TableColumn) => {
                const columnLabelId = getColumnHeaderMessageId(column);
                const dataLabel = columnLabelId ? intl.formatMessage({ id: columnLabelId }) : '';
                return (
                    <td key={column} data-field={dataLabel}>
                        {renderTableData(stateChangesItem, column)}
                    </td>
                );
            })}
        </tr>
    );
};

export const StateChangesTableRow = injectIntl(StateChangesTableRowRaw);
