import qs from 'qs';
import { PersistedSpaState } from '../types';
import { isEmpty } from 'lodash';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { OPTIONS } from './config';
import { TreeCategory } from '../../tree/redux/types';

const readNumber = (value: unknown): number | undefined => {
    if (!isEmpty(value)) {
        const asNumber = Number(value);
        if (!isNaN(asNumber)) {
            return asNumber;
        }
    }
    return undefined;
};

const readSelectedStateChangesDisplay = (data: unknown): boolean | undefined => {
    if (isEmpty(data)) {
        return undefined;
    }
    if (data === 'true') {
        return true;
    } else if (data === 'false') {
        return false;
    }
    return undefined;
};

const readViewMode = (viewMode: unknown): TableViewTogglesViewType | undefined => {
    if (!(typeof viewMode === 'string') || isEmpty(viewMode)) {
        return undefined;
    }
    return Object.values(TableViewToggles).find((c) => c === viewMode);
};

const readTreeCategory = (treeCategory: unknown): TreeCategory | undefined => {
    if (!(typeof treeCategory === 'string') || isEmpty(treeCategory)) {
        return undefined;
    }
    return Object.values(TreeCategory).find((c) => c === treeCategory);
};

const readArray = (input: unknown): string[] | undefined => {
    if (typeof input === 'string') {
        return isEmpty(input) ? undefined : [input as string];
    } else {
        return input instanceof Array ? (input as string[]) : undefined;
    }
};

const readString = (input: unknown): string | undefined => {
    if (!(typeof input === 'string') || isEmpty(input)) {
        return undefined;
    } else {
        return input;
    }
};

export const parseRoute = (query: string): PersistedSpaState => {
    const searchParams = query ? query.replace('?', '') : query;
    const {
        assetIds,
        vehicleGroupIds,
        driverIds,
        driverGroupIds,
        activityIds,
        timestampIds,
        selectedTimeDisplayMode,
        selectedStateChangesDisplay,
        selectedStateChangesStartNumber,
        selectedDrivingTimesRowId,
        selectedStateChangesRowId,
        viewMode,
        treeCategory,
    } = qs.parse(searchParams, OPTIONS);
    return {
        selectedAssetIds: readArray(assetIds),
        selectedVehicleGroupIds: readArray(vehicleGroupIds),
        selectedDriverIds: readArray(driverIds),
        selectedDriverGroupIds: readArray(driverGroupIds),
        selectedActivityIds: readArray(activityIds),
        selectedTimestampIds: readArray(timestampIds),
        selectedTimeDisplayMode: 'remaining' === selectedTimeDisplayMode ? selectedTimeDisplayMode : undefined,
        selectedStateChangesDisplay: readSelectedStateChangesDisplay(selectedStateChangesDisplay),
        selectedDrivingTimesRowId: readString(selectedDrivingTimesRowId),
        selectedStateChangesRowId: readString(selectedStateChangesRowId),
        stateChangesStartNumber: readNumber(selectedStateChangesStartNumber),
        viewMode: readViewMode(viewMode),
        treeCategory: readTreeCategory(treeCategory),
    };
};
