/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TimeBlock } from '../elements/TimeBlock';
import { StateActivity } from '../statechanges/types';
import moment, { Moment } from 'moment';
import { EmptyDataIconWithTooltip } from '../elements/EmptyDataIcon';
import { connect } from 'react-redux';
import { getFromOfTimeRangeSelection, getToOfTimeRangeSelection } from '../../realtime/redux/realtime.redux';
import { getTableViewMode } from '../../realtime/header/tablesettings/redux/tablesettings.redux';
import { TableViewMode } from '../../realtime/header/tablesettings/redux/types';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { TimelineComponent } from './TimeLine.container';
import { State } from '../../../reducers';

interface StatusChangesCellProps extends StatusChangesCellStateProps {
    value?: StateActivity[];
}

interface StatusChangesCellStateProps {
    viewType: TableViewMode;
    startRange: Moment;
    endRange: Moment;
}

const renderLabelsForCardsViewMode = (
    viewType: TableViewMode,
    startRange: Moment,
    endRange: Moment
): JSX.Element | undefined => {
    if (viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD || viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS) {
        return (
            <div className={'margin-bottom-10 padding-left-10 padding-right-3'}>
                <TimelineComponent
                    startRange={startRange}
                    endRange={endRange.isSame(moment(), 'day') ? moment() : endRange.add(1, 'second')}
                />
            </div>
        );
    }
    return;
};

export const renderChart = (
    value: (StateActivity | undefined)[],
    rangeStart: Moment,
    rangeEnd?: Moment,
    forcedColor?: string
): JSX.Element => (
    <div className={'height-20 position-relative bg-lightest margin-left-10 margin-right-10'}>
        {value
            .filter((stateChange) => stateChange !== undefined)
            .filter((stateChange) => stateChange!.to > rangeStart)
            .map((stateChange, index) => {
                return (
                    <TimeBlock
                        key={index}
                        rangeStart={rangeStart}
                        rangeEnd={rangeEnd}
                        activity={stateChange!}
                        forcedColor={forcedColor}
                    />
                );
            })}
    </div>
);

export const renderChartNegative = (
    value: (StateActivity | undefined)[],
    rangeStart: Moment,
    rangeEnd?: Moment
): JSX.Element => (
    <div className={'height-20 position-relative bg-light margin-left-10 margin-right-10'}>
        {value
            .filter((stateChange) => stateChange !== undefined)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .filter((stateChange) => stateChange!.to > rangeStart)
            .map((stateChange, index) => {
                return (
                    <TimeBlock
                        key={index}
                        rangeStart={rangeStart}
                        rangeEnd={rangeEnd}
                        activity={stateChange!}
                        forcedColor={'bg-lightest'}
                    />
                );
            })}
    </div>
);

export const StatusChangesCellRaw = (props: StatusChangesCellProps): JSX.Element => {
    const { value, viewType, startRange, endRange } = props;

    if (!value) {
        return <EmptyDataIconWithTooltip />;
    }

    return (
        <div className={'width-100pct'}>
            {renderLabelsForCardsViewMode(viewType, startRange, endRange)}
            {renderChart(value, startRange, endRange)}
        </div>
    );
};

const mapStateToProps = (state: State): StatusChangesCellStateProps => {
    const endRange = moment(getToOfTimeRangeSelection(state));
    return {
        viewType: getTableViewMode(state),
        startRange: moment(getFromOfTimeRangeSelection(state)),
        endRange: endRange.isSame(moment(), 'day') ? moment() : endRange,
    };
};

export const StatusChangesCell = connect(mapStateToProps)(StatusChangesCellRaw);
