import { connect } from 'react-redux';
import { PersistedSpaState } from './types';
import { ROUTE_STATE, storage } from './localStorage/storage';
import { getIdsOfSelectedAssets, getIdsOfSelectedDrivers } from '../tree/selectionService';
import { getCurrentCategory, getSelectedDriverGroups, getSelectedVehicleGroups } from '../tree/redux/tree.redux';
import {
    getIsCurrentValuesDisplayed,
    getIsStateChangesDisplayed,
    getSelectedActivities,
    getSelectedDrivingTimesRowId,
    getSelectedStateChangesRowId,
    getSelectedTimestampCategories,
} from '../realtime/redux/realtime.redux';
import {
    getColumnOrder,
    getDrivingTimesChunkNumber,
    getHiddenColumns,
    getSortBy,
    getSortDir,
    getStateChangesChunkNumber,
    getTableViewMode,
} from '../realtime/header/tablesettings/redux/tablesettings.redux';
import { State } from '../../reducers';
import useAfterMount from '@rio-cloud/rio-uikit/hooks/useAfterMount';

const LocalStorageUpdater = (state: PersistedSpaState): null => {
    useAfterMount(() => {
        storage.saveDomainObject(ROUTE_STATE, state);
    }, [state]);
    return null;
};

const mapStateToProps = (state: State): PersistedSpaState => {
    return {
        selectedAssetIds: getIdsOfSelectedAssets(state),
        selectedVehicleGroupIds: getSelectedVehicleGroups(state),
        selectedDriverIds: getIdsOfSelectedDrivers(state),
        selectedDriverGroupIds: getSelectedDriverGroups(state),
        selectedActivityIds: getSelectedActivities(state),
        selectedTimestampIds: getSelectedTimestampCategories(state),
        selectedTimeDisplayMode: getIsCurrentValuesDisplayed(state) ? 'current' : 'remaining',
        selectedHiddenColumnIds: getHiddenColumns(state),
        selectedColumnOrderIds: getColumnOrder(state),
        selectedStateChangesDisplay: getIsStateChangesDisplayed(state),
        drivingTimesChunkNumber: getDrivingTimesChunkNumber(state),
        stateChangesChunkNumber: getStateChangesChunkNumber(state),
        selectedDrivingTimesRowId: getSelectedDrivingTimesRowId(state),
        selectedStateChangesRowId: getSelectedStateChangesRowId(state),
        sortDir: getSortDir(state),
        sortBy: getSortBy(state),
        viewMode: getTableViewMode(state),
        treeCategory: getCurrentCategory(state),
    };
};

export const LocalStorageUpdateContainer = connect(mapStateToProps)(LocalStorageUpdater);
