import { ApiDrivingTimesItem } from '../types';
import { ApiResponseDrivingTimeSample } from '../iots/drivingtimesCodec';

export const mapToDrivingTimes = (data: ApiResponseDrivingTimeSample[]): ApiDrivingTimesItem[] => {
    return data.map((item) => {
        return {
            id: item.sample_id,
            driver_id: item.driver_id,
            identification: item.identification,
            identification_type: item.identification_type,
            asset_id: item.asset_id,
            sample_date_time: item.sample_date_time,
            working_state: {
                state: item.activity_type,
            },
            embedded: {
                driving_and_resting_details: item.driving_and_resting_details,
                eu_shift_times: item.eu_shift_times,
                eu_infringements: item.eu_infringements,
            },
        };
    });
};
