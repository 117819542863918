/* eslint-disable no-underscore-dangle */
import { Driver, DriverStatus } from '../../data/redux/types';
import { ApiDriver } from '../iots/driverCodec';

export const mapToDriverList = (data: ApiDriver[]): Driver[] =>
    data.map((it) => ({
        id: it.id,
        displayName: it.display_name,
        status: it.status === 'active' ? DriverStatus.ACTIVE : DriverStatus.ARCHIVED,
        tagIds: it._embedded.tags.map((tag) => tag.id),
    }));
