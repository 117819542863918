import {
    BrowserPersistenceSortDir,
    BrowserPersistenceState,
    BrowserPersistenceTableColumn,
    BrowserPersistenceTreeCategory,
    BrowserPersistenceViewMode,
} from './browserPersistenceCodec';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { PersistedSpaState } from './types';
import { assertUnreachable } from '../../common/typescriptutils/exhaustiveCheck';
import { TreeCategory } from '../tree/redux/types';
import { TableColumn } from '../enums/TableColumn';
import SortDirection, { SortDirectionType } from '@rio-cloud/rio-uikit/utils/SortUtils';

const mapSortDir = (sortDir: BrowserPersistenceSortDir): SortDirectionType => {
    switch (sortDir) {
        case 'asc':
            return SortDirection.ASCENDING;
        case 'desc':
            return SortDirection.DESCENDING;
        default:
            assertUnreachable(sortDir);
            throw new Error('unreachable');
    }
};

const mapViewMode = (viewMode: BrowserPersistenceViewMode): TableViewTogglesViewType => {
    switch (viewMode) {
        case 'TABLE':
            return TableViewToggles.VIEW_TYPE_TABLE as TableViewTogglesViewType; // TODO remove this cast after UIKit upgrade
        case 'MULTI_CARDS':
            return TableViewToggles.VIEW_TYPE_MULTI_CARDS as TableViewTogglesViewType; // TODO remove this cast after UIKit upgrade
        case 'SINGLE_CARD':
            return TableViewToggles.VIEW_TYPE_SINGLE_CARD as TableViewTogglesViewType; // TODO remove this cast after UIKit upgrade
        default:
            assertUnreachable(viewMode);
            throw new Error('unreachable');
    }
};

const mapTreeCategory = (treeCategory: BrowserPersistenceTreeCategory): TreeCategory => {
    switch (treeCategory) {
        case 'AssetTree-vehicles':
            return TreeCategory.VEHICLES;
        case 'AssetTree-drivers':
            return TreeCategory.DRIVERS;
        default:
            assertUnreachable(treeCategory);
            throw new Error('unreachable');
    }
};

const mapTableColumns = (sortBy: BrowserPersistenceTableColumn): TableColumn => {
    switch (sortBy) {
        case 'vehicle':
            return TableColumn.VEHICLE;
        case 'driver':
            return TableColumn.DRIVER;
        case 'statusDuration':
        case 'statusDuration_latest': // this is kept for backwards compatibility
            return TableColumn.STATUS_DURATION;
        case 'continuousDriving':
            return TableColumn.CONTINUOUS_DRIVING;
        case 'remainingCurrentDrivingTime':
            return TableColumn.REMAINING_CURRENT_DRIVING_TIME;
        case 'restingTodayCurrent':
            return TableColumn.RESTING_TODAY_CURRENT;
        case 'remainingTimeOfCurrentBreakRest':
            return TableColumn.REMAINING_TIME_OF_CURRENT_BREAK;
        case 'drivingTodayCurrent':
            return TableColumn.DRIVING_TODAY_CURRENT;
        case 'remainingDrivingTodayCurrent':
            return TableColumn.REMAINING_DRIVING_TODAY_CURRENT;
        case 'drivingWeeklyCurrent':
            return TableColumn.DRIVING_WEEKLY_CURRENT;
        case 'remainingWeekDrivingRemaining':
            return TableColumn.REMAINING_WEEK_DRIVING_REMAINING;
        case 'drivingTwoWeeklyCurrent':
            return TableColumn.DRIVING_TWO_WEEKLY_CURRENT;
        case 'remainingDrivingTwoWeeklyCurrent':
            return TableColumn.REMAINING_DRIVING_TWO_WEEKLY_CURRENT;
        case 'minimumDailyRestMinimum':
            return TableColumn.MINIMUM_DAILY_REST_MINIMUM;
        case 'remainingMinimumDailyRestMinimum':
            return TableColumn.REMAINING_MINIMUM_DAILY_REST_MINIMUM;
        case 'weeklyRestMinimum':
            return TableColumn.WEEKLY_REST_MINIMUM;
        case 'stateChanges':
            return TableColumn.STATE_CHANGES;
        case 'timeStamp': // this is kept for backwards compatibility
        case 'timestamp_latest': // this is kept for backwards compatibility
        case 'drivingTimesTimestamp':
            return TableColumn.DRIVING_TIMES_TIMESTAMP;
        case 'activitiesTimestamp':
            return TableColumn.ACTIVITIES_TIMESTAMP;
        case 'workingTimesTimestamp':
            return TableColumn.WORKING_TIMES_TIMESTAMP;
        case 'currentDailyWorkingTime':
            return TableColumn.CURRENT_DAILY_WORKING_TIME;
        case 'remainingDailyWorkingTime':
            return TableColumn.REMAINING_DAILY_WORKING_TIME;
        case 'currentWeeklyWorkingTime':
            return TableColumn.CURRENT_WEEKLY_WORKING_TIME;
        case 'remainingWeeklyWorkingTime':
            return TableColumn.REMAINING_WEEKLY_WORKING_TIME;
        default:
            assertUnreachable(sortBy);
            throw new Error('unreachable');
    }
};

export const browserPersistence2SpaStateMapper = (input: BrowserPersistenceState): PersistedSpaState => {
    return {
        selectedAssetIds: input.selectedAssetIds,
        selectedVehicleGroupIds: input.selectedVehicleGroupIds,
        selectedDriverIds: input.selectedDriverIds,
        selectedDriverGroupIds: input.selectedDriverGroupIds,
        selectedActivityIds: input.selectedActivityIds,
        selectedTimestampIds: input.selectedTimestampIds,
        selectedHiddenColumnIds: input.selectedHiddenColumnIds?.map((it) => mapTableColumns(it)),
        selectedColumnOrderIds: input.selectedColumnOrderIds?.map((it) => mapTableColumns(it)),
        selectedTimeDisplayMode: input.selectedTimeDisplayMode,
        selectedStateChangesDisplay: input.selectedStateChangesDisplay,
        drivingTimesChunkNumber: input.drivingTimesChunkNumber,
        stateChangesChunkNumber: input.stateChangesChunkNumber,
        stateChangesStartNumber: input.stateChangesStartNumber,
        selectedDrivingTimesRowId: input.selectedDrivingTimesRowId,
        selectedStateChangesRowId: input.selectedStateChangesRowId,
        sortDir: input.sortDir && mapSortDir(input.sortDir),
        sortBy: input.sortBy && mapTableColumns(input.sortBy),
        viewMode: input.viewMode && mapViewMode(input.viewMode),
        treeCategory: input.treeCategory && mapTreeCategory(input.treeCategory),
    };
};
