import * as t from 'io-ts';
import { BasicResponseStructure, ResourceLinkList } from './resourceLinkCodec';

const euInfringement = t.type({
    type: t.string,
    category: t.string,
    target: t.string,
    actual: t.string,
    deviation: t.string,
    level: t.string,
});

const workingTimeSampleOptionalFields = t.partial({
    current_daily_working_time: t.string,
    remaining_daily_working_time: t.string,
    current_weekly_working_time: t.string,
    remaining_weekly_working_time: t.string,
    ddd_data_until: t.string,
});

const workingTimeSampleRequiredFields = t.type({
    driver_id: t.string,
    latest_data_at: t.string,
    current_working_time_infringements: t.array(euInfringement),
});

const apiResponseWorkingTimeSample = t.intersection([workingTimeSampleRequiredFields, workingTimeSampleOptionalFields]);

export const apiResponseWorkingTimeSampleListCodec = t.type({
    _links: ResourceLinkList,
    items: t.array(apiResponseWorkingTimeSample),
});
export type ApiResponseWorkingTimesSample = t.TypeOf<typeof apiResponseWorkingTimeSample>;

export type ApiResponseWorkingTimes = BasicResponseStructure & t.TypeOf<typeof apiResponseWorkingTimeSampleListCodec>;
