import { ProductActivationsApiResponse, ProductWithBookingDateAndStatus } from '../iots/productActivationsCodec';
import { AssetProduct, Feature, Product, ProductActivations, ProductStatus } from '../../data/redux/types';
import { isOfType } from '../../../utils/util';

export const mapToProductActivations = (data: ProductActivationsApiResponse): ProductActivations => {
    const assetBookings: any = {};
    Object.keys(data.asset_products).forEach(
        (assetId) => (assetBookings[assetId] = getAssetProductList(data.asset_products[assetId]))
    );

    return {
        assetBookings,
        features: data.features
            .map((feature) => mapToFeature(feature))
            .filter((it): it is Feature => typeof it !== 'undefined'),
    };
};

const getAssetProductList = (data: ProductWithBookingDateAndStatus[]): AssetProduct[] =>
    data.map((it) => mapToAssetProduct(it)).filter((it): it is AssetProduct => typeof it !== 'undefined');

const mapToAssetProduct = (data: ProductWithBookingDateAndStatus): AssetProduct | undefined => {
    const assetProduct = {
        product: mapToProducts(data.product),
        status: mapToProductStatus(data.status),
        statusChangeDate: data.status_change_date,
    };
    return isOfType(assetProduct) ? assetProduct : undefined;
};

const mapToFeature = (featureString: string): Feature | undefined => {
    switch (featureString) {
        case 'WORKING_TIMES':
            return Feature.WORKING_TIMES;
        default:
            console.warn(`Unknown feature found: ${featureString}`);
            return undefined;
    }
};

const mapToProductStatus = (data: string): ProductStatus | undefined => {
    switch (data) {
        case 'ACTIVE':
            return ProductStatus.ACTIVE;
        case 'PENDING_ACTIVATION':
            return ProductStatus.PENDING;
        default:
            console.warn(`Unknown product status found: ${data}`);
            return undefined;
    }
};

const mapToProducts = (data: string): Product | undefined => {
    switch (data) {
        case 'man-sku00000004':
            return Product.COMPLIANT;
        case 'rio-sku00000027':
            return Product.TIMED;
        default:
            console.warn(`Unknown product product found: ${data}`);
            return undefined;
    }
};
