import { ReactElement } from 'react';

interface RioglyphIconProps {
    type: string;
    color?: string;
    size?: string;
    className?: string;
    backgroundSize?: number;
}

const RioglyphIcon = (props: RioglyphIconProps): ReactElement => {
    const { type, color = 'black', size = '14', className, backgroundSize } = props;

    const textColor = backgroundSize ? `white bg-${color} border-radius-100pct padding-${backgroundSize}` : color;

    const classes = `rioglyph rioglyph-${type} text-color-${textColor} text-size-${size} ${className ? className : ''}`;

    return <span className={classes} data-testid={`rioglyph-icon-${type}`} />;
};

export default RioglyphIcon;
