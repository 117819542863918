import { connect } from 'react-redux';
import debounce from 'lodash/fp/debounce';
import { PersistedSpaState } from './types';
import { makeRoute } from './url/urlCreationService';
import { NonFunctionProperties } from '../../utils/util';
import { getIdsOfSelectedAssets, getIdsOfSelectedDrivers } from '../tree/selectionService';
import { getCurrentCategory, getSelectedDriverGroups, getSelectedVehicleGroups } from '../tree/redux/tree.redux';
import {
    getIsCurrentValuesDisplayed,
    getIsStateChangesDisplayed,
    getSelectedActivities,
    getSelectedDrivingTimesRowId,
    getSelectedStateChangesRowId,
    getSelectedTimestampCategories,
} from '../realtime/redux/realtime.redux';
import { getTableViewMode } from '../realtime/header/tablesettings/redux/tablesettings.redux';
import { State } from '../../reducers';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import useAfterMount from '@rio-cloud/rio-uikit/hooks/useAfterMount';

const PUSH_ROUTE_DEBOUNCE = 200;

interface RouteUpdaterProps {
    routeToPushTo: string;
}

const pushRoute = debounce(PUSH_ROUTE_DEBOUNCE)((navigate: NavigateFunction, route: string) => {
    navigate(route);
});

const RouteUpdater = (props: RouteUpdaterProps): null => {
    const { routeToPushTo } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname.concat(location.search);

    useAfterMount(() => {
        if (currentUrl !== routeToPushTo) {
            pushRoute(navigate, routeToPushTo);
        }
    }, [routeToPushTo]);
    return null;
};

const getRouteState = (state: State): PersistedSpaState => {
    return {
        selectedAssetIds: getIdsOfSelectedAssets(state),
        selectedVehicleGroupIds: getSelectedVehicleGroups(state),
        selectedDriverIds: getIdsOfSelectedDrivers(state),
        selectedDriverGroupIds: getSelectedDriverGroups(state),
        selectedActivityIds: getSelectedActivities(state),
        selectedTimestampIds: getSelectedTimestampCategories(state),
        selectedTimeDisplayMode: getIsCurrentValuesDisplayed(state) ? 'current' : 'remaining',
        selectedStateChangesDisplay: getIsStateChangesDisplayed(state),
        selectedDrivingTimesRowId: getSelectedDrivingTimesRowId(state),
        selectedStateChangesRowId: getSelectedStateChangesRowId(state),
        viewMode: getTableViewMode(state),
        treeCategory: getCurrentCategory(state),
    };
};

const mapStateToProps = (state: State): NonFunctionProperties<RouteUpdaterProps> => {
    const routeState = getRouteState(state);
    return {
        routeToPushTo: makeRoute(routeState),
    };
};

export const RouteUpdaterContainer = connect(mapStateToProps)(RouteUpdater);
