import { Dispatch } from 'redux';
import { fetchLatestPositionOfAsset } from '../../../api/api';
import { LatestPosition } from '../../../api/types';
import { realtimeSidebarActions } from '../../redux/detailsSidebar.redux';
import { getDrivingTimeDisplayItems, getSelectedDrivingTimesRowId } from '../../../realtime/redux/realtime.redux';
import { getLocale } from '../../../../configuration';
import { apiCallActions } from '../../../api/redux/api.redux';
import { ErrorCauses } from '../../../api/redux/types';
import { isUserSessionExpired } from '../../../../configuration/login/selectors';
import { State } from '../../../../reducers';

export const fetchLatestPositionThunk =
    () =>
    (dispatch: Dispatch, getState: () => State): void => {
        const activeRowId = getSelectedDrivingTimesRowId(getState());
        const drivingTimesItem = getDrivingTimeDisplayItems(getState()).find((item) => item.id === activeRowId);

        if (drivingTimesItem) {
            if (isUserSessionExpired(getState())) {
                dispatch(apiCallActions.setErrorForApiCall({ type: ErrorCauses.SESSION_EXPIRED_FETCH, value: true }));
                return;
            }

            const requests = [
                fetchLatestPositionOfAsset(drivingTimesItem.assetId, getLocale(getState())),
            ] as Promise<LatestPosition>[];
            Promise.all(requests)
                .then((result) => {
                    const latestPosition = result[0];
                    dispatch(realtimeSidebarActions.fetchedLastPosition(latestPosition));

                    dispatch(
                        apiCallActions.setErrorForApiCall({ type: ErrorCauses.LATEST_POSITION_FETCH, value: false })
                    );
                    dispatch(
                        apiCallActions.setErrorForApiCall({ type: ErrorCauses.SESSION_EXPIRED_FETCH, value: false })
                    );
                })
                .catch(() => {
                    dispatch(
                        apiCallActions.setErrorForApiCall({ type: ErrorCauses.LATEST_POSITION_FETCH, value: true })
                    );
                });
        }
    };
