import Activity from '@rio-cloud/rio-uikit/Activity';
import { Activities, ActivityConfig } from '../../enums/Activities';
import { FormattedMessage } from 'react-intl';
import { ProductStatus } from '../../upselling/types';
import {
    createGoogleAnalyticsEvent,
    TrackingAction,
    TrackingCategory,
} from '../../../configuration/setup/googleTagManager';
import { WidgetVisibilityTrackerContainer } from '../../../configuration/setup/VisbilityTracker.container';
import { UpsellingBuyButtonContainer } from '../../upselling/UpsellingBuyButtonContainer';

interface UpsellingViewProps {
    productStatus?: ProductStatus;
}

const activityIcons = (): JSX.Element[] =>
    Object.keys(ActivityConfig).map((item) => (
        <div key={item} className="margin-left-10 margin-right-10">
            <Activity activity={ActivityConfig[item as Activities].uikitActivity} bsSize={'xl'} />
        </div>
    ));

const Advertisement = (): JSX.Element => (
    <>
        <div className="display-flex justify-content-center margin-bottom-20">{activityIcons()}</div>
        <div className="text-center text-size-20">
            <FormattedMessage id={'intl-msg:timed.widget.upselling.info'} />
        </div>
    </>
);

const ActivationPending = (): JSX.Element => {
    return (
        <>
            <div data-testid={'up-selling-view'}>
                <div className="border rounded">
                    <div className="padding-top-25 padding-bottom-25">
                        <Advertisement />
                        <div className="text-center text-size-12">
                            <WidgetVisibilityTrackerContainer
                                googleAnalyticsEvent={createGoogleAnalyticsEvent(
                                    TrackingCategory.WIDGET_VIEW,
                                    TrackingAction.SHOW_PENDING_ACTIVATION_FOR_ASSET
                                )}
                            />
                            <FormattedMessage id={'intl-msg:timed.upselling.pendingServiceActivation'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const UpsellingView = (props: UpsellingViewProps): JSX.Element => {
    const { productStatus } = props;

    if (productStatus === ProductStatus.PENDING) {
        return <ActivationPending />;
    } else {
        return (
            <div data-testid={'up-selling-view'}>
                <WidgetVisibilityTrackerContainer
                    googleAnalyticsEvent={createGoogleAnalyticsEvent(
                        TrackingCategory.WIDGET_VIEW,
                        TrackingAction.SHOW_UPSELLING_LINK_TO_MARKETPLACE
                    )}
                />
                <UpsellingBuyButtonContainer />
            </div>
        );
    }
};
