const ROW_LIMIT = 15;

export const chunkResult = <T>(data: T[], currentChunk: number): T[] => {
    if (data.length === 0 || currentChunk < 0) {
        return [];
    }
    const endIndex = currentChunk * ROW_LIMIT;
    return data.slice(0, endIndex);
};

export const hasMoreChunks = <T>(data: T[], currentChunk: number): boolean => {
    if (currentChunk < 1) {
        return false;
    }
    return currentChunk * ROW_LIMIT < data.length;
};

export const getTotalChunkNumber = <T>(data: T[]): number => Math.ceil(data.length / ROW_LIMIT);

export const dataHasMoreThanOneChunk = <T>(data: T[]): boolean => getTotalChunkNumber(data) > 1;
