export enum ApiCalls {
    ASSET_TREE = 'assetTree',
    DRIVING_TIMES = 'drivingTimes',
    STATE_CHANGES = 'stateChanges',
    LATEST_POSITION = 'latestPosition',
}

export enum ErrorCauses {
    ASSET_TREE_FETCH = 'assetTreeFetch',
    DRIVING_TIMES_FETCH = 'drivingTimesFetch',
    STATE_CHANGES_FETCH = 'stateChangesFetch',
    LATEST_POSITION_FETCH = 'latestPositionFetch',
    SESSION_EXPIRED_FETCH = 'sessionExpiredFetch',
    ACCESS_DENIED_FETCH = 'accessDeniedFetch',
}

export interface ApiLoadingState {
    loading: { [key: string]: boolean };
    error: { [key: string]: boolean };
}
