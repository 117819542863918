import { Driver, Tag } from '../redux/types';
import { isEmpty } from 'fp-ts/Array';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';

export const UNASSIGNED_DRIVERS = 'unassignedDrivers';

export const getDriverNameOfDriverId = (driverId: string, drivers: Driver[]): string | undefined => {
    const foundDriver = drivers.find((driver) => driver.id === driverId);
    return foundDriver && foundDriver.displayName;
};

const filterAllowedTagsForDriver = (driver: Driver, tagIds: string[]) => {
    return driver.tagIds.filter((tag) => tagIds.includes(tag));
};

export const removeRestrictedDriverTags = (tags: Tag[], drivers: Driver[]): Driver[] => {
    const tagIds = tags.map((tag) => tag.id);
    return drivers.map((driver) => ({ ...driver, tagIds: filterAllowedTagsForDriver(driver, tagIds) }));
};

export const assignOrphanDriversToDefaultGroup = (drivers: Driver[]): Driver[] => {
    return drivers.map((driver) => ({
        ...driver,
        tagIds: !isEmpty(driver.tagIds) ? driver.tagIds : [UNASSIGNED_DRIVERS],
    }));
};

export const getDriverGroups = (tags: Tag[], drivers: Driver[]): TreeGroup[] => {
    const atLeastOneDriverWithEmptyTags = drivers.some((driver) => driver.tagIds.includes(UNASSIGNED_DRIVERS));
    const treeGroups = tags.map((it) => ({ id: it.id, name: it.name }) as TreeGroup);
    if (atLeastOneDriverWithEmptyTags) {
        treeGroups.push({ id: UNASSIGNED_DRIVERS, name: UNASSIGNED_DRIVERS });
    }
    return treeGroups;
};
