export const rioglyphIconTypeByAssetType = (assetType: string | undefined): string => {
    switch (assetType) {
        case 'truck':
            return 'truck';
        case 'bus':
            return 'bus';
        case 'van':
            return 'van';
        case 'trailer':
            return 'trailer';
        default:
            return 'truck';
    }
};
